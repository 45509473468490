import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Container, FormFeedback, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import _xhr from '../../utils/_xhr';
import swal from 'sweetalert';
import axios from 'axios';
import validator from '../../utils/validator';
import cookie from '../../utils/cookie';
import rest from '../../utils/rest.cfg';
import validationMessages from '../../utils/validationMessages';
const _changePasswordUrl = rest('users');
const auth = rest('auth');


class RenewPassword extends Component {
  constructor(props) {
      super(props);
      this.onSubmit = this.onSubmit.bind(this);

      this.state = {
          credentials: {
              oldPassword: "",
              newPassword: ""
          },
          passwordAgain: "",
          validations: {
              oldPassword: true,
              newPassword: true,
              passwordAgain: true
          }
      };
  }

  componentWillMount() {

  }

  componentDidMount() {

  }

  componentWillUpdate() {

  }

  componentDidUpdate() {

  }

  componentWillReceiveProps(newProps) {

  }

  onPasswordAgainChange(e){
    let credentials = this.state.credentials;
    let validationObj = this.state.validations;
    if(credentials["newPassword"] === e.target.value){
      validationObj["passwordAgain"] = true;
    }
    this.setState({
        validations: validationObj,
        passwordAgain: e.target.value
    })
  }

  onCommonChange(e){
      let obj = this.state.credentials;
      obj[e.target.name] = e.target.value;

      let validationObj = this.state.validations;
      validationObj[e.target.name] = validator.check("password", e.target.value);

      this.setState({
          credentials:obj,
          validations: validationObj
      })
  }

  onSubmit(e) {
      e.preventDefault();
      let self = this;

      let changePasswordUrl = _changePasswordUrl + '/' + cookie.get(auth.user);
1
      let credentials = self.state.credentials;

      let validationObj = self.state.validations;
      validationObj["newPassword"] = validator.check("password", credentials["newPassword"]);
      //validationObj["oldPassword"] = validator.check("password", credentials["oldPassword"]);
      if(credentials["newPassword"] !== self.state.passwordAgain){
        validationObj["passwordAgain"] = false;
      }
      this.setState({
          validations: validationObj,
          isSubmit: true
      })
      let params = {};
      params.password = credentials.newPassword;
      if(validator.isObjValid(validationObj)){
          _xhr.patch({
              url: changePasswordUrl,
              params: params,
              onsuccess: function ({ data }) {
                  swal("Parola değiştirildi!", {
                      icon: "success",
                      button: "Giriş"
                  })
                      .then((value) => {
                          self.props.history.push('/login');
                      });
              },
              onerror: function ({ err }){
                  let message = validationMessages("technicalError");
                  try{
                      if(err.response.data.error.code === "INVALID_PASSWORD"){
                          message = validationMessages("invalidCurrentPass");
                      }
                  }catch(e){}

                  swal(message, {
                      icon: "error"
                  });
              }
          })
      }
  }

  render() {
    return (
      <div className="app">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <Card className="mx-4">
                <CardBody className="p-4">
                  <h1>Parola Değişiklik</h1>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-lock"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input className={!this.state.validations.newPassword?"is-invalid":""} value={this.state.credentials["newPassword"]} onChange={this.onCommonChange.bind(this)} id="newPassword" name="newPassword" type="password" placeholder="Yeni Parola" />
                    {   !this.state.validations.newPassword && this.state.isSubmit &&
                        <FormFeedback>Yeni parola en az 8 karakterden oluşmalıdır.</FormFeedback>
                    }
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-lock"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input className={!this.state.validations.passwordAgain?"is-invalid":""} value={this.state.passwordAgain} onChange={this.onPasswordAgainChange.bind(this)}  id="passwordAgain" type="password" name="passwordAgain" placeholder="Yeni Parola Tekrar" />
                    {   !this.state.validations.passwordAgain && this.state.isSubmit &&
                        <FormFeedback>Yeni parolayı tekrar girin.</FormFeedback>
                    }
                  </InputGroup>
                  <Button color="success" onClick={this.onSubmit} block>Değiştir</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default RenewPassword;
