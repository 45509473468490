import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Button, FormGroup, Input, Label, CardFooter } from 'reactstrap';

const propTypes = {
  header: PropTypes.string,
  questionLength: PropTypes.number,
  activeQuestion: PropTypes.object,
  previousButtonOnClick: PropTypes.func,
  nextButtonOnClick: PropTypes.func,
  finishButtonOnClick: PropTypes.func,
  onChangeAnswer: PropTypes.func,
  onChangeTextAnswer: PropTypes.func,
  resultInfo: PropTypes.bool
};

const defaultProps = {
  answerPrefix: ["A) ", "B) ", "C) ", "D) ", "E) "]
};

class ExamInfo extends Component {
  constructor(props) {
    super(props);

    this.previousButtonOnClick = this.previousButtonOnClick.bind(this);
    this.nextButtonOnClick = this.nextButtonOnClick.bind(this);
    this.finishButtonOnClick = this.finishButtonOnClick.bind(this);
    this.onChangeAnswer = this.onChangeAnswer.bind(this);
    this.onChangeTextAnswer = this.onChangeTextAnswer.bind(this);
  }

  onChangeTextAnswer(e) {
    if (this.props.onChangeTextAnswer) {
      this.props.onChangeTextAnswer(e);
    }
  }

  onChangeAnswer(e) {
    if (this.props.onChangeAnswer) {
      this.props.onChangeAnswer(e);
    }
  }

  previousButtonOnClick(e) {
    if (this.props.previousButtonOnClick) {
      this.props.previousButtonOnClick(e);
    }
  }

  nextButtonOnClick(e) {
    if (this.props.nextButtonOnClick) {
      this.props.nextButtonOnClick(e);
    }
  }

  finishButtonOnClick(e) {
    if (this.props.finishButtonOnClick) {
      this.props.finishButtonOnClick(false);
    }
  }

  render() {
    const { header, answerPrefix, questionLength, activeQuestion, resultInfo} = this.props;
    let coloredText = " text-muted ";
    let questionResult = "";
    let rightAnswer = "";
    let time = "";
    if(resultInfo){
      questionResult = " Boş Cevap ";
      if(activeQuestion.qtype !== "multichoice"){
        rightAnswer = " - " + activeQuestion.answer;
      }
      if (!activeQuestion.empty){
        if( activeQuestion.correct ){
          questionResult = " Doğru Cevap ";
          coloredText = " text-success ";
          rightAnswer = "";
        }else{
          questionResult = " Yanlış Cevap ";
          coloredText = " text-danger ";
        }

      }

      if(activeQuestion.hour !== undefined && activeQuestion.hour.toString().length === 1){
        activeQuestion.hour = "0"+activeQuestion.hour;
      }
      if(activeQuestion.min !== undefined && activeQuestion.min.toString().length === 1){
        activeQuestion.min = "0"+activeQuestion.min;
      }
      if(activeQuestion.sec !== undefined && activeQuestion.sec.toString().length === 1){
        activeQuestion.sec = "0"+activeQuestion.sec;
      }
      if(activeQuestion.hour !== undefined && activeQuestion.min !== undefined && activeQuestion.sec !== undefined){
        time = " - Geçen Süre: " + activeQuestion.hour + ":" + activeQuestion.min + ":" + activeQuestion.sec ;
      }
    }
    return (
        <React.Fragment>
              <b className="text-muted">{header}</b>
              <br></br>
              <b className={coloredText}>Soru {activeQuestion.listIndex + 1} / {questionLength} {questionResult} {time}</b>
              {
                  activeQuestion.parentText &&
                  <Card>
                    <CardHeader className="bg-white font-weight-bold questionParentHeader">
                      <div dangerouslySetInnerHTML={{ __html: activeQuestion.parentText }}></div>
                    </CardHeader>
                  </Card>
              }
              {
                  activeQuestion.questionText &&
                  <Card>
                    <CardBody className="font-weight-bold questionHeader">
                      <div dangerouslySetInnerHTML={{ __html: activeQuestion.questionText }}></div>
                    </CardBody>
                  </Card>
              }
              {
                activeQuestion.childQuestion &&
                <Card>
                  <CardBody className="font-weight-bold questionHeader">
                    <div dangerouslySetInnerHTML={{ __html: activeQuestion.childQuestion }}></div>
                  </CardBody>
                </Card>
              }
              <b className="text-muted">Cevaplar</b>
              <Card className="mb-1">

                  {
                      activeQuestion.qtype === "multichoice" ?
                          <CardBody className="ml-2">
                              {
                                  activeQuestion.Answers.map((answer, index) => {
                                      let className = " form-check-label ";
                                      let radioClassName = " form-check-input ";
                                      let iconClass = "";
                                      let checkboxPaddingLeft = "radio";
                                      if(resultInfo ){
                                        checkboxPaddingLeft = checkboxPaddingLeft + " pl-0 "
                                        iconClass = " icon-close icons text-white font-lg align-middle  font-weight-bold pr-2";
                                        if(!activeQuestion.empty){
                                          if(!activeQuestion.correct && answer.correct){
                                            className = className + " bg-gray ";
                                            iconClass = " icon-check icons text-gray font-lg align-middle  font-weight-bold pr-2";
                                          }
                                          if(answer.id === activeQuestion.responseId){
                                            if (!activeQuestion.correct){
                                              className = className + " bg-red ";
                                              iconClass = " icon-close icons text-danger font-lg align-middle  font-weight-bold pr-2";
                                            }else{
                                              className = className + " bg-green ";
                                              iconClass = " icon-check icons text-success font-lg align-middle  font-weight-bold pr-2";
                                            }
                                          }
                                        }else if(answer.correct){
                                          className = className + " bg-gray ";
                                          iconClass = " icon-check icons text-gray font-lg align-middle  font-weight-bold pr-2";
                                        }
                                        radioClassName = radioClassName + " d-none ";
                                      }else{
                                        iconClass = " d-none ";
                                        if(answer.id === activeQuestion.responseId){
                                          checkboxPaddingLeft = checkboxPaddingLeft + ' selected';
                                        }

                                      }

                                      return <FormGroup key={index} row check className={checkboxPaddingLeft}>
                                        <i className={iconClass}></i>
                                        <Input className={radioClassName} type="radio" id={"answer_" + answer.id} name={"questionAnswer"} value={answer.id} onClick={this.onChangeAnswer.bind(this)} />
                                        <Label check className={className} htmlFor={"answer_" + answer.id} >{answerPrefix[index]}<span className={'answerTextSpan'} dangerouslySetInnerHTML={{ __html: answer.answer }}></span></Label>

                                      </FormGroup>
                                  })
                              }
                          </CardBody>
                          :
                          <CardBody className="ml-2">
                              <FormGroup row>
                                  <Input type="textarea" name="textarea-input" id="reply" placeholder="Cevabınız ..." onChange={this.onChangeTextAnswer.bind(this)} />
                              </FormGroup>
                              {
                                resultInfo &&
                                <b className={coloredText}>{questionResult} {rightAnswer}</b>
                              }
                          </CardBody>
                  }

                <CardFooter>
                    {
                        (activeQuestion.listIndex !== 0) &&
                        <Button onClick={this.previousButtonOnClick.bind(this)} color="primary font-weight-bold"><i className="fa fa-arrow-circle-left fa-lg"></i>&nbsp;Önceki Soru</Button>
                    }
                    {
                        activeQuestion.listIndex + 1 < questionLength &&
                        <Button onClick={this.nextButtonOnClick.bind(this)} color="primary font-weight-bold" className="pull-right">Sonraki Soru&nbsp;<i className="fa fa-arrow-circle-right fa-lg"></i></Button>
                    }
                </CardFooter>
                {
                  (this.props.finishButtonOnClick) &&
                  <CardFooter>
                      <Button onClick={this.finishButtonOnClick.bind(this)} color="success font-weight-bold" className="pull-right">Sınavı Bitir&nbsp;<i className="fa fa-check-square fa-lg"></i></Button>
                  </CardFooter>
                }
              </Card>
        </React.Fragment>
    );
  }
}

ExamInfo.propTypes = propTypes;
ExamInfo.defaultProps = defaultProps;

export default ExamInfo;
