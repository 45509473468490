import React, { Component } from 'react';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row
} from 'reactstrap';

import moment from 'moment';
import queryString from 'query-string';

import _xhr from '../../utils/_xhr';
import rest from '../../utils/rest.cfg';
import cookie from '../../utils/cookie';
import project from '../../utils/project';

import ExamWidget from '../../components/Exams';

const usersUrl = rest('users');
const _quizresultsUrl = rest('quizresults');
const auth = rest('auth');
const _quizResultUser = rest('quizResultUser');
const _userUrl = rest('users');
class Results extends Component {
  constructor(props) {
      super(props);

      this.state = {
        quizAttempts: [],
        formerExams : [],
        userId : "",
        user: {}
      };
  }

  componentWillMount() {

  }

  componentDidMount() {
    let self = this;
    let formerExams = [];

    self.getQuizAttempts().then(() => {
      let quizAttempts = self.state.quizAttempts;

      /* quizAttempts.forEach(function(quizAttempt) {
        if(quizAttempt.completed === true){
          formerExams.push(quizAttempt);
        }
      });

      self.setState({
        formerExams:formerExams
      }) */
    })

    let userId = '';
    if(this.props.location.search !== "" && this.props.location.search.indexOf("userId") > -1 ){
      let parsed = queryString.parse(this.props.location.search);
      userId = parsed.userId;
      if(userId) {
        let userUrl = _userUrl;
        _xhr.get({
          url: userUrl + '/' + userId,
          onsuccess: function ({ data }) {
            self.setState({
              user:data
            })
          }
        });
      }
    }
  }

  componentWillUpdate() {

  }

  componentDidUpdate() {

  }

  componentWillReceiveProps(newProps) {

  }

  getQuizAttempts(){
    let self = this;

    let userId = '';
    if(this.props.location.search !== "" && this.props.location.search.indexOf("userId") > -1 ){
      let parsed = queryString.parse(this.props.location.search);
      userId = parsed.userId;
      self.setState({
        userId:userId
      })
    }

    let quizResultsUrl = _quizresultsUrl + '/all';
    return new Promise((resolve, reject) => {
      _xhr.get({
          url: quizResultsUrl,
          onsuccess: function ({ data }) {
            if(!userId) {
              self.setState({
                quizAttempts:data
              })
            } else {
              self.getUsersQuizAttempt(userId)
            }

            resolve();
          }
      });
    });
  }

  getUsersQuizAttempt(userId) {
    let self = this;
    _xhr.post({
      url: _quizResultUser,
      params: {
        userId: userId
      },
      onsuccess: function({ data }) {
        self.setState({
          quizAttempts:data
        })
      }
    })
  }

  render() {
    return (
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <i className="fa fa-hourglass-3"></i>
                <b> {
                  this.state.user.name ?
                      this.state.user.name + ' ' + this.state.user.surname + ' - SINAV SONUÇLARI' :
                      'SINAV SONUÇLARI'
                }</b>
                <div className="card-header-actions">
                  <Badge pill color="success" className="float-right">{this.state.quizAttempts.length}</Badge>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  {
                    this.state.quizAttempts.length > 0 &&
                    this.state.quizAttempts.map((formerExam, index) => {
                      return <Col md="4" key={index}>
                                <ExamWidget url={this.state.userId > "" ?
                                "/results/" + formerExam.id + "?userId=" + this.state.userId :
                                "/results/" + formerExam.id}
                                            header={formerExam.quiz.name}
                                            invert={false}
                                            isQuizOpened={false}
                                            icon="icon-doc">
                                  {formerExam.quiz.intro}<br/>
                                  BAŞLANGIÇ    :{moment(formerExam.quiz.timeOpen).format(project("dateFormat"))}<br/>
                                  BİTİŞ        :{moment(formerExam.quiz.timeClose).format(project("dateFormat"))}<br/>
                                  SÜRE         :{formerExam.quiz.timeLimit} DAKİKA</ExamWidget>
                             </Col>
                    })
                  }
                  {
                    this.state.quizAttempts.length === 0 &&
                      <Col>
                        Görüntülenecek sonuç bulunmamaktadır.
                     </Col>
                  }
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

    );
  }
}

export default Results;
