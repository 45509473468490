import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import {
  Button,
  Col,
  Card,
  CardBody,
  CardHeader,
  /*Pagination,
  PaginationItem,
  PaginationLink,*/
  Row,
  Table,
    Input,
    InputGroup,
    InputGroupAddon,
  UncontrolledTooltip
} from 'reactstrap';

import swal from 'sweetalert';

import _xhr from '../../utils/_xhr';
import rest from '../../utils/rest.cfg';
import cookie from '../../utils/cookie';
import appConstants from '../../utils/appConstants';

const usersUrl = rest('users');
const _followingsUrl = rest('following');
const _followingUrl = rest('following');
const auth = rest('auth');

class Follow extends Component {
  constructor(props) {
      super(props);

      this.state = {
        followings : [],
        requests: [],
        emailToAdd: '',
        user: {}
      };
  }

  componentWillMount() {

  }

  componentDidMount() {
    this.getCurrentUser()
    this.getFollowingUsers()
  }

  getFollowingUsers(){
    let self = this;
    let followingUrl = _followingUrl + '/all';
    let userId = cookie.get(auth.user);
    let followings = [];
    let requests = [];

    _xhr.get({
        url: followingUrl,
        onsuccess: function ({ data }) {
          data.forEach(item => {
            if(item.createdBy !== parseInt(userId) && item.status === 'pending') {
              requests.push(item);
            } else if (item.created.id === parseInt(userId) && item.status === 'approved'){
              followings.push(item);
            }
          })
          self.setState({
            followings:followings,
            requests:requests,
          })
        }
    });
  }

  getCurrentUser(){
    let self = this;
    _xhr.get({
      url: usersUrl + "/" + cookie.get(auth.user),
      onsuccess: function ({ data }) {
        self.setState({
          user: data
        })
        },
      onerror: function({error}) {
        console.log(error);
      }
    })
  }

  componentWillUpdate() {

  }

  componentDidUpdate() {

  }

  componentWillReceiveProps(newProps) {

  }

  onCommonChange(e){

  }

  onPasswordAgainChange(e){

  }

  onCompetitionGroupIdChange(e){

  }

  onSubmit(e) {
  }

  onUnFollowUser(followingRow){

    if(followingRow){
      let self = this;
      swal("Onay",
            "\"" + followingRow.user.name.toUpperCase() + " " + followingRow.user.surname.toUpperCase()
            + "\" kişisini takip listenizden çıkaracaksınız. Devam etmek istiyor musunuz?",
      {
        icon: "warning",
        buttons: {
          cancel: "İptal",
          confirm: "Onay",
        }
      })
      .then((submit) => {
          if(submit){
            
            let unFollowUserUrl = _followingUrl + "/" + followingRow.id ;

            _xhr.delete({
                url: unFollowUserUrl,
                onsuccess: function ({ response }) {
                  self.setState({

                  });
                  self.getFollowingUsers();
                }
            });
          }
      });
    }
  }

  onUnApproveUser(followingRow){

    if(followingRow){
      let self = this;
      swal("Onay",
          "\"" + followingRow.created.name.toUpperCase() + " " + followingRow.created.surname.toUpperCase()
          + "\" kişinin takip isteğini reddeceksiniz devam etmek istiyor musunuz?",
          {
            icon: "warning",
            buttons: {
              cancel: "İptal",
              confirm: "Onay",
            }
          })
          .then((submit) => {
            if(submit){

              let unFollowUserUrl = _followingUrl + "/" + followingRow.id ;

              _xhr.delete({
                url: unFollowUserUrl,
                onsuccess: function ({ response }) {
                  self.setState({

                  });
                  self.getFollowingUsers();
                }
              });
            }
          });
    }
  }

  onApproveUser(row) {
    let self = this;
    let followingUrl = _followingUrl + '/' + row.id;

    _xhr.patch({
      url: followingUrl,
      params: {'status':'approved'},
      onsuccess: function ({ data }) {
        self.getFollowingUsers();
      }
    });
  }

  onFollowUser() {
    let self = this;
    let email = self.state.emailToAdd;
    let followingUrl = _followingUrl + '/add';
    _xhr.post({
      url: followingUrl,
      params: {'email':email},
      onsuccess: function({data}) {
        alert("Kullanıcıya istek gönderildi kullanıcı kabul ettiğinde Takip Ettiklerim listesinde görüntüleyebilirsiniz.")
        self.state.emailToAdd = '';
      },
      onerror: function({err}) {
        alert("Kullanıcı eklenmedi veya zaten ekli lütfen mail adresini kontrol edin!")
      }
    })
  }

  onInputChange(e){
    let obj = e.target.value;

    this.setState({
      emailToAdd:obj
    })
  }

  render() {
    return (
      <Row>
        <Col>
          {
            this.state.user.type === 2 &&
            <div>
              <p>Takip etmek istediğiniz kullanıcının e-posta adresini giriniz gönder tuşuna basınız. Kişinin kabul etmesi halinde takip edebileceksiniz.</p>
              <InputGroup>
                <Input value={this.state.emailToAdd} onChange={this.onInputChange.bind(this)} type="email" name="emailtoadd" id="emailtoadd" placeholder={'Kullanıcı Email'} />
                <InputGroupAddon addonType="append">
                  <Button onClick={this.onFollowUser.bind(this)}>GÖNDER</Button>
                </InputGroupAddon>
              </InputGroup>
            </div>
          }
          <br /><br />
          {
            this.state.user.type !== 2 &&
            <Card>
              <CardHeader>
                <i className="icon-people"></i> Gelen Takip İsteklerim
              </CardHeader>
              <CardBody>
                {/*<Row className="d-flex justify-content-end">
                <Col md="3" className="mb-3">
                  <Link to="/follow/addfollow">
                    <Button block outline color="primary" className="float-right"><i className="icon-user-follow"></i>&nbsp;&nbsp;Kullanıcı Ekle</Button>
                  </Link>
                </Col>
              </Row>*/}
                <Table responsive striped>
                  <thead>
                  <tr>
                    <th>Öğrenci</th>
                    <th className="text-center">E-posta Adresi</th>
                    <th className="text-center">Okul Adı</th>
                    <th className="text-center">Sınıf</th>
                    <th className="text-center"></th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.requests.length === 0 &&
                    <tr className="text-center">
                      <td colSpan="6" className="dataTables_empty">
                        Takip isteğiniz yok.
                      </td>
                    </tr>
                  }
                  {
                    this.state.requests.map((following,i) => {
                      return  <tr key={i}>
                        <td className="text-uppercase">{following.created.name} {following.created.surname}</td>
                        <td className="text-center">{following.created.email}</td>
                        <td className="text-center">{following.created.schoolName}</td>
                        <td className="text-center">{appConstants.getClassText(following.created.classroom)}</td>
                        <td>

                          <Button id={"btnUserFollow_" + i} outline color="primary" className="float-left"
                                  onClick={this.onApproveUser.bind(this, following)}>
                            <i className="icon-check"></i>
                            <UncontrolledTooltip placement="top" target={"btnUserFollow_" + i}>
                              Kabul Et
                            </UncontrolledTooltip>
                          </Button>

                          <span className="float-left">&nbsp;&nbsp;</span>

                          <Button id={"btnUserDeleteFollow_" + i} outline color="primary" className="float-left"
                                  onClick={this.onUnApproveUser.bind(this, following)}>
                            <i className="icon-close"></i>
                            <UncontrolledTooltip placement="top" target={"btnUserDeleteFollow_" + i}>
                              Reddet
                            </UncontrolledTooltip>
                          </Button>
                        </td>
                      </tr>
                    })
                  }
                  </tbody>
                </Table>
                {/*<Pagination>
                <PaginationItem disabled><PaginationLink previous tag="button">Prev</PaginationLink></PaginationItem>
                <PaginationItem active>
                  <PaginationLink tag="button">1</PaginationLink>
                </PaginationItem>
                <PaginationItem><PaginationLink tag="button">2</PaginationLink></PaginationItem>
                <PaginationItem><PaginationLink tag="button">3</PaginationLink></PaginationItem>
                <PaginationItem><PaginationLink tag="button">4</PaginationLink></PaginationItem>
                <PaginationItem><PaginationLink next tag="button">Next</PaginationLink></PaginationItem>
              </Pagination>*/}
              </CardBody>
            </Card>
          }
          {
            this.state.user.type === 2 &&
            <Card>
              <CardHeader>
                <i className="icon-people"></i> Takip Ettiklerim
              </CardHeader>
              <CardBody>
                {/*<Row className="d-flex justify-content-end">
                <Col md="3" className="mb-3">
                  <Link to="/follow/addfollow">
                    <Button block outline color="primary" className="float-right"><i className="icon-user-follow"></i>&nbsp;&nbsp;Kullanıcı Ekle</Button>
                  </Link>
                </Col>
              </Row>*/}
                <Table responsive striped>
                  <thead>
                  <tr>
                    <th>Öğrenci</th>
                    <th className="text-center">E-posta Adresi</th>
                    <th className="text-center">Okul Adı</th>
                    <th className="text-center">Sınıf</th>
                    <th className="text-center"></th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.followings.length === 0 &&
                    <tr className="text-center">
                      <td colSpan="6" className="dataTables_empty">
                        Takip ettiğiniz herhangi bir kişi bulunmamaktadır. Takip etmek istediğiniz kullanıcıya yukarıdaki email formu yardımıyla istek gönderebilirsiniz.
                      </td>
                    </tr>
                  }
                  {
                    this.state.followings.map((following,i) => {
                      return  <tr key={i}>
                        <td className="text-uppercase">{following.user.name} {following.user.surname}</td>
                        <td className="text-center">{following.user.email}</td>
                        <td className="text-center">{following.user.schoolName}</td>
                        <td className="text-center">{appConstants.getClassText(following.user.classroom)}</td>
                        <td>
                          <Link to={"/results?userId=" + following.user.id} className="text-decoration-none">
                            <Button id={"btnUserExams_" + i} outline color="primary" className="float-left">
                              <i className="icon-magnifier"></i>
                              <UncontrolledTooltip placement="top" target={"btnUserExams_" + i}>
                                Sonuçlar
                              </UncontrolledTooltip>
                            </Button>
                          </Link>

                          <span className="float-left">&nbsp;&nbsp;</span>

                          <Button id={"btnUserUnfollow_" + i} outline color="primary" className="float-left"
                                  onClick={this.onUnFollowUser.bind(this, following)}>
                            <i className="icon-user-unfollow"></i>
                            <UncontrolledTooltip placement="top" target={"btnUserUnfollow_" + i}>
                              Takipten Çıkar
                            </UncontrolledTooltip>
                          </Button>
                        </td>
                      </tr>
                    })
                  }
                  </tbody>
                </Table>
                {/*<Pagination>
                <PaginationItem disabled><PaginationLink previous tag="button">Prev</PaginationLink></PaginationItem>
                <PaginationItem active>
                  <PaginationLink tag="button">1</PaginationLink>
                </PaginationItem>
                <PaginationItem><PaginationLink tag="button">2</PaginationLink></PaginationItem>
                <PaginationItem><PaginationLink tag="button">3</PaginationLink></PaginationItem>
                <PaginationItem><PaginationLink tag="button">4</PaginationLink></PaginationItem>
                <PaginationItem><PaginationLink next tag="button">Next</PaginationLink></PaginationItem>
              </Pagination>*/}
              </CardBody>
            </Card>
          }
        </Col>
      </Row>
    );
  }
}

export default Follow;
