import React, { Component } from 'react';
import { Alert, Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import swal from 'sweetalert';
import axios from 'axios';
import rest from '../../utils/rest.cfg';
import cookie from '../../utils/cookie';

const loginUrl = rest('login');
const auth = rest('auth');
const checkdetails = rest('checkdetails');

class Login extends Component {
  constructor(props) {
      super(props);
      this.onSubmit = this.onSubmit.bind(this);

      this.state = {
          credentials: {
              email: "",
              password: ""
          }
      };
  }

  componentWillMount() {

  }

  componentDidMount() {

  }

  componentWillUpdate() {

  }

  componentDidUpdate() {

  }

  componentWillReceiveProps(newProps) {

  }

  onCommonChange(e){
      let obj = this.state.credentials;
      obj[e.target.name] = e.target.value;

      this.setState({
          credentials:obj
      })
  }

  onSubmit(e) {
      if(e){ //when password input field press "enter", e is undefined 
        e.preventDefault();
      }
      
      let self = this;
      let credentials = self.state.credentials;
      axios.post(loginUrl, credentials)
          .then( response => {
              if (response.data.token) {
                  cookie.delete(auth.token);
                  cookie.set(auth.token, response.data.token, 10*24*60*60*1000);
                  cookie.delete(auth.user);
                  cookie.set(auth.user, response.data.user.id, 10*24*60*60*1000);
                  cookie.delete(checkdetails.emailverify);
                  cookie.set(checkdetails.emailverify, "true", 24*60*60*1000); // one day

                  if(self.props.isPopup ){
                    window.location.reload('/');
                  } else {
                    self.props.history.push('/');
                  }
              }
          }).catch(err => {
            swal("Hatalı giriş! Lütfen e-posta adresinizi ve parolanızı kontrol edin.", {
              icon: "error",
            });
          });
  }

  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <div className={attributes.isPopup ? "" : "app flex-row align-items-center"}>
        <Container>
          <Row className="justify-content-center">
            <Col md="12">
              <CardGroup>
                <Card className="p-4">
                {
                  this.props.location.search !== "" && this.props.location.search.indexOf("page") > -1 &&
                    <Alert color="success">
                      Kullanıcınızı doğrulama işlemi tamamlanmıştır. Artık e-posta adresiniz ve parolanızla giriş yapabilirsiniz.
                    </Alert>
                }
                  <CardBody>
                    <h1>Giriş.</h1>
                    <p className="text-muted">Kullanıcı bilgilerinizle giriş yapabilirsiniz.</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input value={this.state.credentials["email"]} onChange={this.onCommonChange.bind(this)}  id="email" type="email" name="email" placeholder="E-posta Adresi" />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input value={this.state.credentials["password"]} 
                        onChange={this.onCommonChange.bind(this)}  
                        onKeyPress={event => {
                          if (event.key === "Enter") {
                            this.onSubmit();
                          }
                        }}
                        id="password" type="password" name="password" placeholder="Parola" />
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button color="primary" className="px-4" onClick={this.onSubmit}>Giriş</Button>
                      </Col>
                      <Col xs="6" className="text-center">
                        <Link to="/resetpassword">Parolamı unuttum?</Link>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className="text-white bg-primary py-5 " >
                  <CardBody className="text-center">
                    <div>
                      <h2>Kullanıcı Oluştur</h2>
                      <p>Yeni kullanıcı oluşturmak için lütfen kayıt olun.</p>
                      <Link to="/register"><Button color="primary" className="mt-3" active>Kayıt Ol!</Button></Link>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
