import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import _xhr from '../../utils/_xhr';
import swal from 'sweetalert';

import axios from 'axios';
import rest from '../../utils/rest.cfg';
import validationMessages from '../../utils/validationMessages';
const _resetUrl = rest('reset');
const _emailForgot = rest('emailForgot');

class ResetPassword extends Component {
  constructor(props) {
      super(props);
      this.onSubmit = this.onSubmit.bind(this);

      this.state = {
          credentials: {
              email: ""
          }
      };
  }

  componentWillMount() {

  }

  componentDidMount() {

  }

  componentWillUpdate() {

  }

  componentDidUpdate() {

  }

  componentWillReceiveProps(newProps) {

  }

  onCommonChange(e){
      let obj = this.state.credentials;
      obj[e.target.name] = e.target.value;

      this.setState({
          credentials:obj
      })
  }

  onSubmit(e) {
      e.preventDefault();
      let self = this;

      let credentials = self.state.credentials;
      _xhr.post({
          url: _emailForgot,
          params: credentials,
          onsuccess: function ({ data }) {
              swal(self.state.credentials.email + " adresine parola sıfırlama linki gönderildi.", {
                  icon: "success",
                  button: "Giriş"
              })
                  .then((value) => {
                      self.props.history.push('/login');
                  });
          },
          onerror: function ({ err }){
              swal(validationMessages("mailNotFound"), {
                  icon: "error",
              });
          }
      })
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">
                <CardBody className="p-4">
                  <h1>Parola Sıfırlama</h1>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>@</InputGroupText>
                    </InputGroupAddon>
                    <Input value={this.state.credentials["email"]} onChange={this.onCommonChange.bind(this)}  id="email" type="email" name="email" placeholder="E-posta adresi" />
                  </InputGroup>
                  <Button color="success" onClick={this.onSubmit} block>Sıfırla</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ResetPassword;
