
let createCookie = function(name,value,ttl) {
    var expires = "";
    if (ttl) {
        var date = new Date();
        date.setTime(date.getTime() + ttl);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

let readCookie = function(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

let eraseCookie = function(name) {
    createCookie(name,"",-1);
}

export default {
    'get' : readCookie,
    'set' : createCookie,
    'delete': eraseCookie
};
