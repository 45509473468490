import axios from 'axios';
import rest from './rest.cfg';
import cookie from './cookie';
import swal from 'sweetalert';
import validationMessages from './validationMessages';

const auth = rest("auth");
const processStart = function () {
    document.body.classList.add('onprocess');
}
const processStop = function () {
    document.body.classList.remove('onprocess');
}

let _isLoggedIn = function(){
  if(cookie.get(auth.token)) {
    return true;
  }

  return false;
}

/**
 *
 * @param opts url, params, onsuccess, onerror, oncomplete, indicator[boolean default true]]
 * @private
 */
let _get = function (opts) {

    if ( !opts.url) {
        console.error('_xhr: Must be an invalid url in options')
        return false;
    }

    if ( opts.params && typeof opts.params !== "object" ) {
        console.error('_xhr: params must be a json object in options')
        return false;
    }

    let url = opts.url;
    let isLoggedIn = _isLoggedIn();
    let headers = {};
    if(isLoggedIn) {
        headers = {'x-auth': cookie.get(auth.token)}
    }

    let params = opts.params || {};

    if (opts.stringData) {
        for (let key in opts.stringData) {
            let val = opts.stringData[key];
            if (typeof val === "object")  {
                val = JSON.stringify(val)
            }

            let querySeperator = "?";
            if(isLoggedIn){
              querySeperator = "&";
            }
            url = url + querySeperator + key + "=" + val;
        }
    }

    processStart();

    axios.get(url, {
        params: params,
        headers: headers
    })
    .then(function (response) {
        processStop();
        if ( opts.onsuccess && typeof opts.onsuccess === "function" ) {
            opts.onsuccess(response);
        }

        if ( opts.oncomplete && typeof opts.oncomplete === "function" ) {
            opts.oncomplete(response);
        }
    })
    .catch(function (error) {
        processStop();
        if ( opts.onerror && typeof opts.onerror === "function" ) {
            opts.onerror(error);
        }else{
          swal(validationMessages("technicalError"), {
            icon: "error"
          });
        }
    });

}

/**
 *
 * @param opts url, params, onsuccess, onerror, oncomplete, indicator[boolean default true]]
 * @private
 */
let _post = function (opts) {
    if ( !opts.url) {
        console.error('_xhr: Must be an invalid url in options')
        return false;
    }

    if ( opts.params && typeof opts.params !== "object" ) {
        console.error('_xhr: params must be a json object in options')
        return false;
    }

    let url = opts.url;
    let headers = {};
    let isLoggedIn = _isLoggedIn();
    if(isLoggedIn) {
        headers = {'x-auth': cookie.get(auth.token)}
    }

    let params = opts.params || {};

    if (opts.stringData) {
        for (let key in opts.stringData) {
            let val = opts.stringData[key];
            if (typeof val === "object")  {
                val = JSON.stringify(val)
            }
            let querySeperator = "?";
            if(isLoggedIn){
              querySeperator = "&";
            }
            url = url + querySeperator + key + "=" + val;
        }
    }

    processStart();

    axios.post(url, params, {headers})
        .then(function (response) {
            processStop();
            if ( opts.onsuccess && typeof opts.onsuccess === "function" ) {
                opts.onsuccess(response);
            }
        })
        .catch(function (error) {
            processStop();
            let showDefaultErrorBox = true;
            if ( opts.onerror && typeof opts.onerror === "function" ) {
                showDefaultErrorBox = false;
                opts.onerror(error);
            }

            if ( opts.oncomplete && typeof opts.oncomplete === "function" ) {
                showDefaultErrorBox = false;
                opts.oncomplete(error);
            }

            if( showDefaultErrorBox){
              swal(validationMessages("technicalError"), {
                icon: "error"
              });
            }


        });

}

/**
 *
 * @param opts url, params, onsuccess, onerror, oncomplete, indicator[boolean default true]]
 * @private
 */
let _delete = function (opts) {
    if ( !opts.url) {
        console.error('_xhr: Must be an invalid url in options')
        return false;
    }

    if ( opts.params && typeof opts.params !== "object" ) {
        console.error('_xhr: params must be a json object in options')
        return false;
    }

    let url = opts.url;
    let isLoggedIn = _isLoggedIn();
    let headers = {};
    if(isLoggedIn) {
        headers = {'x-auth': cookie.get(auth.token)}
    }
    let params = opts.params || {};

    if (opts.stringData) {
        for (let key in opts.stringData) {
            let val = opts.stringData[key];
            if (typeof val === "object")  {
                val = JSON.stringify(val)
            }
            let querySeperator = "?";
            if(isLoggedIn){
                querySeperator = "&";
            }
            url = url + querySeperator + key + "=" + val;
        }
    }

    processStart();


    axios.delete(url, {headers})
        .then(function (response) {
            processStop();
            if ( opts.onsuccess && typeof opts.onsuccess === "function" ) {
                opts.onsuccess(response);
            }
        })
        .catch(function (error) {
            processStop();
            let showDefaultErrorBox = true;
            if ( opts.onerror && typeof opts.onerror === "function" ) {
                showDefaultErrorBox = false;
                opts.onerror(error);
            }

            if ( opts.oncomplete && typeof opts.oncomplete === "function" ) {
                showDefaultErrorBox = false;
                opts.oncomplete(error);
            }

            if( showDefaultErrorBox){
              swal(validationMessages("technicalError"), {
                icon: "error"
              });
            }


        });

}

let _put = function (opts) {
    if ( !opts.url) {
        console.error('_xhr: Must be an invalid url in options')
        return false;
    }

    if ( opts.params && typeof opts.params !== "object" ) {
        console.error('_xhr: params must be a json object in options')
        return false;
    }

    let url = opts.url;
    let isLoggedIn = _isLoggedIn();
    if(isLoggedIn) {
        url = url + "?" + auth.name + "=" + cookie.get(auth.name);
    }
    let params = opts.params || {};

    if (opts.stringData) {
        for (let key in opts.stringData) {
            let val = opts.stringData[key];
            if (typeof val === "object")  {
                val = JSON.stringify(val)
            }
            let querySeperator = "?";
            if(isLoggedIn){
              querySeperator = "&";
            }
            url = url + querySeperator + key + "=" + val;
        }
    }

    processStart();

    axios.put(url, params)
        .then(function (response) {
            processStop();
            if ( opts.onsuccess && typeof opts.onsuccess === "function" ) {
                opts.onsuccess(response);
            }

        })
        .catch(function (error) {
          processStop();
          let showDefaultErrorBox = true;
          if ( opts.onerror && typeof opts.onerror === "function" ) {
              showDefaultErrorBox = false;
              opts.onerror(error);
          }

          if ( opts.oncomplete && typeof opts.oncomplete === "function" ) {
              showDefaultErrorBox = false;
              opts.oncomplete(error);
          }

          if( showDefaultErrorBox){
            swal(validationMessages("technicalError"), {
              icon: "error"
            });
          }
        });

}


/**
 *
 * @param opts url, params, onsuccess, onerror, oncomplete, indicator[boolean default true]]
 * @private
 */
let _patch = function (opts) {
    if ( !opts.url) {
        console.error('_xhr: Must be an invalid url in options')
        return false;
    }

    if ( opts.params && typeof opts.params !== "object" ) {
        console.error('_xhr: params must be a json object in options')
        return false;
    }

    let url = opts.url;
    let headers = {};
    let isLoggedIn = _isLoggedIn();
    if(isLoggedIn) {
        headers = {'x-auth': cookie.get(auth.token)}
    }

    let params = opts.params || {};

    if (opts.stringData) {
        for (let key in opts.stringData) {
            let val = opts.stringData[key];
            if (typeof val === "object")  {
                val = JSON.stringify(val)
            }
            let querySeperator = "?";
            if(isLoggedIn){
              querySeperator = "&";
            }
            url = url + querySeperator + key + "=" + val;
        }
    }

    processStart();

    axios.patch(url, params, {headers})
        .then(function (response) {
            processStop();
            if ( opts.onsuccess && typeof opts.onsuccess === "function" ) {
                opts.onsuccess(response);
            }
        })
        .catch(function (error) {
            processStop();
            let showDefaultErrorBox = true;
            if ( opts.onerror && typeof opts.onerror === "function" ) {
                showDefaultErrorBox = false;
                opts.onerror(error);
            }

            if ( opts.oncomplete && typeof opts.oncomplete === "function" ) {
                showDefaultErrorBox = false;
                opts.oncomplete(error);
            }

            if( showDefaultErrorBox){
              swal(validationMessages("technicalError"), {
                icon: "error"
              });
            }


        });

}

export default {
    'get' : _get,
    'post' : _post,
    'delete': _delete,
    'put': _put,
    'patch': _patch
};
