import React, { Component } from 'react';
import {Button, Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import moment from "moment";
import _xhr from '../../utils/_xhr';
import rest from '../../utils/rest.cfg';
import classnames from "classnames";
import ExamWidget from "../../components/Exams";
import project from "../../utils/project";

class Publications extends Component {
    constructor(props) {

        super(props);

        this.state = {
            publications: [],
            activeTab: '1'
        };

        moment.locale('tr');  //set date lang to 'tr'
    }

    componentDidMount() {
        this.getAllPublications();
    }

    getAllPublications() {
        let self = this;
        _xhr.get({
            url: rest('rest') + 'publication/all',
            onsuccess: function({ data }){
                console.log(data);
                self.setState({
                    publications: data
                })
            }
        })
    }

    toggle(tab) {
        if(this.state.activeTab !== tab) this.state.activeTab = tab;
    }

    render() {
        return(
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <i className="fa fa-book"></i>
                            <b>Yayınlar</b>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="12">
                                    <div style={{width: '100%', marginBottom: '20px'}}>
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '1' })}
                                                    onClick={() => this.setState({activeTab: '1'})}
                                                >
                                                    <b>Dergiler</b>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: this.state.activeTab === '2' })}
                                                    onClick={() => this.setState({activeTab: '2'})}
                                                >
                                                    <b>Kitaplar</b>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={this.state.activeTab} md="12">
                                            <TabPane tabId="1">
                                                <Row>
                                                    {
                                                        this.state.publications.length > 0 &&
                                                        this.state.publications.map((publication, index) => {
                                                            if(publication.publicationType === 'dergi') {
                                                                return <Col md="4" key={index}>
                                                                    <ExamWidget url={"/publications/" + publication.id}
                                                                                header={publication.name}
                                                                                invert={publication.isQuizOpened}
                                                                                isQuizOpened={publication.isQuizOpened}
                                                                                icon={publication.isUsed ? "icon-star" : publication.isPremium ? "icon-lock" : "icon-book-open"}>
                                                                        {publication.description}</ExamWidget>
                                                                </Col>
                                                            }
                                                        })
                                                    }
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <Row>
                                                    {
                                                        this.state.publications.length > 0 &&
                                                        this.state.publications.map((publication, index) => {
                                                            if(publication.publicationType === 'kitap') {
                                                                return <Col md="4" key={index}>
                                                                    <ExamWidget url={"/publications/" + publication.id}
                                                                                header={publication.name}
                                                                                invert={publication.isQuizOpened}
                                                                                isQuizOpened={publication.isQuizOpened}
                                                                                icon={publication.isUsed ? "icon-star" : publication.isPremium ? "icon-lock" : "icon-book-open"}>
                                                                        {publication.description}</ExamWidget>
                                                                </Col>
                                                            }
                                                        })
                                                    }
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                    {/*<div style={{width: '100%', marginBottom: '20px'}}>
                                        {
                                            this.state.publications.length > 0 &&
                                            this.state.publications.map((publicationItam, index) => {
                                                return <Card>
                                                    <CardHeader className='d-flex'>
                                                        <b>{publicationItam.name}</b><span className='ml-auto'>Yıl: {publicationItam.year}</span>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div
                                                            className='mb-3'>{publicationItam.description}</div>
                                                    </CardBody>
                                                </Card>
                                            })
                                        }
                                    </div>*/}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default Publications;
