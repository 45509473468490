export default {
  items: [
    {
      title: true,
      name: 'Portal',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Anasayfa',
      url: '/home',
      icon: 'icon-grid',
    },
    {
      name: 'YKS/YDT Sınavları',
      url: '/exams',
      icon: 'icon-calculator',
    },
    {
      name: 'YDS Sınavları',
      url: '/exams-yds',
      icon: 'icon-calculator',
    },
    {
      name: 'Sınav Sonuçları',
      url: '/results',
      icon: 'icon-chart',
    },
    {
      name: 'Sonuç Raporu',
      url: '/statistics',
      icon: 'icon-chart',
      // allowedRoles: ['99'], only for teacher
    },
    {
      name: 'Mağaza',
      url: '/store',
      icon: 'icon-basket'
    },
    {
      name: 'Yayınlar',
      url: '/publications',
      icon: 'icon-book-open',
      role: 2
    },
    {
      name: 'Takip Listesi',
      url: '/follow',
      icon: 'icon-user-follow',
      badge: {
        variant: 'info'
      }
    },

    {
      name: 'Hakkımızda',
      url: '/hakkimizda',
      icon: 'icon-question'
    },
    {
      name: 'İletişim',
      url: '/iletisim',
      icon: 'icon-phone'
    }
    /*{
      name: 'İstatistik',
      url: '/statistics',
      icon: 'icon-pie-chart',
      badge: {
        variant: 'info',
        text: 'NEW',
      },
    },
    {
      divider: true,
    },
    {
      title: true,
      name: 'Ayarlar',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Parola Değişiklik',
      url: '/renewpassword',
      icon: 'icon-key',
    },
    */
    /*{
      name: 'Rehber',
      url: '/guide',
      icon: 'icon-doc',
      variant: 'danger',
    },*/
  ],
};
