import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'reactstrap';
import {Link} from 'react-router-dom';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class FullFooter extends Component {
  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <Col md="6" sm="12">
          <div className="footer-links">
            <Link to="/yasal-uyari">Yasal Uyarı</Link>
            <Link to="/mesafeli-satis-sozlesmesi">Mesafeli Satış Sözleşmesi</Link>
            <Link to="/teslimat-ve-iade">Teslimat ve İade</Link>
          </div>
        </Col>
          <Col md="6" sm="12">
            <img style={{width: '100%', maxWidth: '400px', float: 'right'}} src={process.env.PUBLIC_URL + 'assets/img/logo_band_colored@2x.png'} />
          </Col>
      </React.Fragment>
    );
  }
}

FullFooter.propTypes = propTypes;
FullFooter.defaultProps = defaultProps;

export default FullFooter;
