import React, { Component } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from 'reactstrap';

import moment from 'moment';
import 'moment/locale/tr';
import queryString from 'query-string';

import cookie from '../../utils/cookie';
import _xhr from '../../utils/_xhr';
import rest from '../../utils/rest.cfg';
import _ from 'lodash';

const _quizzesUrl = rest('quizzes');
const _quizResultUrl =rest('quizresults');
const auth = rest('auth');
const _quizResultUserOne = rest('quizResultUserOne');
const _userUrl = rest('users');
const pieChartConst = {
  labels: [
    'Doğru',
    'Yanlış',
    'Boş',
  ],
  datasets: [
    {
      data: [0, 0, 0],
      backgroundColor: [
        '#98e6ab',
        '#ec939c',
        '#ffdf80',
      ],
      hoverBackgroundColor: [
        '#98e6ab',
        '#ec939c',
        '#ffdf80',
      ],
    }],
};

const barChartConst = {
  labels: ['Doğru','Yanlış','Boş'],
  datasets: [
    {
      label: 'Sınav Ortalaması',
      backgroundColor: '#495057',
      borderColor: '#495057',
      borderWidth: 1,
      hoverBackgroundColor: '#495057',
      hoverBorderColor: '#495057',
      data: [0,0,0],
    },
    {
      label: 'Sınav Sonucu',
      backgroundColor: '#ced4da',
      borderColor: '#ced4da',
      borderWidth: 1,
      hoverBackgroundColor: '#ced4da',
      hoverBorderColor: '#ced4da',
      data: [0,0,0],
    },
  ],
};

const barChartConst2 = {
  labels: ['Süre (dk)'],
  datasets: [
    {
      label: 'Ortalama Tamamlama Süresi',
      backgroundColor: '#495057',
      borderColor: '#495057',
      borderWidth: 1,
      hoverBackgroundColor: '#495057',
      hoverBorderColor: '#495057',
      data: [0,0],
    },
    {
      label: 'Tamamlama Süresi',
      backgroundColor: '#ced4da',
      borderColor: '#ced4da',
      borderWidth: 1,
      hoverBackgroundColor: '#ced4da',
      hoverBorderColor: '#ced4da',
      data: [0,0],
    },
  ],
};

const options = {
  tooltips: {
    enabled: false,
    custom: CustomTooltips
  },
  maintainAspectRatio: false
}

const pagingCount = 20;

class ExamResults extends Component {
  constructor(props) {
      super(props);

      this.state = {
        isExamCalculated: false,
        myRanking: 0,
        numberOfAttendee: 0,
        myPercentage: "%0",
        totalTimeElapsed: "00:00:00",
        myCorrectCount: 0,
        myFalseCount: 0,
        myEmptyCount: 0,
        pieChartCorrectFalseCountData: pieChartConst,
        barChartCorrectFalseAverageData: barChartConst,
        barChartAverageTimeData: barChartConst2,
        allRanking: [],
        pagingRanking: [],
        pageNum: 1,
        activePage: 1,
        totalPage: 1,
        showAllList: false,
        quizExp: {},
        userId: "",
        user: {}
      };

      this.toggleAllList = this.toggleAllList.bind(this);
      moment.locale('tr');
  }

  componentWillMount() {
  }

  componentDidMount() {
    let self = this;
/*     _xhr.get({
        url: _quizzesUrl + "/" + self.props.match.params.examId,
        onsuccess: function ({ data }) {
          self.setState({
            quizExp:data
          })
        }
    }); */
    let userId = '';
    if(this.props.location.search !== "" && this.props.location.search.indexOf("userId") > -1 ){
      let parsed = queryString.parse(this.props.location.search);
      userId = parsed.userId;
      if(userId) {
        let userUrl = _userUrl;
        _xhr.get({
          url: userUrl + '/' + userId,
          onsuccess: function ({ data }) {
            self.setState({
              user:data
            })
          }
        });
      }
      self.setState({
        userId:userId
      })
    }
    if(userId) {
      self.getUserQuizResult(userId);
    } else {
      self.getSelfQuizResult();
    }


  }

  getSelfQuizResult() {
    let self = this;
    _xhr.get({
      url: _quizResultUrl + '/' + self.props.match.params.examId,
      onsuccess: function (response) {
       self.calculateResult(response);
      }
    });
  }

  getUserQuizResult(userId) {
    let self = this;
    _xhr.post({
      url: _quizResultUserOne,
      params: {
        resultId: self.props.match.params.examId,
        userId: userId
      },
      onsuccess: function (response) {
        self.calculateResult(response);
      }
    });
  }

  calculateResult(response) {
    let self = this;
    if(response.data) {
      let isExamCalculated = true;
      let data = response.data.data;
      let avg = response.data.avg;
      let myData = response.data.data.score;
      if(myData === undefined){
        isExamCalculated = false;
      } else  {


        let averageCorrectCount = '';
        let averageFalseCount = '';
        let averageEmptyCount = '';

        let averageTimeMinutes = -'';
        let myAverageTimeMinutes = '';

        let pieChartData = self.state.pieChartCorrectFalseCountData;
        pieChartData.datasets[0].data = [data.correctCount, data.falseCount, data.emptyCount];

        let barChartData = self.state.barChartCorrectFalseAverageData;
        barChartData.datasets[0].data = [avg.avgCorrectCount, avg.avgFalseCount, avg.avgEmptyCount];
        barChartData.datasets[1].data = [data.correctCount, data.falseCount, data.emptyCount];
        let avgTime = parseInt(avg.avgTotalTimeElapsed);
        let barChartData2 = self.state.barChartAverageTimeData;
        barChartData2.datasets[0].data = [(avgTime / 60000).toFixed(1), 0];
        barChartData2.datasets[1].data = [(data.totalTimeElapsed / 60000).toFixed(1), 0];
        //(new Date(data * 1000)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0];
        self.setState({
          isExamCalculated: isExamCalculated,
          myCorrectCount: data.correctCount,
          myFalseCount: data.falseCount,
          myEmptyCount: data.emptyCount,
          totalTimeElapsed: (new Date(data.totalTimeElapsed)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0],
          pieChartCorrectFalseCountData: pieChartData,
          quizId: data.quiz.id
        })
        let userId = '';
        if(this.props.location.search !== "" && this.props.location.search.indexOf("userId") > -1 ){
          let parsed = queryString.parse(this.props.location.search);
          userId = parsed.userId;
          if(userId) {
            _xhr.get({
              url: _quizResultUrl + '/detail-follow/' + self.state.quizId + '/' + userId,
              onsuccess: function (response) {
                let myRanking = _.findIndex(response.data, {isMyScore : true}) + 1;
                let myPercentage = "%" + (myRanking*100/response.data.length).toFixed(2);
                self.setState({
                  myRanking: myRanking,
                  numberOfAttendee: response.data.length,
                  myPercentage: myPercentage,
                  allRanking: response.data,
                  pagingRanking: response.data.slice(0,pagingCount),
                  totalPage: parseInt(Math.ceil(response.data.length/pagingCount), 10),
                })
              }
            })
            self.setState({
              barChartCorrectFalseAverageData: barChartData,
              barChartAverageTimeData: barChartData2,
            })
          }
          self.setState({
            userId:userId
          })
        } else {
          _xhr.get({
            url: _quizResultUrl + '/detail/' + self.state.quizId,
            onsuccess: function (response) {
              let myRanking = _.findIndex(response.data, {isMyScore : true}) + 1;
              let myPercentage = "%" + (myRanking*100/response.data.length).toFixed(2);
              self.setState({
                myRanking: myRanking,
                numberOfAttendee: response.data.length,
                myPercentage: myPercentage,
                allRanking: response.data,
                pagingRanking: response.data.slice(0,pagingCount),
                totalPage: parseInt(Math.ceil(response.data.length/pagingCount), 10),
              })
            }
          })
          self.setState({
            barChartCorrectFalseAverageData: barChartData,
            barChartAverageTimeData: barChartData2,
          })
        }


      }
    }
  }

  componentWillUpdate() {

  }

  componentDidUpdate() {

  }

  componentWillReceiveProps(newProps) {

  }

  toggleAllList() {
    this.setState({
      showAllList: !this.state.showAllList,
    });
  }

  msToTime(duration) {
    let seconds = parseInt((duration / 1000) % 60, 10),
        minutes = parseInt((duration / (1000 * 60)) % 60, 10),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24, 10);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  }

  changePagination(direction){
    let self = this;
    let activePage;
    let pageNum;
    if(direction === "next"){
      pageNum = ((this.state.pageNum + 4) !== self.state.totalPage && self.state.activePage === (this.state.pageNum + 4)) ? (self.state.pageNum + 1) : self.state.pageNum;
      activePage = (self.state.activePage + 1) > self.state.totalPage ? self.state.totalPage : (self.state.activePage + 1);
      self.setState({
        pageNum: pageNum,
        activePage: activePage,
        pagingRanking: self.state.allRanking.slice((activePage-1)*pagingCount, activePage*pagingCount)
      })

    } else {
      pageNum = (self.state.pageNum !== 1 && self.state.pageNum === self.state.activePage) ? (self.state.pageNum - 1) : self.state.pageNum;
      activePage = (self.state.activePage - 1) < 1 ? self.state.activePage : (self.state.activePage - 1);
      self.setState({
        pageNum: pageNum,
        activePage: activePage,
        pagingRanking: self.state.allRanking.slice((activePage-1)*pagingCount, activePage*pagingCount)
      })
    }
  }

  changeActivePage(pageNum){
    let self = this;
    self.setState({
      activePage: pageNum,
      pagingRanking: self.state.allRanking.slice((pageNum-1)*pagingCount, pageNum*pagingCount)
    })

  }

  render() {
    return (
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <i className="fa fa-hourglass-3"></i>
                <b> {
                  this.state.user.name ?
                      this.state.user.name + ' ' + this.state.user.surname + ' - SINAV SONUÇLARI' :
                      'SINAV SONUÇLARI'
                }</b>
              </CardHeader>
              <CardBody>
                {
                  this.state.isExamCalculated === true &&
                  <div>
                    <Row>
                      <Col>
                        <Card>
                          <CardHeader className="bg-info">
                            <i className="fa fa-list-alt"></i><strong>Sınav Özeti</strong>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col md="6">
                                <ListGroup>
                                  <ListGroupItem className="justify-content-between">
                                    <h5>Doğru Sayısı: <Badge className="float-right" pill color="primary">{this.state.myCorrectCount}</Badge></h5>
                                  </ListGroupItem>
                                  <ListGroupItem className="justify-content-between">
                                    <h5>Yanlış Sayısı: <Badge className="float-right" pill color="primary">{this.state.myFalseCount}</Badge></h5>
                                  </ListGroupItem>
                                  <ListGroupItem className="justify-content-between">
                                    <h5>Boş Sayısı: <Badge className="float-right" pill color="primary">{this.state.myEmptyCount}</Badge></h5>
                                  </ListGroupItem>
                                  <ListGroupItem className="justify-content-between">
                                    <h5>Tamamlama Süresi: <Badge className="float-right" pill color="primary">{this.state.totalTimeElapsed}</Badge></h5>
                                  </ListGroupItem>
                                </ListGroup>
                                <br/>
                                <ListGroup>
                                  <ListGroupItem className="justify-content-between">
                                    <h5>Sınav Sıralaması: <Badge className="float-right" pill color="primary">{this.state.myRanking}</Badge></h5>
                                  </ListGroupItem>
                                  <ListGroupItem className="justify-content-between">
                                    <h5>Sınava Katılan Kişi Sayısı: <Badge className="float-right" pill color="primary">{this.state.numberOfAttendee}</Badge></h5>
                                  </ListGroupItem>
                                  <ListGroupItem className="justify-content-between">
                                    <h5>Yüzdelik Dilim: <Badge className="float-right" pill color="primary">{this.state.myPercentage}</Badge></h5>
                                  </ListGroupItem>
                                  <ListGroupItem className="justify-content-between"><Button block color="ghost-primary" onClick={this.toggleAllList} className="mr-1">Tüm Sıralamayı Gör</Button></ListGroupItem>
                                  <Modal isOpen={this.state.showAllList} toggle={this.toggleAllList}
                                         className="modal-primary">
                                    <ModalHeader toggle={this.toggleAllList}>Sınav Sıralaması</ModalHeader>
                                    <ModalBody>
                                      <Table responsive size="sm">
                                        <thead>
                                          <tr>
                                            <th>#</th>
                                            <th>Ad</th>
                                            <th>Soyad</th>
                                            <th>Net</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                        {
                                          this.state.pagingRanking.length > 0 &&
                                          this.state.pagingRanking.map((rank, index) => {
                                            if(rank.isMyScore === true){
                                              return <tr key={index} className="bg-danger">
                                                        <td>{(this.state.activePage-1)*pagingCount + index + 1}</td>
                                                        <td>{rank.user.name}</td>
                                                        <td>{rank.user.surname}</td>
                                                        <td>{rank.score}</td>
                                                      </tr>
                                            } else {
                                              return <tr key={index}>
                                                        <td>{(this.state.activePage-1)*pagingCount + index + 1}</td>
                                                        <td>{rank.user.name}</td>
                                                        <td>{rank.user.surname}</td>
                                                        <td>{rank.score}</td>
                                                      </tr>
                                            }
                                          })
                                        }
                                        </tbody>
                                      </Table>
                                      <Pagination size="md" className="justify-content-center">
                                        <PaginationItem className={this.state.activePage === 1 ? "disabled" : ""}>
                                          <PaginationLink previous tag="button" onClick={this.changePagination.bind(this, "previous")}/>
                                        </PaginationItem>
                                        <PaginationItem className={this.state.activePage === (this.state.pageNum) ? "active" : ""}>
                                          <PaginationLink tag="button" onClick={this.changeActivePage.bind(this, this.state.pageNum)}>
                                            {this.state.pageNum}
                                          </PaginationLink>
                                        </PaginationItem>
                                        {
                                          this.state.totalPage > 1 && (this.state.pageNum + 1) <= this.state.totalPage &&
                                          <PaginationItem className={this.state.activePage === (this.state.pageNum + 1) ? "active" : ""}>
                                            <PaginationLink tag="button" onClick={this.changeActivePage.bind(this, this.state.pageNum + 1)}>
                                              {this.state.pageNum + 1}
                                            </PaginationLink>
                                          </PaginationItem>
                                        }
                                        {
                                          this.state.totalPage > 2 && (this.state.pageNum + 2) <= this.state.totalPage &&
                                          <PaginationItem className={this.state.activePage === (this.state.pageNum + 2) ? "active" : ""}>
                                            <PaginationLink tag="button" onClick={this.changeActivePage.bind(this, this.state.pageNum + 2)}>
                                              {this.state.pageNum + 2}
                                            </PaginationLink>
                                          </PaginationItem>
                                        }
                                        {
                                          this.state.totalPage > 3 && (this.state.pageNum + 3) <= this.state.totalPage &&
                                          <PaginationItem className={this.state.activePage === (this.state.pageNum + 3) ? "active" : ""}>
                                            <PaginationLink tag="button" onClick={this.changeActivePage.bind(this, this.state.pageNum + 3)}>
                                              {this.state.pageNum + 3}
                                            </PaginationLink>
                                          </PaginationItem>
                                        }
                                        {
                                          this.state.totalPage > 4 && (this.state.pageNum + 4) <= this.state.totalPage &&
                                          <PaginationItem className={this.state.activePage === (this.state.pageNum + 4) ? "active" : ""}>
                                            <PaginationLink tag="button" onClick={this.changeActivePage.bind(this, this.state.pageNum + 4)}>
                                              {this.state.pageNum + 4}
                                            </PaginationLink>
                                          </PaginationItem>
                                        }
                                        <PaginationItem className={this.state.activePage === this.state.totalPage ? "disabled" : ""}>
                                          <PaginationLink next tag="button" onClick={this.changePagination.bind(this, "next")} />
                                        </PaginationItem>
                                      </Pagination>
                                    </ModalBody>
                                  </Modal>
                                </ListGroup>
                              </Col>
                              <Col md="6">
                                <Card>
                                  <CardBody>
                                    <div className="chart-wrapper">
                                      <Pie data={this.state.pieChartCorrectFalseCountData} />
                                    </div>
                                  </CardBody>
                                </Card>
                                <Card>
                                  <CardBody>
                                    <Link to={cookie.get(auth.user) !== this.state.userId ?
                                      "/results/examdetail/" + this.props.match.params.examId + '?userId=' + this.state.userId :
                                      "/results/examdetail/" + this.props.match.params.examId} className="text-decoration-none">
                                      <Button block color="success" onClick={this.toggleAllList} className="mr-1">Detaylı Soru İnceleme</Button>
                                    </Link>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card>
                          <CardHeader className="bg-info">
                            <i className="fa fa-list-alt"></i><strong>Sınav Ortalamaları</strong>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col md="6">
                                <Card>
                                  <CardBody>
                                    <div className="chart-wrapper">
                                      <Bar data={this.state.barChartCorrectFalseAverageData} options={options} />
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col md="6">
                                <Card>
                                  <CardBody>
                                    <div className="chart-wrapper">
                                      <Bar data={this.state.barChartAverageTimeData} options={options} />
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                }
                {
                  this.state.isExamCalculated === false &&
                  <b>Sınav sonuçları henüz hesaplanmamıştır.
                  <br/>
                  Sınav sonuçları açıklanma zamanı: {moment(this.state.quizExp.timeClose).format("DD/MM/YYYY HH:mm")}</b>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>


    );
  }
}

export default ExamResults;
