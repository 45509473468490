import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';

class About extends Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <i className="icon-question"></i><b>HAKKIMIZDA</b>
            </CardHeader>
            <CardBody>
              <div className="page-content">
                <p><b>ELS  YOĞUN BİR BİRİKİMİN ÜRÜNÜDÜR...</b></p>
                <p>ELS-LYS İngilizce Hazırlık Dergisi, 1994 yılında yayınlanmaya başlamıştır. Ancak ELS’i bugüne taşıyan birikim, 1994 yılının çok öncesine dayanmaktadır. ELS’i var eden çekirdek kadro, 1980’li yılların sonlarında, ÖYS-İngilizce dil sınavına hazırlık amacıyla Türkiye’de sınıf açıp eğitim veren ilk ekiptir.</p>

                <p>ELS yayınlarının alt yapısı o günlerde oluşmuş ve yetkinleştirilerek bugünlere taşınmıştır. Uzun sayılabilecek bu süreçte bu alanda biriktirdiklerimizi, deneyimlerimizi ilk kez 1994’te ELS yayınlarında dostlarımızla paylaşmaya başladık. Başlarken geçmişten taşıdığımız zenginlikler, birikimlerimiz vardı ve iddialıydık. Önümüze hedef olarak, geçmiş birikimlerimizi temel alarak kendimizi aşmayı, sürekli ve yeniden üreterek, daha zengin ve daha işlevsel bir yayın çıkarmayı koyduk. Bunda da başarılı olduk. Başarılı olduğumuza ilişkin iddiamız, kesinlikle öznel ve keyfi bir değerlendirmenin sonucu  değildi. Bu konuda en önemli veri, değerli dostlarımızın ve öğretmenlerimizin ELS’e ilişkin nesnel değerlendirmeleriydi. Bunlar bizi bir adım daha ileriye taşıyan, sınırlarımızı aşmaya zorlayan değerlendirmelerdi. Kısacası, en büyük katkı ve destek ELS’le sınava hazırlanan ve hazırlayan dostlarımızdan geldi.
                </p><p>
                Bu nedenle ELS, yazılı ve görsel basında reklam yapma gereksinimi duymadı. Zengin içeriği ve iç tutarlılığı olan yayınlarıyla ELS, kendine dil alanında eşsiz bir alan yarattı.
              </p><p>
                <b>ELS YAYINLARINI KİMLER VE NASIL HAZIRLIYOR?</b>
              </p><p>
                ELS yayınları, İngilizce konusunda eğitimli, birikimli ve kariyer sahibi olan, yaratıcı, çoğunluğu yabancı, oldukça zengin ve kalabalık bir kadro tarafından üretilmektedir.
              </p><p>
                Yayınlarımızda yer alan her sözcük, her kavram, her soru ya da cümle, kılı kırk yararcasına bir tartışma sürecinin ardından o yeri alabilmektedir. ELS’in kalite anlayışı, ELS’le sınava hazırlanan adayları ve öğretmenlerimizi, yanlış sorularla, yazım hatalarıyla uğraştırmamaktır. Yanlışsız, eksiksiz bir yayıncılık politikası temel ilkemizdir.
              </p><p>
                ELS yayınlarını önemli ve vazgeçilmez kılan, alanında meydana gelen değişmeleri, yenilikleri yakından izleyip, değişen durumdan kendisine görev çıkarmasıdır. Bu bağlamda ELS, değişimi izleyen yenilikçi bir yayıncılık yapmaktadır.
              </p><p>
                Abonelerimizin ve öğretmen arkadaşlarımızın ELS yayınları hakkında yorumlarını ve eleştirilerini bizimle paylaşmalarını, bu yenilikçi anlayış bakımından, çok önemli ve değerli buluyoruz.
              </p><p>
                ELS’in abone işlemleri, müşteri ilişkileri, yayınlarımızın basım ve dağıtım aşaması da yukarıda belirtmiş olduğumuz anlayışla ve titizlikle yürütülmektedir.
              </p><p>
                Yayınlarımızın üretim ve dağıtım sürecindeki tüm bu titizlenmelerin temelinde yatan amaç şudur:
              </p><p>
                Ürettiğimiz kaynaklarla hedefinize ulaşmanıza yardımcı olarak sizlerin memnuniyetini sağlamak ve bu memnuniyeti kalıcı kılmak...
              </p><p>
                <b>Saygıyla... Dostlukla...</b></p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

    );
  }
}

export default About;
