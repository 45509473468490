import React, {Component} from 'react';
import _xhr from '../../utils/_xhr';
import rest from '../../utils/rest.cfg';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormFeedback, FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText, Label,
  Row
} from 'reactstrap';
import swal from 'sweetalert';
import {Link} from 'react-router-dom';
import cookie from '../../utils/cookie';
import regex from "../../utils/regex";
const auth = rest('auth');
class Payment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      paymentData: {},
      paymentForm: '',
      form: {},
      script: null,
      oldHead: document.head,
      agreement: 0,
      package: {}
    }
    this.script = null;
    this.loadIyzico = this.loadIyzico.bind(this);
    this.inputChangeHandler = this.inputChangeHandler.bind(this);
    this.sendFormHandler = this.sendFormHandler.bind(this);
    this.agreementChangeHandler = this.agreementChangeHandler.bind(this);
  }

  componentWillMount () {
  }

  componentDidMount() {
    let self = this;
    _xhr.get({
      url: rest('rest') + 'package/' + self.props.match.params.id,
      onsuccess: function({ data }){
        self.setState({
          package: data
        })
      }
    })
  }

  componentWillUnmount() {
    document.getElementById('iyzipay-checkout-form').innerHTML = '';
    //document.head = this.state.oldHead;
    if(this.state.script) {
      console.log(this.state.script);
      document.body.removeChild(this.state.script);
      this.setState({
        script: null
      })
    }
  }

  sendFormHandler() {
    console.log(this.state.form);
    const body = this.state.form;
    body.packageId = parseInt(this.props.match.params.id);
    body.buyerCountry = 'Türkiye'
    if(this.validateFields(this.state.form)) {
      this.loadIyzico(body);
    } else {
      swal("Hata!",
        "Lütfen formdaki tüm alanların dolu olduğundan emin olun!",
        {
          icon: "error"
        })
    }
  }

  validateFields(form) {
    if(
      !form.packageId ||
      !form.buyerName ||
      !form.buyerSurname ||
      !form.buyerPhone ||
      !form.buyerEmail ||
      !form.buyerIdentityNumber ||
      !form.buyerAddress ||
      !form.buyerCity ||
      !form.buyerCountry
    ) {
      return false;
    }
    return true;
  }

  inputChangeHandler(e) {
    let value = e.target.value;
    if(e.target.id === 'phone') {
      let originalValue = e.target.value.replace(/\(/g, "").replace(/\)/g, "");
      if (regex('phone').test(originalValue) && originalValue.length <= 11) {
        if (originalValue.length >= 2) {
          originalValue = originalValue.slice(0, 1) + '(' + originalValue.slice(1);
        }
        if (originalValue.length >= 6) {
          originalValue = originalValue.slice(0, 5) + ')' + originalValue.slice(5);
        }
        value = originalValue;
      }else{
        return false;
      }
    }
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: value
      }
    })
  }

  agreementChangeHandler(e) {
    if(e.target.checked) {
      this.setState({
        agreement: 1
      })
    } else {
      this.setState({
        agreement: 0
      })
    }
  }

  loadIyzico(body) {
    let self = this;
    _xhr.post({
      url: rest('rest') + 'purchase/payment-form',
      params: body,
      onsuccess: function({ data }){
        //self.instance.appendChild(script);
        var scriptText = data.checkoutFormContent;
        scriptText = scriptText.replace('<script type="text/javascript">','');
        scriptText = scriptText.replace('</script>','');
        this.script = document.createElement('script');
        this.script.type = 'text/javascript';
        this.script.innerHTML = scriptText;
        //console.log(this.script);
        document.body.appendChild(this.script);
        window.location.href = data.paymentPageUrl;
        /*self.setState({
          paymentForm: data.checkoutFormContent,
          paymentData: data,
          script: this.script
        })*/
      }
    })
  }


  render() {
    return (
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <i className="icon-basket"></i><b>ÖDEME</b>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6" sm="12">
                  <div className="payment-form">
                    <h1>Fatura Bilgileri</h1>
                    <p className="text-muted">Lütfen aşağıdaki formu doldurunuz tüm alanlar zorunludur.</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend" className="w-30 d-block">
                        <InputGroupText>Adınız</InputGroupText>
                      </InputGroupAddon>
                      <Input value={this.state.form.buyerName} onChange={this.inputChangeHandler} name="buyerName" />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend" className="w-30 d-block">
                        <InputGroupText>Soyadınız</InputGroupText>
                      </InputGroupAddon>
                      <Input value={this.state.form.buyerSurname} onChange={this.inputChangeHandler} name="buyerSurname" />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend" className="w-30 d-block">
                        <InputGroupText>Telefon Numaranız</InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" placeholder="0(xxx)xxxxxxx" value={this.state.form.buyerPhone} id="phone" onChange={this.inputChangeHandler} name="buyerPhone" />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend" className="w-30 d-block">
                        <InputGroupText>E-Posta Adresiniz</InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="xxx@yyy.zzz" type="email" value={this.state.form.buyerEmail} onChange={this.inputChangeHandler} name="buyerEmail" />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend" className="w-30 d-block">
                        <InputGroupText>T.C. Kimlik No</InputGroupText>
                      </InputGroupAddon>
                      <Input type="number" value={this.state.form.buyerIdentityNumber} onChange={this.inputChangeHandler} name="buyerIdentityNumber" />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend" className="w-30 d-block">
                        <InputGroupText>Fatura Adresi</InputGroupText>
                      </InputGroupAddon>
                      <Input value={this.state.form.buyerAddress} onChange={this.inputChangeHandler} name="buyerAddress" />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend" className="w-30 d-block">
                        <InputGroupText>Şehir</InputGroupText>
                      </InputGroupAddon>
                      <Input type="select" value={this.state.form.buyerCity} onChange={this.inputChangeHandler} name="buyerCity" >
                        <option value="">Seçiniz</option>
                        <option value="İstanbul">İstanbul</option>
                        <option value="Ankara">Ankara</option>
                        <option value="İzmir">İzmir</option>
                        <option value="Adana">Adana</option>
                        <option value="Adıyaman">Adıyaman</option>
                        <option value="Afyonkarahisar">Afyonkarahisar</option>
                        <option value="Ağrı">Ağrı</option>
                        <option value="Aksaray">Aksaray</option>
                        <option value="Amasya">Amasya</option>
                        <option value="Antalya">Antalya</option>
                        <option value="Ardahan">Ardahan</option>
                        <option value="Artvin">Artvin</option>
                        <option value="Aydın">Aydın</option>
                        <option value="Balıkesir">Balıkesir</option>
                        <option value="Bartın">Bartın</option>
                        <option value="Batman">Batman</option>
                        <option value="Bayburt">Bayburt</option>
                        <option value="Bilecik">Bilecik</option>
                        <option value="Bingöl">Bingöl</option>
                        <option value="Bitlis">Bitlis</option>
                        <option value="Bolu">Bolu</option>
                        <option value="Burdur">Burdur</option>
                        <option value="Bursa">Bursa</option>
                        <option value="Çanakkale">Çanakkale</option>
                        <option value="Çankırı">Çankırı</option>
                        <option value="Çorum">Çorum</option>
                        <option value="Denizli">Denizli</option>
                        <option value="Diyarbakır">Diyarbakır</option>
                        <option value="Düzce">Düzce</option>
                        <option value="Edirne">Edirne</option>
                        <option value="Elazığ">Elazığ</option>
                        <option value="Erzincan">Erzincan</option>
                        <option value="Erzurum">Erzurum</option>
                        <option value="Eskişehir">Eskişehir</option>
                        <option value="Gaziantep">Gaziantep</option>
                        <option value="Giresun">Giresun</option>
                        <option value="Gümüşhane">Gümüşhane</option>
                        <option value="Hakkâri">Hakkâri</option>
                        <option value="Hatay">Hatay</option>
                        <option value="Iğdır">Iğdır</option>
                        <option value="Isparta">Isparta</option>
                        <option value="Kahramanmaraş">Kahramanmaraş</option>
                        <option value="Karabük">Karabük</option>
                        <option value="Karaman">Karaman</option>
                        <option value="Kars">Kars</option>
                        <option value="Kastamonu">Kastamonu</option>
                        <option value="Kayseri">Kayseri</option>
                        <option value="Kırıkkale">Kırıkkale</option>
                        <option value="Kırklareli">Kırklareli</option>
                        <option value="Kırşehir">Kırşehir</option>
                        <option value="Kilis">Kilis</option>
                        <option value="Kocaeli">Kocaeli</option>
                        <option value="Konya">Konya</option>
                        <option value="Kütahya">Kütahya</option>
                        <option value="Malatya">Malatya</option>
                        <option value="Manisa">Manisa</option>
                        <option value="Mardin">Mardin</option>
                        <option value="Mersin">Mersin</option>
                        <option value="Muğla">Muğla</option>
                        <option value="Muş">Muş</option>
                        <option value="Nevşehir">Nevşehir</option>
                        <option value="Niğde">Niğde</option>
                        <option value="Ordu">Ordu</option>
                        <option value="Osmaniye">Osmaniye</option>
                        <option value="Rize">Rize</option>
                        <option value="Sakarya">Sakarya</option>
                        <option value="Samsun">Samsun</option>
                        <option value="Siirt">Siirt</option>
                        <option value="Sinop">Sinop</option>
                        <option value="Sivas">Sivas</option>
                        <option value="Şırnak">Şırnak</option>
                        <option value="Tekirdağ">Tekirdağ</option>
                        <option value="Tokat">Tokat</option>
                        <option value="Trabzon">Trabzon</option>
                        <option value="Tunceli">Tunceli</option>
                        <option value="Şanlıurfa">Şanlıurfa</option>
                        <option value="Uşak">Uşak</option>
                        <option value="Van">Van</option>
                        <option value="Yalova">Yalova</option>
                        <option value="Yozgat">Yozgat</option>
                        <option value="Zonguldak">Zonguldak</option>
                      </Input>
                    </InputGroup>
                    <FormGroup check>
                      <Input
                        value={this.state.agreement}
                        id="agreement"
                        type="checkbox"
                        onChange={this.agreementChangeHandler}
                      />
                      {''}
                      <Label check>
                        <Link target="_blank"
                              to={`/mesafeli-satis-sozlesmesi?name=${this.state.form.buyerName} ${this.state.form.buyerSurname}&address=${this.state.form.buyerAddress}&phone=${this.state.form.buyerPhone}&email=${this.state.form.buyerEmail}&price=${this.state.package.price}`}>
                          Mesafeli satış sözleşmesini</Link> kabul ediyorum.
                      </Label>
                    </FormGroup>
                    <br />
                  </div>
                  <button onClick={this.sendFormHandler} disabled={!this.state.agreement} className='btn btn-primary'><b>ÖDEME</b></button>
                </Col>
                <Col md="6" sm="12">
                  <img className="package-cover-image" src={this.state.package.coverImage} />
                  <div className="package-details">
                    <h1 className="package-title">{this.state.package.name}</h1>
                    <p>{this.state.package.explanation}</p>
                    {
                      (this.state.package.price === this.state.package.reducedPrice || !this.state.package.reducedPrice) ? (
                        <div className="prices">
                          <p className="sale-price">{this.state.package.price} TL</p>
                        </div>
                      ) : (
                        <div className="prices">
                          <p className="sale-price">{this.state.package.reducedPrice} TL <span className="regular-price">{this.state.package.price} TL</span></p>
                        </div>
                      )
                    }
                    {
                      this.state.package.longDescription &&
                      <div className="package-long-description">
                        <h5><b>Paket Tanıtımı:</b></h5>
                        <div dangerouslySetInnerHTML={{__html:this.state.package.longDescription }} />
                      </div>
                    }
                    <div className="package-products">
                      {
                        this.state.package.product && <h5><b>Paketin içerdiği ürünler:</b></h5>
                      }
                      <Row>
                        {
                          this.state.package.product &&
                          this.state.package.product.map( product =>
                            <Col md="6" sm="12" key={product.id}>
                              <Card className="package-product">
                                <CardBody>
                                  <b>{product.name}</b>
                                  <p>{product.description}</p>
                                </CardBody>
                              </Card>
                            </Col>)
                        }
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>

            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Payment;
