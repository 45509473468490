import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Container, FormFeedback, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import _xhr from '../../utils/_xhr';
import swal from 'sweetalert';
import axios from 'axios';
import validator from '../../utils/validator';
import rest from '../../utils/rest.cfg';
import validationMessages from '../../utils/validationMessages';
const _resetPasswordUrl = rest('changepassword');
const auth = rest('auth');

class ChangePassword extends Component {
  constructor(props) {
      super(props);
      this.onSubmit = this.onSubmit.bind(this);

      this.state = {
          credentials: {
              newPassword: ""
          },
          passwordAgain: "",
          validations: {
              newPassword: true,
              passwordAgain: true
          }
      };
  }

  componentWillMount() {

  }

  componentDidMount() {

  }

  componentWillUpdate() {

  }

  componentDidUpdate() {

  }

  componentWillReceiveProps(newProps) {

  }

  onPasswordAgainChange(e){
    let credentials = this.state.credentials;
    let validationObj = this.state.validations;
    if(credentials["newPassword"] === e.target.value){
      validationObj["passwordAgain"] = true;
    }
    this.setState({
        validations: validationObj,
        passwordAgain: e.target.value
    })
  }

  onCommonChange(e){
      let obj = this.state.credentials;
      obj[e.target.name] = e.target.value;

      let validationObj = this.state.validations;
      validationObj[e.target.name] = validator.check("password", e.target.value);

      this.setState({
          credentials:obj,
          validations: validationObj
      })
  }
  getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(window.location.href);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };
  onSubmit(e) {
      e.preventDefault();
      let self = this;

      let accessTokenStr;
      if (this.props.location.search !== "" && this.props.location.search.indexOf("access_token") > -1) {
          accessTokenStr = auth.name + "=" + this.props.location.search.split("=")[1];
      }

      let credentials = self.state.credentials;

      let validationObj = self.state.validations;
      validationObj["newPassword"] = validator.check("password", credentials["newPassword"]);
      if(credentials["newPassword"] !== self.state.passwordAgain){
        validationObj["passwordAgain"] = false;
      }
      this.setState({
          validations: validationObj,
          isSubmit: true
      })

      if(validator.isObjValid(validationObj)){
          let code = this.getUrlParameter('id');
          let params = {
              code: code,
              password: credentials.newPassword
          }

          _xhr.post({
              url: _resetPasswordUrl,
              params: params,
              onsuccess: function ({ data }) {
                  swal("Parola değiştirildi.", {
                      icon: "success",
                      button: "Giriş"
                  })
                      .then((value) => {
                          self.props.history.push('/login');
                      });
              },
              onerror: function ({ err }) {
                  let message = validationMessages("technicalError");
                  try{
                      if(err.response.data.error.code === "INVALID_PASSWORD"){
                          message = validationMessages("invalidCurrentPass");
                      }
                  }catch(e){}

                  swal(message, {
                      icon: "error"
                  });
              }
          })
      }
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">
                <CardBody className="p-4">
                  <h1>Parola Değişiklik</h1>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-lock"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input className={!this.state.validations.newPassword?"is-invalid":""} value={this.state.credentials["newPassword"]} onChange={this.onCommonChange.bind(this)} id="newPassword" name="newPassword" type="password" placeholder="Yeni Parola" />
                    {   !this.state.validations.newPassword && this.state.isSubmit &&
                        <FormFeedback>Parola en az 8 karakterden oluşmalıdır.</FormFeedback>
                    }
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="icon-lock"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input className={!this.state.validations.passwordAgain?"is-invalid":""} value={this.state.passwordAgain} onChange={this.onPasswordAgainChange.bind(this)}  id="passwordAgain" type="password" name="passwordAgain" placeholder="Yeni Parola Tekrar" />
                    {   !this.state.validations.passwordAgain && this.state.isSubmit &&
                        <FormFeedback>Lütfen yeni parolayı tekrar girin.</FormFeedback>
                    }
                  </InputGroup>
                  <Button color="success" onClick={this.onSubmit} block>Parolayı Değiştir</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ChangePassword;
