/**
 * @AUTHOR Erkan Sert
 * @DATE 25.01.2019
 */
export const commonConstants = {
    dateFormat: "LLLL",
}

export default function (name) {
    return commonConstants[name]
};
