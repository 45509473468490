import Konva from "konva";
export const addLine = (stage, layer, mode = "brush") => {
    let isPaint = false;
    let lastLine;
    stage.on("mousedown touchstart", function(e) {
        isPaint = true;
        let pos = stage.getPointerPosition();
        lastLine = new Konva.Line({
            stroke: mode == "small" ? "red" : mode == "big" ? "red" : mode == "brush" ? "red" : "white",
            strokeWidth: mode == "small" ? 3 : mode == "brush" ? 5 : mode == "small" ? 15 : 20,
            globalCompositeOperation:
                mode === "small" ? "source-over" :  mode === "big" ? "source-over" : mode === "brush" ? "source-over" : "destination-out",
            points: [pos.x, pos.y],
            draggable: mode == "brush" || mode == "big" || mode == "small",
        });
        layer.add(lastLine);
    });
    stage.on("mouseup touchend", function() {
        isPaint = false;
    });
    stage.on("mousemove touchmove", function() {
        if (!isPaint) {
            return;
        }
        const pos = stage.getPointerPosition();
        let newPoints = lastLine.points().concat([pos.x, pos.y]);
        lastLine.points(newPoints);
        layer.batchDraw();
    });
};
