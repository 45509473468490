import React, { Component } from 'react';
import {
  Button,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Input,
  /*Pagination,
  PaginationItem,
  PaginationLink,*/
  Row,
  Table,
  UncontrolledTooltip,
  FormFeedback
} from 'reactstrap';

import swal from 'sweetalert';

import _xhr from '../../utils/_xhr';
import rest from '../../utils/rest.cfg';
import cookie from '../../utils/cookie';
import appConstants from '../../utils/appConstants';

const usersUrl = rest('users');
const _followingsUrl = rest('following');
const auth = rest('auth');

class AddFollow extends Component {
  constructor(props) {
      super(props);

      this.state = {
        users : [],
        email: "",
        followings : [],
        validations: {
          emailMinLength: true
        },
        isSearchSubmit: false  //use for validation 
      };
  }

  componentWillMount() {

  }

  componentDidMount() {
    this.getFollowingUsers()
  }

  getFollowingUsers(){
    let self = this;
    let followingUsersUrl = usersUrl + "/" + cookie.get(auth.user) + _followingsUrl;

    _xhr.get({
        url: followingUsersUrl,
        onsuccess: function ({ data }) {
          self.setState({
            followings:data
          })
        }
    });
  }

  componentWillUpdate() {

  }

  componentDidUpdate() {

  }

  componentWillReceiveProps(newProps) {

  }

  onEmailChange(e){

    let emailValue = e.target.value;
    let validationObj = this.state.validations;
    let isSearchSubmit = this.state.isSearchSubmit;

    if(emailValue.length < 3){ //min 3 character
      validationObj.emailMinLength = false;
    } else{
      isSearchSubmit = false;
    }

    this.setState({
      email: emailValue,
      validations: validationObj,
      isSearchSubmit: isSearchSubmit
    });
  }

  onCheckUser(){

    if(this.state.email.length < 3){ //min 3 character
      let validationObj = this.state.validations;
      validationObj.emailMinLength = false;

      this.setState({
        validations: validationObj,
        isSearchSubmit: true
      });
      
      return;
    }

    let stringQuery = {
        filter: { "where": { "email": { "like" : encodeURI("%" + this.state.email + "%") } },
        "limit": "100",                  
      }
    };

    let self = this;
    _xhr.get({
        url: usersUrl,
        stringData: stringQuery,
        onsuccess: function ({ data }) {
          if(data && data.length > 0){
            //filter followings user in search results
            let dataFromServer = data;
            let filteredUsers = dataFromServer.filter(function(user) {

              let followingUsers = self.state.followings;
              let followingsUserList = 
                followingUsers.filter(function(following) {
                  return following.userId === user.id;
                });
              
              if(followingsUserList.length > 0 ){
                return false;
              } else{
                return true;
              }
            });
  
            self.setState({
              users:filteredUsers
            })
          } else{
            self.setState({
              users:[]
            })
          }
        },
        onerror: function ({response}) {
          if(response.status === 401){
            swal("Arama yapmaya yetkiniz bulunmamaktadır. Yetki almak için lütfen bizimle iletişime geçin.", {
              icon: "error",
            });
          }
        }
    });
  }

  onAddFollowingUser(user){

    if(user){
      let self = this;

      swal("Onay",
            "\"" + user.name.toUpperCase() + " " + user.surname.toUpperCase() 
            + "\" kişisini takip listenize ekleyeceksiniz. Devam etmek istiyor musunuz?",
      {
        icon: "warning",
        buttons: {
          cancel: "İptal",
          confirm: "Onay",
        }
      })
      .then((submit) => {
          if(submit){
            let addFollowingUserUrl = usersUrl + "/" + cookie.get(auth.user) + _followingsUrl;

            let addFollowingUserDataObj = {
                "userId": user.id
            }

            _xhr.post({
                url: addFollowingUserUrl,
                params: addFollowingUserDataObj,
                onsuccess: function ({ response }) {

                  //remove added user from search results
                  let users = self.state.users.filter(function(userInList){
                    return userInList.id !== user.id;
                  });
                  
                  //add followings users to followings
                  let followings = self.state.followings;
                  followings.push({
                    userId: user.id
                  });

                  self.setState({
                    followings: followings,
                    users: users
                  });
                }
            });
          }
      });
    }
  }

  render() {
    return (
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <i className="icon-user-follow"></i> Takip Et
            </CardHeader>
            <CardBody>
            <Row>
              <Col md="6" className="mb-3">
                <FormGroup>
                  <Input type="text" id="name" value={this.state.email} name="email"
                      onChange={this.onEmailChange.bind(this)}
                      onKeyPress={event => {
                        if (event.key === "Enter") {
                          this.onCheckUser();
                        }
                      }}
                      placeholder="E-posta adresi" required 
                      className={!this.state.validations.emailMinLength && this.state.isSearchSubmit ? "is-invalid":""}
                    />
                    {   !this.state.validations.emailMinLength && this.state.isSearchSubmit &&
                        <FormFeedback>Arama yapmak için en az 3 karakter girin.</FormFeedback>
                    }
                </FormGroup>
              </Col>
              <Col md="1" className="mb-3">
                <Button block outline color="primary" className="float-right" onClick={this.onCheckUser.bind(this)}><i className="fa fa-search"></i></Button>
              </Col>
            </Row>
              <Table responsive striped>
                <thead>
                  <tr>
                    <th>Öğrenci</th>
                    <th className="text-center">E-posta Adresi</th>
                    <th className="text-center">Okul Adı</th>
                    <th className="text-center">Sınıf</th>
                    <th className="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                {
                  this.state.users.length === 0 &&
                  <tr className="text-center"><td colSpan="6" className="dataTables_empty">Görüntülenebilecek kayıt yoktur.</td></tr>
                }
                {
                  this.state.users.map((user,i) => {
                    return  <tr key={i}>
                              <td className="text-uppercase">{user.name} {user.surname}</td>
                              <td className="text-center">{user.email}</td>
                              <td className="text-center">{user.schoolName}</td>
                              <td className="text-center">{appConstants.getClassText(user.classroom)}</td>
                              <td>
                                <Button id={"btnUserFollow_" + i} outline color="primary" className="float-left"
                                  onClick={this.onAddFollowingUser.bind(this, user)}>
                                  <i className="icon-user-follow"></i>
                                  <UncontrolledTooltip placement="top" target={"btnUserFollow_" + i}>
                                    Takip Et
                                  </UncontrolledTooltip>
                                </Button>
                              </td>
                            </tr>
                  })
                }
                </tbody>
              </Table>
              {/*<Pagination>
                <PaginationItem disabled><PaginationLink previous tag="button">Prev</PaginationLink></PaginationItem>
                <PaginationItem active>
                  <PaginationLink tag="button">1</PaginationLink>
                </PaginationItem>
                <PaginationItem><PaginationLink tag="button">2</PaginationLink></PaginationItem>
                <PaginationItem><PaginationLink tag="button">3</PaginationLink></PaginationItem>
                <PaginationItem><PaginationLink tag="button">4</PaginationLink></PaginationItem>
                <PaginationItem><PaginationLink next tag="button">Next</PaginationLink></PaginationItem>
              </Pagination>*/}
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default AddFollow;
