import React, { Component } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardImg,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import _xhr from '../../utils/_xhr';
import rest from '../../utils/rest.cfg';

class Store extends Component {
    constructor(props) {

        super(props);

        this.state = {
            activeTab: '1',
            packages: []
        };

        moment.locale('tr');  //set date lang to 'tr'
    }

    componentDidMount() {
        this.getAllPackages();
    }

    getAllPackages() {
        let self = this;
        _xhr.get({
            url: rest('rest') + 'package/all',
            onsuccess: function({ data }){
                console.log(data);
                self.setState({
                    packages: data
                })
            }
        })
    }

    goToPackage(id) {

    }

    render() {
        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <i className="fa fa-shopping-cart"></i>
                            <b>MAĞAZA</b>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="12">
                                    <div style={{width: '100%', marginBottom: '20px'}}>

                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: this.state.activeTab === '1'})}
                                                    onClick={() => this.setState({activeTab: '1'})}
                                                >
                                                    <b>Paketler</b>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                  className={classnames({active: this.state.activeTab === '2'})}
                                                  onClick={() => this.setState({activeTab: '2'})}
                                                >
                                                    <b>Basılı Yayınlar</b>
                                                </NavLink>
                                            </NavItem>
                                            {/*<NavItem>
                                                <NavLink
                                                    className={classnames({active: this.state.activeTab === '2'})}
                                                    onClick={() => this.setState({activeTab: '2'})}
                                                >
                                                    <b>Kitaplar</b>
                                                </NavLink>

                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({active: this.state.activeTab === '3'})}
                                                    onClick={() => this.setState({activeTab: '3'})}
                                                >
                                                    <b>Sınavlar</b>
                                                </NavLink>
                                            </NavItem>*/}
                                        </Nav>
                                        <TabContent activeTab={this.state.activeTab} md="12">
                                            <TabPane tabId="1">
                                                <Row>
                                                    {
                                                        this.state.packages.length > 0 &&
                                                        this.state.packages.map((packageItem, index) => {
                                                            return <Col md="4" key={index}>
                                                                <Card className='full-height'>
                                                                    {
                                                                        packageItem.coverImage && (<CardImg top width="100%" src={packageItem.coverImage} alt="Card image cap" />)
                                                                    }
                                                                    <CardHeader className='d-flex'>
                                                                        <b>{packageItem.name}</b>
                                                                        <Button color='primary' className='ml-auto' onClick={() => { this.props.history.push('/package/' + packageItem.id) }}>İNCELE</Button>
                                                                    </CardHeader>
                                                                    <CardBody>
                                                                        <div>{packageItem.explanation}</div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        })
                                                    }
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <Row>
                                                    <Col md="4">
                                                        <Card className='full-height'>
                                                            <CardImg top width="100%" src="https://elsimages.s3.eu-central-1.amazonaws.com/grammar-kitap.jpg" alt="Card image cap" />
                                                            <CardHeader className='d-flex'>
                                                                <b>English Grammar Inside and Out</b>
                                                                <Button color='primary' className='ml-auto' onClick={() => { window.location.href = 'https://elsyayin.com.tr/Urun/english-grammar-inside-and-out-0.aspx' }}>İNCELE</Button>
                                                            </CardHeader>
                                                        </Card>
                                                    </Col>
                                                    <Col md="4">
                                                        <Card className='full-height'>
                                                            <CardImg top width="100%" src="https://elsimages.s3.eu-central-1.amazonaws.com/reading-kitap.jpg" alt="Card image cap" />
                                                            <CardHeader className='d-flex'>
                                                                <b>English Through Reading</b>
                                                                <Button color='primary' className='ml-auto' onClick={() => { window.location.href = 'https://elsyayin.com.tr/Urun/english-through-reading-0.aspx' }}>İNCELE</Button>
                                                            </CardHeader>
                                                        </Card>
                                                    </Col>
                                                    <Col md="4">
                                                        <Card className='full-height'>
                                                            <CardImg top width="100%" src="https://elsimages.s3.eu-central-1.amazonaws.com/vocabulary-kitap.jpg" alt="Card image cap" />
                                                            <CardHeader className='d-flex'>
                                                                <b>Test Your Vocabulary</b>
                                                                <Button color='primary' className='ml-auto' onClick={() => { window.location.href = 'https://elsyayin.com.tr/Urun/test-your-vocabulary-0.aspx' }}>İNCELE</Button>
                                                            </CardHeader>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="12">
                                                        <p>Diğer basılı yayınlarımızı görüntülemek için <a href="https://elsyayin.com.tr/#yayinlarimiz" target="_blank"><u>tıklayınız.</u></a></p>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="3">
                                                Sınavlar içeriği
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default Store;
