import React, {Component} from 'react';

class CheckoutForm extends Component {
  constructor() {
    super();
  }

  render() {
    return(
      <div>Test</div>
    )
  }
}

export default CheckoutForm;
