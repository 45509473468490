import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';

class Contact extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <i className="icon-phone"></i><b>İLETİŞİM</b>
            </CardHeader>
            <CardBody>
              <div className="page-content">
                <p>Mail adresimiz üzerinden bize ulaşabilirsiniz: <a href="mailto:els@elsyayin.com.tr">els@elsyayin.com.tr</a></p>
                <p><b>Adres:</b> Hasanpaşa Mah. Sarayardı Cad. No: 94/A Bakan Apt. KADIKÖY / İSTANBUL</p>
                <p><b>Telefon:</b> <a href="tel:02163491824">0216 349 18 24</a></p>
                <p><b>Telefon:</b> <a href="tel:02163450247">0216 345 02 47</a></p>
                <p><b>GSM:</b> <a href="tel:05058309797">0505 830 97 97</a></p>
                <p><b>Fax:</b> 0216 349 18 25</p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

    );
  }
}

export default Contact;
