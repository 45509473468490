/**
 * @AUTHOR Onur KESKİN
 * @DATE 01.01.2019
 */
const regexList = {
    mail: /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9-])+(.))+([a-zA-Z]{2,4})+$/i,
    phone: /^[0-9]*$/,
    numeric: /^[0-9]*$/,
    alphaNumeric: /^[a-zA-Z0-9]*$/,
    alpha: /^[a-zA-Z]*$/,
    alphaTurkishPlusSpace: /^[a-zA-Z\u00E7\u011F\u0131\u015F\u00F6\u00FC\u00C7\u011E\u0130\u015E\u00D6\u00DC\u0020]*$/,
    alphaNumericTurkishPlusSpaceDot: /^[a-zA-Z0-9\u00E7\u011F\u0131\u015F\u00F6\u00FC\u00C7\u011E\u0130\u015E\u00D6\u00DC\u0020\u002E]*$/,
    date: /^\d{4}(-)(((0)[0-9])|((1)[0-2]))(-)([0-2][0-9]|(3)[0-1])$/
}

export default function (regexName) {
    return regexList[regexName];
};
