import {
  Login,
  ResetPassword,
  ChangePassword,
  Register,
  RenewPassword,
  Home,
  Exams,
  Follow,
  AddFollow,
  TakeExam,
  Results,
  ExamResults,
  ExamDetail,
  Statistics,
  Store,
  About,
  PackageDetails, Contact, LegalWarnings, MesafeliSatis, TeslimatIade, Payment, ThankYou, CheckoutForm, PaymentFailed
} from './views';
import MainLayout from './components/Layout/Dashboard';
import Profile from "./views/Profile/Profile";
import Publications from "./views/Publications";
import PublicationDetail from "./views/Publications/PublicationDetail";

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Portal', component: MainLayout },
  { path: '/login', exact: true, name: 'Giriş', component: Login },
  { path: '/resetpassword', exact: true, name: 'Parola Sıfırlama', component: ResetPassword },
  { path: '/changepassword', exact: true, name: 'Parola Değişiklik', component: ChangePassword },
  { path: '/renewpassword', exact: true, name: 'Parola Değişiklik', component: RenewPassword },
  { path: '/register', exact: true, name: 'Kayıt Ol', component: Register },
  { path: '/home', exact: true, name: 'Anasayfa', component: Home },
  { path: '/exams', exact: true, name: 'Sınavlar', component: Exams },
  { path: '/exams-yds', exact: true, name: 'Sınavlar', component: Exams },
  { path: '/exams/:id', exact: true, name: 'Sınav', component: TakeExam },
  { path: '/follow', exact: true, name: 'Takip Listesi', component: Follow },
  { path: '/follow/addfollow', exact: true, name: 'Takip Et', component: AddFollow },
  { path: '/results', exact: true, name: 'Sonuçlar', component: Results },
  { path: '/results/:examId', exact: true, name: 'Sınav Sonucu', component: ExamResults },
  { path: '/results/examdetail/:id', exact: true, name: 'Sınav Detayı', component: ExamDetail },
  { path: '/statistics', exact: true, name: 'İstatistikler', component: Statistics },
  { path: '/profile', exact: true, name: 'Profil', component: Profile },
  { path: '/store', exact: true, name: 'Mağaza', component: Store },
  { path: '/package/:id', exact: true, name: 'Paket', component: PackageDetails},
  { path: '/publications', exact: true, name: 'Yayınlar', component: Publications },
  { path: '/publications/:id', exact: true, name: 'Yayın', component: PublicationDetail },
  { path: '/hakkimizda', exact: true, name: 'Hakkımızda', component: About},
  { path: '/iletisim', exact: true, name: 'İletişim', component: Contact},
  { path: '/yasal-uyari', exact: true, name: 'Yasal Uyarı', component: LegalWarnings},
  { path: '/mesafeli-satis-sozlesmesi', exact: true, name: 'Mesafeli Satış Sözleşmesi', component: MesafeliSatis},
  { path: '/teslimat-ve-iade', exact: true, name: 'Teslimat ve İade', component: TeslimatIade},
  { path: '/payment/:id', exact: true, name: 'Ödeme', component: Payment},
  { path: '/thank-you', exact: true, name: 'Teşekkürler', component: ThankYou},
  { path: '/payment-failed', exact: true, name: 'Hata', component: PaymentFailed}
];

export default routes;
