/**
 * @AUTHOR Onur KESKİN
 * @DATE 01.01.2019
 */
let requiredFunc = function(value, state) {
  if (!value.toString().trim().length) {
    return false;
  }
  return true;
}

let minLengthFunc = function(value, state, min) {
    if (value.toString().trim().length < min) {
      return false;
    }
    return true;
}

let maxLengthFunc = function(value, state, max) {
  if (value.toString().trim().length > max) {
    return false;
  }
  return true;
}

let minMaxLengthFunc = function(value, state, min, max) {
  if (value.toString().trim().length > max || value.toString().trim().length < min) {
    return false;
  }
  return true;
}

let regexCheckFunc = function(value, state, regex) {
    return regex.test(value);
}

let checkSameFunc = function(value, state, item, item2) {
    return state.inputs[item].value === state.inputs[item2].value;
}

const routineList = {
    required: requiredFunc,
    minLength: minLengthFunc,
    maxLength: maxLengthFunc,
    minMaxLength: minMaxLengthFunc,
    regexCheck: regexCheckFunc,
    checkSame: checkSameFunc
}

export default function (key, value, state, ...args) {
    return routineList[key](value, state, ...args);

};
