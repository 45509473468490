import React, {Component, useRef, useState} from 'react';
import moment from "moment";
import {Button, ButtonGroup, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import _xhr from "../../utils/_xhr";
import rest from "../../utils/rest.cfg";
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
import { Stage, Layer, Rect, Text } from 'react-konva';
import Konva from 'konva';
import {addLine} from "../../konva/line";
import {addHighLight} from "../../konva/highlight";
/*class ColoredRect extends React.Component {
    state = {
        color: 'green'
    };
    handleClick = () => {
        this.setState({
            color: Konva.Util.getRandomColor()
        });
    };
    render() {
        return (
            <Rect
                x={20}
                y={20}
                width={50}
                height={50}
                fill={this.state.color}
                shadowBlur={5}
                onClick={this.handleClick}
            />
        );
    }
}*/
const stageEl = React.createRef();
const layerEl = React.createRef();

const drawLine = (size) => {
    console.log(size);
    addHighLight(stageEl.current.getStage(), layerEl.current, "deselect");
    addLine(stageEl.current.getStage(), layerEl.current, size);
};
const eraseLine = () => {
    addHighLight(stageEl.current.getStage(), layerEl.current, "deselect");
    addLine(stageEl.current.getStage(), layerEl.current, "erase");
};
const drawHighLight = (color) => {
    addLine(stageEl.current.getStage(), layerEl.current, "deselect");
    addHighLight(stageEl.current.getStage(), layerEl.current, color);
}
const clearLayer = () => {

    layerEl.current.destroyChildren();
    stageEl.current.clear();
}
/*const sizer = () => {
    const [size, setSize] = React.useState({ width: window.offsetWidth, height: window.offsetHeight });
    React.useEffect(() => {
        const checkSize = () => {
            setSize({
                width: container.offsetWidth,
                height: container.offsetHeight,
            });
        };

        window.addEventListener('resize', checkSize);
        return () => window.removeEventListener('resize', checkSize);

    }, []);
}*/
class PublicationDetail extends Component {

    constructor(props) {
        super(props);
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
        this.state = {
            publication: {},
            pdfBase64: '',
            numPages: 0,
            pageNumber: 1,
            bytes: null,
            renderSucces: false,
            canvasWidth: window.innerWidth,
            canvasHeight: window.innerHeight
        };
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        moment.locale('tr');  //set date lang to 'tr'
    }

    componentDidMount() {
        let self = this;
        _xhr.get({
            url: rest('rest') + 'publication/' + self.props.match.params.id,
            onsuccess: function({ data }){
                console.log(data);
                self.setState({
                    publication: data
                })
            }
        })
        _xhr.get({
            url: rest('rest') + 'publication/pdf/' + self.props.match.params.id,
            onsuccess: function({ data }){
                self.setState({
                    pdfBase64: 'data:application/pdf;base64,' + data
                })
            }
        })
    }

    onDocumentLoadSuccess({ numPages }) {
        if(this) {
            this.setState(
                {
                    numPages: numPages
                }
            )
        }
        setTimeout(function(){
            const container = document.querySelector('.react-pdf__Document');
            this.setState(
                {
                    canvasWidth: container.offsetWidth,
                    canvasHeight: container.offsetHeight
                }

            )
        }.bind(this), 3000);
    }

    changeThePage(event) {
        if(event.target.value > 0) {
            layerEl.current.destroyChildren();
            stageEl.current.clear();
            this.setState({
                pageNumber: parseInt(event.target.value)
            })
        }
    }

    render() {
        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <i className="fa fa-book"></i>
                            <b>{this.state.publication.name}</b>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="12">
                                    <div>

                                        {this.state.pdfBase64 ? (
                                            <div className={'pdf-container'}>
                                                <ButtonGroup className={'pdf-toolbar'}>
                                                    <Button variant="secondary" onClick={() => drawLine('small')}>
                                                        <span className={'small-dot'}/>
                                                    </Button>
                                                    <Button variant="secondary" onClick={() => drawLine('brush')}>
                                                        <span className={'medium-dot'}/>
                                                    </Button>
                                                    <Button variant="secondary" onClick={() => drawLine('big')}>
                                                        <span className={'big-dot'}/>
                                                    </Button>
                                                    <Button variant="secondary" onClick={() => drawHighLight('yellow')} className={'highlight-yellow'}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                                                             focusable="false" data-prefix="fas" data-icon="highlighter"
                                                             className="svg-inline--fa fa-highlighter fa-w-17"
                                                             role="img" viewBox="0 0 544 512">
                                                            <path fill="currentColor"
                                                                  d="M0 479.98L99.92 512l35.45-35.45-67.04-67.04L0 479.98zm124.61-240.01a36.592 36.592 0 0 0-10.79 38.1l13.05 42.83-50.93 50.94 96.23 96.23 50.86-50.86 42.74 13.08c13.73 4.2 28.65-.01 38.15-10.78l35.55-41.64-173.34-173.34-41.52 35.44zm403.31-160.7l-63.2-63.2c-20.49-20.49-53.38-21.52-75.12-2.35L190.55 183.68l169.77 169.78L530.27 154.4c19.18-21.74 18.15-54.63-2.35-75.13z"/>
                                                        </svg>
                                                    </Button>
                                                    <Button variant="secondary" onClick={() => drawHighLight('pink')} className={'highlight-pink'}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                                                             focusable="false" data-prefix="fas" data-icon="highlighter"
                                                             className="svg-inline--fa fa-highlighter fa-w-17"
                                                             role="img" viewBox="0 0 544 512">
                                                            <path fill="currentColor"
                                                                  d="M0 479.98L99.92 512l35.45-35.45-67.04-67.04L0 479.98zm124.61-240.01a36.592 36.592 0 0 0-10.79 38.1l13.05 42.83-50.93 50.94 96.23 96.23 50.86-50.86 42.74 13.08c13.73 4.2 28.65-.01 38.15-10.78l35.55-41.64-173.34-173.34-41.52 35.44zm403.31-160.7l-63.2-63.2c-20.49-20.49-53.38-21.52-75.12-2.35L190.55 183.68l169.77 169.78L530.27 154.4c19.18-21.74 18.15-54.63-2.35-75.13z"/>
                                                        </svg>
                                                    </Button>
                                                    <Button variant="secondary" onClick={() => drawHighLight('green')} className={'highlight-green'}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                                                             focusable="false" data-prefix="fas" data-icon="highlighter"
                                                             className="svg-inline--fa fa-highlighter fa-w-17"
                                                             role="img" viewBox="0 0 544 512">
                                                            <path fill="currentColor"
                                                                  d="M0 479.98L99.92 512l35.45-35.45-67.04-67.04L0 479.98zm124.61-240.01a36.592 36.592 0 0 0-10.79 38.1l13.05 42.83-50.93 50.94 96.23 96.23 50.86-50.86 42.74 13.08c13.73 4.2 28.65-.01 38.15-10.78l35.55-41.64-173.34-173.34-41.52 35.44zm403.31-160.7l-63.2-63.2c-20.49-20.49-53.38-21.52-75.12-2.35L190.55 183.68l169.77 169.78L530.27 154.4c19.18-21.74 18.15-54.63-2.35-75.13z"/>
                                                        </svg>
                                                    </Button>
                                                    <Button variant="secondary" onClick={eraseLine} className={'erase-icon'}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                                                             focusable="false" data-prefix="fas" data-icon="eraser"
                                                             className="svg-inline--fa fa-eraser fa-w-16" role="img"
                                                             viewBox="0 0 512 512">
                                                            <path fill="currentColor"
                                                                  d="M497.941 273.941c18.745-18.745 18.745-49.137 0-67.882l-160-160c-18.745-18.745-49.136-18.746-67.883 0l-256 256c-18.745 18.745-18.745 49.137 0 67.882l96 96A48.004 48.004 0 0 0 144 480h356c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H355.883l142.058-142.059zm-302.627-62.627l137.373 137.373L265.373 416H150.628l-80-80 124.686-124.686z"/>
                                                        </svg>
                                                    </Button>
                                                    <Button variant="secondary" onClick={clearLayer}>
                                                        Reset
                                                    </Button>
                                                </ButtonGroup>
                                                <Stage width={this.state.canvasWidth} height={this.state.canvasHeight} ref={stageEl}>
                                                    <Layer ref={layerEl}>
                                                        {/*<Text text="Try click on rect" />
                                                        <ColoredRect />*/}
                                                    </Layer>
                                                </Stage>
                                                <Document
                                                    file={this.state.pdfBase64}
                                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                                    onContextMenu={(e) => e.preventDefault()}
                                                >
                                                    <Page wrap pageNumber={this.state.pageNumber}>
                                                    </Page>
                                                </Document>
                                                <div className={'pdf-pagination'}>Sayfa <input type="number" value={this.state.pageNumber} onChange={this.changeThePage.bind(this)} /> - {this.state.numPages}</div>
                                            </div>
                                                ) : (
                                            <div>Loading</div>
                                        )}

                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default PublicationDetail;
