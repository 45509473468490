import React from 'react';
import {Badge, Col, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink} from 'reactstrap';
import PropTypes from 'prop-types';

import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import rest from '../../../utils/rest.cfg';
import cookie from '../../../utils/cookie';
import _xhr from "../../../utils/_xhr";

const logoutUrl = rest('logout');
const auth = rest('auth');
const checkdetails = rest('checkdetails');

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class FullHeader extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };
  constructor(props) {
    super(props);
    this.state = {
      availableDeneme: 0,
      availableKonu: 0
    };

  }

  logOut() {
    axios.post(logoutUrl)
        .then(function (response) {
            cookie.delete(auth.name);
            cookie.delete(auth.user);
            cookie.delete(checkdetails.emailverify);

            window.location.href = "/";
        }).catch(error => {
            cookie.delete(auth.name);
            cookie.delete(auth.user);
            cookie.delete(checkdetails.emailverify);

            window.location.href = "/";
        });
  }

  componentWillMount() {

  }



  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
      const { history } = this.props;
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: process.env.PUBLIC_URL + 'assets/img/elslogo.png', width: 90, height: 30, alt: 'ELS Logo' }}
          minimized={{ src: process.env.PUBLIC_URL + 'assets/img/elslogo.png', width: 30, height: 30, alt: 'ELS Logo' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="ml-auto" navbar>

          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <Col md="12">
                {attributes.user.name}
                &nbsp;&nbsp;
                <i className="fa fa-user fa-lg"></i>
              </Col>
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
                <DropdownItem onClick={() => history.push("/profile")}><i className="fa fa-user"></i> Hesabım</DropdownItem>
              <DropdownItem onClick={() => history.push("/renewpassword")}><i className="fa fa-key"></i> Parola Değişiklik</DropdownItem>
              <DropdownItem onClick={this.logOut.bind(this)}><i className="fa fa-sign-out"></i> Çıkış</DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

FullHeader.propTypes = propTypes;
FullHeader.defaultProps = defaultProps;

export default withRouter(FullHeader);
