import React, { Component } from 'react';
import {Button, Card, CardBody, CardHeader, Col, Row} from 'reactstrap';
import _xhr from "../../utils/_xhr";
import rest from "../../utils/rest.cfg";

class PackageDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      package: {}
    }

  }

  componentDidMount() {
    let self = this;
    _xhr.get({
      url: rest('rest') + 'package/' + self.props.match.params.id,
      onsuccess: function({ data }){
        console.log(data);
        self.setState({
          package: data
        })
      }
    })
  }

  render() {
    return (
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <i className="icon-basket"></i><b>PAKET DETAYI</b>
            </CardHeader>
            <CardBody>
              <div className="package-content">
                <Row>
                  {
                    this.state.package.coverImage && (
                      <Col md="6" sm="12">
                        <img className="package-cover-image" src={this.state.package.coverImage} />
                      </Col>
                    )
                  }
                  <Col md="6" sm="12">
                    <div className="package-details">
                      <h1 className="package-title">{this.state.package.name}</h1>
                      <p>{this.state.package.explanation}</p>
                      {
                        (this.state.package.price === this.state.package.reducedPrice || !this.state.package.reducedPrice) ? (
                          <div className="prices">
                            <p className="sale-price">{this.state.package.price} TL</p>
                          </div>
                        ) : (
                          <div className="prices">
                            <p className="sale-price">{this.state.package.reducedPrice} TL <span className="regular-price">{this.state.package.price} TL</span></p>
                          </div>
                        )
                      }
                      <Button onClick={() => { this.props.history.push('/payment/' + this.state.package.id ) }} size={'lg'} color={'primary'}><b>SATIN AL</b></Button>
                      {
                        this.state.package.longDescription &&
                          <div className="package-long-description">
                            <h5><b>Paket Tanıtımı:</b></h5>
                            <div dangerouslySetInnerHTML={{__html:this.state.package.longDescription }} />
                          </div>
                      }
                      <div className="package-products">
                        {
                          this.state.package.product && <h5><b>Paketin içerdiği ürünler:</b></h5>
                        }
                        <Row>
                          {
                            this.state.package.product &&
                            this.state.package.product.map( product =>
                              <Col md="6" sm="12" key={product.id}>
                                <Card className="package-product">
                                  <CardBody>
                                    <b>{product.name}</b>
                                    <p>{product.description}</p>
                                  </CardBody>
                                </Card>
                              </Col>)
                          }
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }

}

export default PackageDetails;
