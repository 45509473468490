import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import {
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../../_nav';
// routes config
import routes from '../../../routes';

import FullHeader from './FullHeader';
import FullFooter from './FullFooter';

import swal from 'sweetalert';

import _xhr from '../../../utils/_xhr';
import rest from '../../../utils/rest.cfg';
import cookie from '../../../utils/cookie';

const usersUrl = rest('users');
const _emailVerifyUrl = rest('emailverify');
const _verifyUrl = rest('verify');
const auth = rest('auth');
const checkdetails = rest('checkdetails');

class MainLayout extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
						user: {
							email: "E-mail",
							name: "Name",
							surname: "Surname"
						},
            userType: 0,
            quizzes: {},
            navigationList: navigation,
        };
    }

  componentWillMount() {
  	if(cookie.get(auth.token)) {

      let nav = {
        items: navigation.items.slice(0)
      };
      let navigationItems = nav.items.filter(function(navItem){
        return !navItem.allowedRoles;
      });
      nav.items = navigationItems;

      this.setState({
          isLoggedIn: true,
          navigationList: nav
      })
		}
  }

	componentDidMount() {
    let self = this;
    let cookie2 = cookie;
    if(this.state.isLoggedIn) {
      _xhr.get({
          url: usersUrl + "/" + cookie.get(auth.user),
          onsuccess: function ({ data }) {
            let nav = {
              items: navigation.items.slice(0)
            };
            let navigationItems = nav.items.filter(function(navItem){
              return navItem.allowedRoles ? navItem.allowedRoles.filter(function(role) {
                return role === data.classroom;
              }).length : true;
            });
            if(data.type === 0) {
                navigationItems = navigationItems.filter(e => e.role !== 2);
            }
            nav.items = navigationItems;
            self.setState({
              user:data,
              navigationList: nav
            })

            if(data.emailVerified){
              cookie2.set(checkdetails.emailverify, "false"); // no end time limit

            } else if( !data.emailVerified && 
              (cookie2.get(checkdetails.emailverify) === null 
                || cookie2.get(checkdetails.emailverify) === undefined 
                || cookie2.get(checkdetails.emailverify)==="true")
              ){

              cookie2.set(checkdetails.emailverify, "false", 24*60*60*1000); // one day

              swal("E-posta Doğrulama",
                    "Kayıt işleminizin tamamlanması için e-posta doğrulama işlemini tamamlamanız gerekmektedir. \n" 
                    +"E-posta adresinizi şimdi doğrulamak ister misiniz?",
              {
                icon: "warning",
                buttons: {
                  cancel: "Daha Sonra Hatırlat",
                  confirm: "Evet",
                }
              })
              .then(
                (submit) => {
                  if(submit){
                    _xhr.get({
                        url: _verifyUrl,
                        onsuccess: function({response}) {
                            swal("Lütfen e-posta adresinize gönderilen doğrulama linkine tıklayın. E-postanın iletilmemesi durumunda lütfen spam kutusunu kontrol edin.",
                                {
                                    icon: "success",
                                    button: "Devam"
                                });
                        },
                        onerror: function ({response}) {
                            swal("Doğrulama için e-posta gönderilmesi sırasında bir hata oluştu. Daha sonra tekrar hatırlatma yapılacaktır.",
                                {
                                    icon: "error",
                                    button: "Devam"
                                });
                        }
                    })
                  }
              });
            }

          },
          onerror: function ({ response }) {
              cookie.delete(auth.name);
              cookie.delete(auth.user);
              cookie.delete(checkdetails.emailverify);
  						self.props.history.push('/login');
          }
      });
    } else {
      self.props.history.push('/login');
		}
  }
	render() {
  			return (
  				<div className="app">
  						<AppHeader fixed>
  							<FullHeader user={this.state.user}/>
  						</AppHeader>
  						<div className="app-body">
  							<AppSidebar fixed display="lg">
  								<AppSidebarHeader />
  								<AppSidebarForm />
                  <AppSidebarNav navConfig={this.state.navigationList} {...this.props} />
  								<AppSidebarFooter />
  								<AppSidebarMinimizer />
  							</AppSidebar>
  							<main className="main">
                  {/*<AppBreadcrumb appRoutes={routes}/>*/}
                  <Container fluid>
                    <Switch>
                      {routes.map((route, idx) => {
                          return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                              <route.component
                                {...props}
                              />
                            )} />)
                            : (null);
                        },
                      )}
                      <Redirect from="/" to="/home" />
                    </Switch>
                  </Container>
                </main>
  						</div>
  						<AppFooter>
                  <FullFooter />
  						</AppFooter>
  				</div>
  			);
  }
}

export default MainLayout;
