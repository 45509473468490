import React, { Component } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row, Table
} from 'reactstrap';

import { Pie, Bar } from 'react-chartjs-2';
import _xhr from "../../utils/_xhr";
import rest from "../../utils/rest.cfg";
import {Link} from "react-router-dom";
const _totalStatisticsUrl = rest('totalStatistics');
const _categoryStatisticsUrl = rest('categoryStatistics');
const _subjectStatisticsUrl = rest('subjectStatistics');
const _quizResults = rest('quizattempts');
const data = {
  labels: ["October", "November", "December"],
  datasets: [
    {
      data: [8137119, 9431691, 10266674],
      label: "Infected People",
      borderColor: "#3333ff",
      backgroundColor: "rgba(0, 0, 255, 0.5)",
      fill: true
    }
  ]
}
class Statistics extends Component {
  constructor(props) {
      super(props);
      this.state = {
        totalStatistics: null,
        categoryStatistics: null,
        subjectStatistics: null,
        errorMessage: null,
        quizResults: null,
        totalPie: null,
        quizBar: null,
        vocabularySubjects: null,
        grammarSubjects: null,
        readingSubjects: null,
        worstCategory: null,
        secondWorstCategory: null,
        worstCategoryPie: null,
        secondWorstCategoryPie: null,
        worstVocabulary: null,
        worstVocabularyPie: null,
        vocabulary: null,
        worstGammar: null,
        worstGrammarPie: null,
        grammar: null
      };
  }

  componentWillMount() {
  }

  secondsToHour(seconds) {
    return parseInt(parseInt(seconds) / ( 60 * 60 ));
  }

  secondsToMinute(seconds) {
    return parseInt(parseInt(seconds) / 60) % 60;
  }

  secondsToSeconds(seconds) {
    return parseInt(seconds) % 60;
  }

  getTotalStatistics() {
    let self = this;
    _xhr.get({
      url: _totalStatisticsUrl,
      onsuccess: function({data}) {
        const denemeCount = data.quizCategories.find(item => item.categoryName === 'Deneme Sinavi' || item.categoryName === 'Deneme Sınavı').count;
        data.denemeCount = denemeCount;
        data.konuCount = data.quizCount - denemeCount;
        data.totalTimeHour = self.secondsToHour(data.totalTime);
        data.totalTimeMinutes = self.secondsToMinute(data.totalTime);
        data.totalTimeSeconds = self.secondsToSeconds(data.totalTime);
        data.timePerQuestionHour = self.secondsToHour(data.timePerQuestion);
        data.timePerQuestionMinutes = self.secondsToMinute(data.timePerQuestion);
        data.timePerQuestionSeconds = self.secondsToSeconds(data.timePerQuestion);
        self.setState({
          totalStatistics: data,
          errorMessage: null,
          totalPie: {
            labels: ['Yanlış', 'Boş', 'Doğru'],
            datasets: [
              {
                label: '# of Answers',
                data: [data.falseCount, data.emptyCount, data.correctCount],
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(75, 192, 192, 1)',
                ],
                borderWidth: 1,
              },
            ],
          }
        });
      },
      onerror: function(err) {
        self.setState({
          errorMessage: 'İstatistikleriniz oluşması için en az bir test çözmüş olmalısınız!'
        });
      }
    })
  }



  getCategoryStatistics() {
    let self = this;
    let count = 0;
    _xhr.get({
      url: _categoryStatisticsUrl,
      onsuccess: function({data}) {
        self.setState({
          categoryStatistics: data
        })
        for(let i = data.length - 1; i > -1; i--) {
          if(count < 2 && data[i].categoryId !== 20) {
            if(count === 0) {
              self.setState({
                worstCategory: data[i],
                worstCategoryPie: {
                  labels: ['Yanlış', 'Boş', 'Doğru'],
                  datasets: [
                    {
                      label: '# of Answers',
                      data: [data[i].falseCount, data[i].emptyCount, data[i].correctCount],
                      backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                      ],
                      borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(75, 192, 192, 1)',
                      ],
                      borderWidth: 1,
                    },
                  ],
                }
              })
            } else {
              self.setState({
                secondWorstCategory: data[i],
                secondWorstCategoryPie: {
                  labels: ['Yanlış', 'Boş', 'Doğru'],
                  datasets: [
                    {
                      label: '# of Answers',
                      data: [data[i].falseCount, data[i].emptyCount, data[i].correctCount],
                      backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                      ],
                      borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(75, 192, 192, 1)',
                      ],
                      borderWidth: 1,
                    },
                  ],
                }
              })
            }
          }
        }
      },
      onerror: function(err) {
      }
    })
  }

  getSubjectStatisticsById(id) {
    let self = this;
    _xhr.get({
      url: _subjectStatisticsUrl + '?subjectId=' + id,
      onsuccess: function({data}) {
        if(id === 81) {
          self.setState({
            vocabularySubjects: data
          })
          for(let i = 0; i < data.length; i++) {
            data[i].net = parseFloat(data[i].correctCount) - parseFloat(parseInt(data[i].falseCount) / 3)
          }
          self.setState({
            worstVocabulary: data[data.length - 1]
          });
          self.setState({
            worstVocabularyPie: {
              labels: ['Yanlış', 'Boş', 'Doğru'],
              datasets: [
                {
                  label: '# of Answers',
                  data: [data[data.length - 1].falseCount, data[data.length - 1].emptyCount, data[data.length - 1].correctCount],
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                  ],
                  borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(75, 192, 192, 1)',
                  ],
                  borderWidth: 1,
                },
              ],
            }
          })
        }
        if(id === 67) {
          self.setState({
            grammarSubjects: data
          })
          for(let i = 0; i < data.length; i++) {
            data[i].net = parseFloat(data[i].correctCount) - parseFloat(parseInt(data[i].falseCount) / 3)
          }
          self.setState({
            worstGrammar: data[data.length - 1]
          });
          self.setState({
            worstGrammarPie: {
              labels: ['Yanlış', 'Boş', 'Doğru'],
              datasets: [
                {
                  label: '# of Answers',
                  data: [data[data.length - 1].falseCount, data[data.length - 1].emptyCount, data[data.length - 1].correctCount],
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                  ],
                  borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(75, 192, 192, 1)',
                  ],
                  borderWidth: 1,
                },
              ],
            }
          })
        }
      },
      onerror: function(err) {
      }
    })
  }

  getSubjectStatistics() {
    let self = this;
    _xhr.get({
      url: _subjectStatisticsUrl,
      onsuccess: function({data}) {
        data.forEach(item => {
          if(item.subjectId === 81) {
            self.setState({
              vocabulary: item
            })
          }
          if(item.subjectId === 67) {
            self.setState({
              grammar: item
            })
          }
          self.getSubjectStatisticsById(item.subjectId);
        });
        self.setState({
          subjectStatistics: data
        })
      },
      onerror: function(err) {
      }
    })
  }



  getQuizResults() {
    let self = this;
    _xhr.get({
      url: _quizResults,
      onsuccess: function({data}) {
        const quizBar = {
          labels: [],
          datasets: [
            {
              data: [],
              label: "Net",
              borderColor: "#3333ff",
              backgroundColor: "rgba(0, 0, 255, 0.5)",
              fill: true
            }
          ]
        }
        for(let quiz of data) {
          quizBar.datasets[0].data.push(quiz.score);
          quizBar.labels.push(quiz.quiz.name);
        }
        self.setState({
          quizResults: data,
          quizBar
        })
      },
      onerror: function(err) {

      }
    })
  }

  componentDidMount() {
    this.getTotalStatistics();
    this.getCategoryStatistics();
    this.getSubjectStatistics();
    this.getQuizResults();
  }

  componentWillUpdate() {

  }

  componentDidUpdate() {

  }

  componentWillReceiveProps(newProps) {

  }

  render() {
    return (
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <i className="fa fa-hourglass-3"></i>
                <b>İSTATİSTİKLER</b>
              </CardHeader>
              {
                this.state.errorMessage &&
                <CardBody>
                  <p>{this.state.errorMessage}</p>
                </CardBody>
              }
              {
                this.state.totalStatistics &&
                <CardBody>
                  <Row>
                    <Col md="4">
                      <div className="statistics-info">
                        <div className="statistics-item">
                          <p><span>Toplam Sınav</span>: <b>{this.state.totalStatistics.quizCount}</b></p>
                          <p><span>Deneme Sınavı</span>: <b>{this.state.totalStatistics.denemeCount}</b></p>
                          <p><span>Konu Testi</span>: <b>{this.state.totalStatistics.konuCount}</b></p>
                        </div>
                        <div className="statistics-item">
                          <p><span>Toplam Soru</span>: <b>{this.state.totalStatistics.totalQuestion}</b></p>
                          <p><span>Doğru</span>: <b>{this.state.totalStatistics.correctCount} (%{parseFloat(this.state.totalStatistics.correctPercent).toFixed(2)})</b></p>
                          <p><span>Yanlış</span>: <b>{this.state.totalStatistics.falseCount} (%{parseFloat(this.state.totalStatistics.falsePercent).toFixed(2)})</b></p>
                          <p><span>Boş</span>: <b>{this.state.totalStatistics.emptyCount} (%{parseFloat(this.state.totalStatistics.emptyPercent).toFixed(2)})</b></p>
                        </div>
                        <div className="statistics-item">
                          <p><span>Toplam Sınav Süresi</span>:&nbsp;
                            <b>
                              {this.state.totalStatistics.totalTimeHour.toString().padStart(2, '0')}:
                              {this.state.totalStatistics.totalTimeMinutes.toString().padStart(2, '0')}:
                              {this.state.totalStatistics.totalTimeSeconds.toString().padStart(2, '0')}
                            </b></p>
                          <p><span>Soru başına</span>: <b>
                            {this.state.totalStatistics.timePerQuestionHour.toString().padStart(2, '0')}:
                            {this.state.totalStatistics.timePerQuestionMinutes.toString().padStart(2, '0')}:
                            {this.state.totalStatistics.timePerQuestionSeconds.toString().padStart(2, '0')}
                          </b></p>
                        </div>
                      </div>
                    </Col>
                    <Col md="8">
                      <Pie data={this.state.totalPie} />
                    </Col>
                  </Row>
                </CardBody>
              }
              {
                this.state.quizBar &&
                <CardBody>
                  <h4>Deneme Sınavları Başarı Grafiği</h4>
                  <Row>
                    <Col md="12">
                      <Bar data={this.state.quizBar} />
                    </Col>
                  </Row>
                </CardBody>
              }
              {
                this.state.categoryStatistics &&
                <CardBody>
                  <h4>Soru Tiplerine Göre Sonuçlar</h4>
                  <div className="table-scroll">
                    <Table>
                      <thead>
                      <tr>
                        <th>Soru Tipi</th>
                        <th>Soru Sayısı</th>
                        <th>Doğru</th>
                        <th>Yanlış</th>
                        <th>Boş</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        this.state.categoryStatistics.map(item => {
                          return (
                            <tr key={item.categoryId}>
                              <td>{item.categoryName}</td>
                              <td>{item.totalQuestion}</td>
                              <td>{item.correctCount} (%{parseFloat(item.correctPercent).toFixed(2)})</td>
                              <td>{item.falseCount} (%{parseFloat(item.falsePercent).toFixed(2)})</td>
                              <td>{item.emptyCount} (%{parseFloat(item.emptyPercent).toFixed(2)})</td>
                            </tr>
                          )
                        })
                      }
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              }
              {
                this.state.subjectStatistics &&
                <CardBody>
                  <h4>Konulara Göre Sonuçlar</h4>
                  <div className="table-scroll">
                    <Table>
                      <thead>
                      <tr>
                        <th>Konu</th>
                        <th>Soru Sayısı</th>
                        <th>Doğru</th>
                        <th>Yanlış</th>
                        <th>Boş</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        this.state.subjectStatistics.map(item => {
                          return (
                            <tr key={item.subjectId}>
                              <td>{item.subjectName}</td>
                              <td>{item.totalQuestion}</td>
                              <td>{item.correctCount} (%{parseFloat(item.correctPercent).toFixed(2)})</td>
                              <td>{item.falseCount} (%{parseFloat(item.falsePercent).toFixed(2)})</td>
                              <td>{item.emptyCount} (%{parseFloat(item.emptyPercent).toFixed(2)})</td>
                            </tr>
                          )
                        })
                      }
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              }
              {
                this.state.vocabularySubjects &&
                <CardBody>
                  <h4>Konulara Göre Sonuçlar (Vocabulary)</h4>
                  <div className="table-scroll">
                    <Table>
                      <thead>
                      <tr>
                        <th>Konu</th>
                        <th>Soru Sayısı</th>
                        <th>Doğru</th>
                        <th>Yanlış</th>
                        <th>Boş</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        this.state.vocabularySubjects.map(item => {
                          return (
                            <tr key={item.subjectId}>
                              <td>{item.subjectName}</td>
                              <td>{item.totalQuestion}</td>
                              <td>{item.correctCount} (%{parseFloat(item.correctPercent).toFixed(2)})</td>
                              <td>{item.falseCount} (%{parseFloat(item.falsePercent).toFixed(2)})</td>
                              <td>{item.emptyCount} (%{parseFloat(item.emptyPercent).toFixed(2)})</td>
                            </tr>
                          )
                        })
                      }
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              }
              {
                this.state.grammarSubjects &&
                <CardBody>
                  <h4>Konulara Göre Sonuçlar (Grammar)</h4>
                  <div className="table-scroll">
                    <Table>
                      <thead>
                      <tr>
                        <th>Konu</th>
                        <th>Soru Sayısı</th>
                        <th>Doğru</th>
                        <th>Yanlış</th>
                        <th>Boş</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        this.state.grammarSubjects.map(item => {
                          return (
                            <tr key={item.subjectId}>
                              <td>{item.subjectName}</td>
                              <td>{item.totalQuestion}</td>
                              <td>{item.correctCount} (%{parseFloat(item.correctPercent).toFixed(2)})</td>
                              <td>{item.falseCount} (%{parseFloat(item.falsePercent).toFixed(2)})</td>
                              <td>{item.emptyCount} (%{parseFloat(item.emptyPercent).toFixed(2)})</td>
                            </tr>
                          )
                        })
                      }
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              }
              {
                this.state.worstCategory &&
                <CardBody>
                  <h4>En Zayıf Olduğunuz Soru Tipleri</h4>
                  <Row>
                    <Col md="4">
                      <div className="statistics-weak-graph">
                        <p><b>{this.state.worstCategory.categoryName}</b></p>
                        <Pie data={this.state.worstCategoryPie} />
                      </div>
                    </Col>
                    <Col md="8">
                      <div className="statistics-weak-info">
                        <p>{this.state.worstCategory.categoryName} için doğru cevap oranlarınız:<br />
                        <b>{this.state.worstCategory.correctCount} / {this.state.worstCategory.totalQuestion} (%{parseFloat(this.state.worstCategory.correctPercent).toFixed(2)})</b>
                        <br /><br />
                        </p>
                        <p>
                          {this.state.worstCategory.categoryName} soruları ile ilgili daha fazla çalışmak için <Link to="/store">tıklayınız</Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              }
              {
                this.state.secondWorstCategory &&
                <CardBody>
                  <h4>En Zayıf Olduğunuz Soru Tipleri</h4>
                  <Row>
                    <Col md="4">
                      <div className="statistics-weak-graph">
                        <p><b>{this.state.secondWorstCategory.categoryName}</b></p>
                        <Pie data={this.state.secondWorstCategoryPie} />
                      </div>
                    </Col>
                    <Col md="8">
                      <div className="statistics-weak-info">
                        <p>{this.state.secondWorstCategory.categoryName} için doğru cevap oranlarınız:<br />
                          <b>{this.state.secondWorstCategory.correctCount} / {this.state.secondWorstCategory.totalQuestion} (%{parseFloat(this.state.secondWorstCategory.correctPercent).toFixed(2)})</b>
                          <br /><br />
                        </p>
                        <p>
                          {this.state.secondWorstCategory.categoryName} soruları ile ilgili daha fazla çalışmak için <Link to="/store">tıklayınız</Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              }
              {
                this.state.worstVocabulary &&
                <CardBody>
                  <h4>En Zayıf Olduğunuz Vocabulary Konusu</h4>
                  <Row>
                    <Col md="4">
                      <div className="statistics-weak-graph">
                        <p><b>{this.state.worstVocabulary.subjectName}</b></p>
                        <Pie data={this.state.worstVocabularyPie} />
                      </div>
                    </Col>
                    <Col md="8">
                      <div className="statistics-weak-info">
                        <p>Tüm Vocabulary soruları için doğru cevap oranlarınız:<br />
                          <b>{this.state.vocabulary.correctCount} / {this.state.vocabulary.totalQuestion} (%{parseFloat(this.state.vocabulary.correctPercent).toFixed(2)})</b>
                          <br /><br />
                        </p>
                        <p>{this.state.worstVocabulary.subjectName} için doğru cevap oranlarınız:<br />
                          <b>{this.state.worstVocabulary.correctCount} / {this.state.worstVocabulary.totalQuestion} (%{parseFloat(this.state.worstVocabulary.correctPercent).toFixed(2)})</b>
                          <br /><br />
                        </p>
                        <p>
                          {this.state.worstVocabulary.subjectName} soruları ile ilgili daha fazla çalışmak için <Link to="/store">tıklayınız</Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              }
              {
                this.state.worstGrammar &&
                <CardBody>
                  <h4>En Zayıf Olduğunuz Grammar Konusu</h4>
                  <Row>
                    <Col md="4">
                      <div className="statistics-weak-graph">
                        <p><b>{this.state.worstGrammar.subjectName}</b></p>
                        <Pie data={this.state.worstGrammarPie} />
                      </div>
                    </Col>
                    <Col md="8">
                      <div className="statistics-weak-info">
                        <p>Tüm Grammar soruları için doğru cevap oranlarınız:<br />
                          <b>{this.state.grammar.correctCount} / {this.state.grammar.totalQuestion} (%{parseFloat(this.state.grammar.correctPercent).toFixed(2)})</b>
                          <br /><br />
                        </p>
                        <p>{this.state.worstGrammar.subjectName} için doğru cevap oranlarınız:<br />
                          <b>{this.state.worstGrammar.correctCount} / {this.state.worstGrammar.totalQuestion} (%{parseFloat(this.state.worstGrammar.correctPercent).toFixed(2)})</b>
                          <br /><br />
                        </p>
                        <p>
                          {this.state.worstGrammar.subjectName} soruları ile ilgili daha fazla çalışmak için <Link to="/store">tıklayınız</Link>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              }
            </Card>
          </Col>
        </Row>
    );
  }
}

export default Statistics;
