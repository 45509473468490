
let clientSideValidation = function(name,value) {
    if (!value.toString().trim().length) {
      return false;
    }
    if(name === "password"){
      if (value.toString().trim().length < 8) {
        return false;
      }
    } else if(name === "email"){
      // eslint-disable-next-line
      let EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return EMAIL_REGEX.test(value);
    }
    return true;
}

let clientSideValidationAll = function(validationObj) {
  let result = {
      name: true,
      surname: true,
      email: true,
      password: true,
      passwordAgain: true
  };

  for (let key in validationObj) {
    result[key] = clientSideValidation(key, validationObj[key]);
  }

  return result;
}

let isObjValid = function(validationObj) {

  for (let key in validationObj) {
    if(!validationObj[key]){
      return false;
    }
  }

  return true;
}

export default {
    'check' : clientSideValidation,
    'checkAll' : clientSideValidationAll,
    'isObjValid' : isObjValid
};
