/**
 * @AUTHOR Onur KESKİN
 * @DATE 01.01.2019
 */
const messageList = {
    technicalError: 'Özür dileriz, şu anda işleminizi gerçekleştiremiyoruz.',
    required: 'Lütfen bir değer girin.',
    minLength: 'Girilen değer en az {0} karakter olmalıdır.',
    maxLength: 'Girilen değer en fazla {0} karakter olmalıdır.',
    minMaxLength: 'Girilen değer {0}-{1} karakter arasında olmalıdır.',
    regexCheck: 'Lütfen girdiğiniz değeri kontrol edin.',
    checkSame: 'Parola tekrarı eşleşmemektedir.',
    alreadyExist: 'Girilen E-posta Adresi için üyelik bulunmaktadır.',
    mailNotFound: 'Girilen E-posta Adresi bulunmamaktadır.',
    invalidCurrentPass: 'Girilen Eski Parola bilgisi eşleşmemektedir.'
}

export default function (key, state, ...args) {
    let message = messageList[key];
    if( args !== undefined && args !== null && args !== "" && args.length > 0 ) {
      for(let i = 0; i < args.length; i++){
          message = message.replace("{"+i+"}",args[i]);
      }
    }
    return message;
};
