import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';
import {Link} from 'react-router-dom';

class PaymentFailed extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <b>HATA</b>
            </CardHeader>
            <CardBody>
              <div className="page-content">
                <div className="thank-you-page">
                  <div className="thank-you-container">
                    <div className="thank-you-icon error-icon">
                      <i className="fa fa-warning"></i>
                    </div>
                    <h2>Hata<br />Ödemeniz başarısız oldu!</h2><br />
                    <Link className="btn btn-primary" to={'/'}>ANA SAYFAYA DÖN</Link>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default PaymentFailed;
