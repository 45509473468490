import React, { Component } from 'react';
import {
  Col, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';

import QuestionsAside from './QuestionsAside'
import ExamInfo from './ExamInfo'
import QuestionBody from './QuestionBody'
import Timer from './Timer'

import moment from 'moment';
import swal from 'sweetalert';
import _ from 'lodash';
import {isMobileOnly} from 'react-device-detect';
import validationMessages from '../../../utils/validationMessages';
import cookie from '../../../utils/cookie';
import _xhr from '../../../utils/_xhr';
import rest from '../../../utils/rest.cfg';
const _quizzesUrl = rest('quizzes');
const _currentTimeUrl = rest('date');
const _quizattemptsUrl =rest('quizattempts');
const _questionAttemptsUrl =rest('questionattempts');
const _usersUrl = rest('users');
const auth = rest('auth');
const _quizSlotsUrl = rest('quizslots');
const _quizAttemptUrl = rest('quizattempt');
const _questionAnswersUrl = rest('questionanswers');
const _questionAttemptUrl = rest('questionattempt');

let countupID;

class TakeExam extends Component {
  constructor(props) {
      super(props);

      this.state = {
        showQuestions       : false,
        isQuizOpened        : false,
        questionsAndAnswers : [],
        quizAttempt: {},
        questionAndAnswerAttempts : {},
        answerPrefix: ["A) ", "B) ", "C) ", "D) ", "E) "],
        totalSpentTimeMs: 0,
        quizTimeMs: 0,
        remainTimeMs: 0,
        totalPercentage: 100,
        questionCount: 0,
          availableQuiz: 0,
        modal: false,
        isStarted: false,
          premiumAlert: false
      };

      this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentWillUnmount() {
    if (countupID) {
        clearInterval(countupID);
    }
  }

  componentWillMount() {
    let self = this;
    if (countupID) {
        clearInterval(countupID);
    }
    this.checkQuizAvailable();
  }

  checkQuizAvailable() {
    let self = this;
    self.getCurrentTime().then(() => {
      self.getQuizInfo().then(() => {
        if (self.state.premiumAlert) {
          console.log('getting available');
          self.getUserAvailableQuiz().then(() => {
            if(self.state.availableQuiz > 0) {
              swal("Bu sınav ücretlidir. Sınava giriş yapmak için bir sınav hakkınızı kullanmanız gereklidir." , {
                buttons: {
                  cancel: 'Geri Dön',
                  confirm: 'Sınav Hakkı Kullan',
                }
              }).then((e) => {
                self.useProduct(e)
              });
            } else {
              if(self.state.quiz.categoryId === 3) {
                swal("Bu sınav ücretlidir. Sınava erişebilmek için lütfen Mağaza kısmından YDT Deneme Sınavı sınav hakkı satın alınız." , {
                  buttons: {
                    cancel: 'Geri Dön',
                    confirm: 'Mağazaya Git',
                  }
                }).then((e) => {
                  if(e) {
                    self.props.history.push('/store');
                  }
                });
              } else if (self.state.quiz.categoryId === 4) {
                swal("Bu sınav ücretlidir. Sınava erişebilmek için lütfen Mağaza kısmından YDT Konu Testi sınav hakkı satın alınız." , {
                  buttons: {
                    cancel: 'Geri Dön',
                    confirm: 'Mağazaya Git',
                  }
                }).then((e) => {
                  if(e) {
                    self.props.history.push('/store');
                  }
                });
              } else {
                swal("Bu sınav ücretlidir. Sınava erişebilmek için lütfen Mağaza kısmından sınav hakkı satın alınız." , {
                  buttons: {
                    cancel: 'Geri Dön',
                    confirm: 'Mağazaya Git',
                  }
                }).then((e) => {
                  if(e) {
                    self.props.history.push('/store');
                  }
                });
              }

            }
          }).catch( e => {
            console.log(e);
          })
        } else if (!self.state.isQuizOpened) {
          swal("Sınav, " + self.state.timeOpenFormatted + " ile " + self.state.timeCloseFormatted + " arasında erişime açık olacaktır." , {
          });
        }
      }).catch( error => {
        console.log(error)
      })
    });
  }

  componentDidMount() {
  }

  componentWillUpdate() {

  }

  componentDidUpdate() {

  }

  componentWillReceiveProps(newProps) {

  }

  getUserAvailableQuiz() {
      let self = this;
      const userId = cookie.get(auth.user);
      return new Promise((resolve, reject) => {
          _xhr.get({
              url: rest('rest') + 'user/products/' + userId,
              onsuccess: function(response){
                  let count = 0;
                  response.data.available.forEach( item => {
                      if(!item.product.quizId && !item.product.publicationId) {
                          count = count + item.count;
                      }
                  })
                  self.setState({
                      availableQuiz: count
                  })
                  resolve();
              },
              onerror: function(error) {
                self.setState({
                  availableQuiz: 0
                })
                resolve();
              }
          });
      });
  }

  useProduct(e) {
    if(e) {
      let self = this;
      let quizId = self.props.match.params.id;
      let body = {
        quizId: parseInt(quizId)
      }
      _xhr.post({
        url: rest('rest') + 'user/use-product',
        params: body,
        onsuccess: function (response) {
          window.location.reload();
        }
      });
    }
  }

  getCurrentTime() {
    let self = this;
    return new Promise((resolve, reject) => {
        _xhr.get({
            url: _currentTimeUrl,
            onsuccess: function (response) {
              var now = moment(response.data.now).format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
              self.setState({
                now: moment(now)
              })
              resolve();
            }
        });
    });
  }

  preventDoubleClick(){
      return document.body.classList.contains('onprocess');
  }

  getQuizInfo() {
    let self = this;
    self.getQuizSlots().then(()=>{}).catch(e=>{console.log(e)})
    return new Promise((resolve, reject) => {
        _xhr.get({
            url: _quizzesUrl + "/" + self.props.match.params.id,
            onsuccess: function (response) {
              let quizTimeMs = response.data.timeLimit*60*1000;
              self.setState({
                  quiz: response.data,
                  timeOpenFormatted: moment(response.data.timeOpen).format("DD/MM/YYYY HH:mm"),
                  timeCloseFormatted: moment(response.data.timeClose).format("DD/MM/YYYY HH:mm"),
                  timeLimit: response.data.timeLimit,
                  quizTimeMs: quizTimeMs
              })

              var currentTime = self.state.now;
              var quizOpenTime = moment(response.data.timeOpen).format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
              var quizOpenTimeObj = moment(quizOpenTime);
              var quizCloseTime = moment(response.data.timeClose).format("YYYY-MM-DDTHH:mm:ss.SSSSZ");
              var quizCloseTimeObj = moment(quizCloseTime);

              let remainTimeMs;
              let remainToQuizCloseTimeMs = quizCloseTimeObj - currentTime;
              remainTimeMs = quizTimeMs;
              /*if ( remainToQuizCloseTimeMs >= quizTimeMs){
                remainTimeMs = quizTimeMs;
              }else{
                remainTimeMs = remainToQuizCloseTimeMs;
              }*/
              if (currentTime >= quizOpenTimeObj && currentTime <= quizCloseTimeObj) {
                  self.setState({
                      remainTimeMs: remainTimeMs,
                      isQuizOpened: true
                  })
              }
              if(response.data.isPremium && !response.data.isUsed) {
                  self.setState({
                      premiumAlert: true
                  })
              }

              if(!response.data.timeOpen) {
                self.setState({
                  remainTimeMs: remainTimeMs,
                  isQuizOpened: true
              })
              }

              resolve();
            },
            onerror: function (error) {
              console.log(error)
            }
        });
    });
  }

  goToPrevQuestion(){
    let self = this;
    self.goToQuestion(self.state.activeQuestionData.listIndex -1);
  }

  goToNextQuestion(){
    let self = this;
    self.goToQuestion(self.state.activeQuestionData.listIndex +1);
  }

  goToQuestion(index, start){
    let self = this;
    if(isMobileOnly && self.state.modal){
      self.toggle();
    }
    self.saveQuestion(start).then(() => {
        self.showQuestion(index).then(() => {
            self.fillQuestion(index);
        }); 
    });
  }

  _goToQuestion(index, start){
      let self = this;
      if(isMobileOnly && self.state.modal){
        self.toggle();
      }
      self.saveQuestion(start).then(() => {
          self.showQuestion(index).then(() => {
              self.fillQuestion(index);
          });
      });
  }

  fillQuestion(index){
    let self = this;
    let activeQuestionData = self.state.questionsAndAnswers[index];

    if (activeQuestionData.qtype !== "multichoice"){
      document.getElementById("reply").value = "";
    }else{
      let radios = document.querySelectorAll('[type="radio"]');
      [].forEach.call(radios, function (el) {
          el.checked = false;
      })
    }
    let url = _questionAttemptUrl;
    // GET Question attempt and check if user answered
    _xhr.get({
      url: url + '/' + activeQuestionData.id,
      onsuccess: function(response) {
        //document.getElementById("reply").value = response.data.questionAnswer.answer;
        if(response.data.questionAnswer) {
          activeQuestionData.response = response.data.questionAnswer.answer;
          activeQuestionData.Answers.forEach(function (answer, index) {
            if (answer.id === response.data.questionAnswer.id) {
                let activeAnswerNode = document.getElementById("answer_" + answer.id);
                activeAnswerNode.checked = true;
                activeQuestionData.responseId = response.data.questionAnswer.id;
            }
          })
          self.setState({
            activeQuestionData: activeQuestionData
         })
        }
        
      },
      onerror: function(error) {
        console.log(error);
      }
    })
    
/*     if( questionAndAnswerAttempts[0].QuestionAttempts ){
        let attemptItemIndex = _.findIndex(questionAndAnswerAttempts[0].QuestionAttempts, {questionId: activeQuestionData.id});
        if( attemptItemIndex >= 0 ){
            let attemptItem = questionAndAnswerAttempts[0].QuestionAttempts[attemptItemIndex];

            if (activeQuestionData.qtype !== "multichoice"){
               if( attemptItem.response !== null && attemptItem.response !== undefined ) {
                 document.getElementById("reply").value = attemptItem.response;
                 activeQuestionData.response = attemptItem.response;
               }
            }else{
              if( attemptItem.responseId !== null && attemptItem.responseId !== undefined ) {
                activeQuestionData.Answers.forEach(function (answer, index) {
                    if (answer.id === attemptItem.responseId) {
                        let activeAnswerNode = document.getElementById("answer_" + answer.id);
                        activeAnswerNode.checked = true;
                        activeQuestionData.responseId = attemptItem.responseId;
                    }
                })
              }
            }
        }
        self.setState({
            activeQuestionData: activeQuestionData
        })
    } */
  }

  showQuestion(index){
    let self = this;
    return new Promise((resolve, reject) => {
      let activeQuestionData = self.state.questionsAndAnswers[index];
      if(activeQuestionData) {
        activeQuestionData.startTime = +new Date();
        activeQuestionData.parentQuestion = self.state.parentQuestions[self.state.questionsAndAnswers[index].parent];
        if(activeQuestionData.qtype !== 'parent') {
          _xhr.get({
            url: _questionAnswersUrl + '/' + activeQuestionData.id,
            onsuccess: function(response) {
              activeQuestionData.Answers = response.data;
              self.setState({
                  activeQuestionData: activeQuestionData,
                  showQuestions: true
              })
              window.scrollTo(0, 0);
              resolve();
              }
          })
        }
      }

      
      
    });
  }
  checkQuizCompleted(){
    let self = this;
    if(self.state.quizAttemptCompleted){
        swal(validationMessages("technicalError"),
        {
          icon: "error",
          button: "Ana Sayfa"
        })
        .then((value) => {
            self.props.history.push('/');
        });
    }
  }
  saveQuestion (start){
    let self = this;
    if(start){
      return new Promise((resolve, reject) => {
        if(self.preventDoubleClick()){
          return false;
        }
        resolve();
      });
    }else{
    return new Promise((resolve, reject) => {
        if(self.preventDoubleClick()){
          return false;
        }
        let activeQuestionData = self.state.activeQuestionData;
        activeQuestionData.endTime = +new Date();
        activeQuestionData.elapsedTime = activeQuestionData.endTime -  activeQuestionData.startTime;

        let questionAndAnswerAttempts = self.state.questionAndAnswerAttempts;
        let elapsedTime = activeQuestionData.elapsedTime;

        let response = null;
        let responseId = null;
        if(activeQuestionData.response){
          response = activeQuestionData.response;
        }
        if(activeQuestionData.responseId){
          responseId = activeQuestionData.responseId;
        }

        let updateType = false;
        let insertType = true;

        let attemptItemIndex = -1;
        if( questionAndAnswerAttempts.length > 0){
          attemptItemIndex = _.findIndex(questionAndAnswerAttempts, {questionId: activeQuestionData.id});
          if ( attemptItemIndex >= 0 ) {
               insertType = false;
               updateType = true;

               elapsedTime =
               questionAndAnswerAttempts[attemptItemIndex].timeElapsed +
               activeQuestionData.elapsedTime;
          }else{
             attemptItemIndex = questionAndAnswerAttempts.length;
          }
        }else{
          attemptItemIndex = 0;
          questionAndAnswerAttempts = [];
        }
        if(!responseId) {
          response = null
        }
        let questionAndAnswerAttemptsObj = {
            "questionId": activeQuestionData.id,
            "quizAttemptId": self.state.quizAttemptId,
            "response": response,
            "responseId": responseId,
            "timeElapsed": elapsedTime
        }

        if (activeQuestionData.qtype === "multichoice"){
           delete questionAndAnswerAttemptsObj.response;
        }else{
           delete questionAndAnswerAttemptsObj.responseId;
        }
        console.log(questionAndAnswerAttemptsObj);
        //let url = _usersUrl + "/" + cookie.get(auth.user) + _quizattemptsUrl + "/" + questionAndAnswerAttempts[0].id + _questionAttemptsUrl ;
        let url = _questionAttemptUrl;
        self.checkQuizCompleted();
        _xhr.post({
              url: url + '/add',
              params: questionAndAnswerAttemptsObj,
              onsuccess: function (response) {
                  questionAndAnswerAttemptsObj.id = response.data.id;
                  questionAndAnswerAttempts[attemptItemIndex] = questionAndAnswerAttemptsObj;
                  self.setState({
                      activeQuestionData: activeQuestionData,
                      questionAndAnswerAttempts: questionAndAnswerAttempts
                  })
                  resolve();
              }
          });
    });
    }
  }

  finishExam(notConfirm){
    let self = this;

    if(notConfirm){
      self.saveExam();
    }else{
      swal("Onay",
           "Sınavı bitirdiğinizde tekrar giriş yapamayacaksınız. Devam etmek istiyor musunuz?",
      {
        buttons: {
          cancel: "İptal",
          confirm: "Onay",
        }
      })
      .then((submit) => {
          if(submit){
            self.saveExam();
          }
      });
    }
  }

  saveExam(){
    if (countupID) {
        clearInterval(countupID);
    }
    let self = this;
    self.saveQuestion().then(() => {
        let quizattemptsUrl = _quizAttemptUrl
        let resultObj = {
            "quizId": parseInt(self.props.match.params.id),
            "completed": true
        };
        _xhr.post({
            url: quizattemptsUrl + '/add',
            params: resultObj,
            onsuccess: function (response) {
                console.log(response);
                swal("Sonuç",
                     "Sınavınız başarıyla kaydedilmiştir.",
                {
                  icon: "success",
                  button: "Ana Sayfa"
                })
                .then((value) => {
                    self.props.history.push('/');
                });
            }
        });
    })
  }

  startExam(e) {
    let self = this;

    if(self.preventDoubleClick()){
      return false;
    }

    if(!self.state.premiumAlert) {
      self.getQuizSlots().then(()=>{
        self.getQuizAttempt().then(()=>{
          self.goToQuestion(0, true);
        })
      })
    } else {
      this.checkQuizAvailable()
    }
  }

  getQuizSlots() {
    let self = this;
    return new Promise((resolve, reject) => {
      _xhr.get({
        url: _quizSlotsUrl + '/' + self.props.match.params.id,
        onsuccess: function (response) {
          let parents = {};
          let questionsAnswers = [];
          let index = 0;
            response.data.sort(function(a, b) {
                return a["group"] - b["group"] || a["slot"] - b["slot"];
            });
            //response.data.sort((a,b) => (a.group > b.group) ? 1 : ((b.group > a.group) ? -1 : 0))
            //response.data.sort((a,b) => (a.slot > b.slot) ? 1 : ((b.slot > a.slot) ? -1 : 0))
            response.data.forEach(function (data, i) {
              let question = data.question;
              if (data.question.qtype === "parent") {
                  parents[data.question.id] = data.question.questionText;
              } else {
                  if(i === 0 && data.question.questionCategory) {
                    parents[data.question.questionCategory.id] = data.question.questionCategory.groupText;
                  } else if(i > 0 && response.data[i-1].group !== data.group && data.question.questionCategory) {
                    parents[data.question.questionCategory.id] = data.question.questionCategory.groupText;
                  }
                  if(question.questionCategory) {
                      question.parentText = question.questionCategory.groupText
                  }
                  data.question.listIndex = index;
                  if(question.parent) {
                      let parentIndex = _.findIndex(response.data, (e) => {
                          return e.question.id === question.parent;
                      })

                        //console.log(response.data[parentIndex].parentNumber);
                      if(parentIndex > -1) {
                          if(response.data[parentIndex].question.parentNumber === undefined) {
                              response.data[parentIndex].question.parentNumber = index;
                          }
                          question.childQuestion = question.questionText;
                          let pat = /[^{\}]+(?=})/g;
                          let res;
                          if(pat) {
                              res = response.data[parentIndex].question.questionText.replace(pat, function(number){
                                  return response.data[parentIndex].question.parentNumber + parseInt(number);
                              });
                              //res = res.replaceAll('{','(');
                              //res = res.replaceAll('}',')');
                              question.questionText = res;
                          } else {
                              question.questionText = response.data[parentIndex].question.questionText;
                          }
                      }
                  }
                  questionsAnswers.push(question);
                  index++
              }
          })
          self.setState({
              questionCount: index,
              questionsAndAnswers: questionsAnswers,
              parentQuestions: parents
          })
          resolve();
        }
      })
    });
  }

  getActualQuizAttempt() {
      let self = this;
      return new Promise((resolve, reject) => {
          _xhr.get({
              url: _quizAttemptUrl + '/' + self.props.match.params.id,
              onsuccess: function(response) {
                  let totalSpentTimeMs = 0;
                  let remainTimeMs = self.state.remainTimeMs;
                  if( response.data.questionAttempts ){
                      response.data.questionAttempts.forEach(function (questionAttempt) {
                          totalSpentTimeMs = totalSpentTimeMs + questionAttempt.timeElapsed;
                      })
                      remainTimeMs = remainTimeMs - totalSpentTimeMs;
                  }
                  if(remainTimeMs < 0) {
                      remainTimeMs = 0;
                  }
                  self.setState({
                      quizAttemptId: response.data.id,
                      quizAttemptCompleted: response.data.completed,
                      questionAndAnswerAttempts: response.data.questionAttempts,
                      totalSpentTimeMs: totalSpentTimeMs,
                      remainTimeMs: remainTimeMs
                  })
                  //self.checkQuizCompleted();
                  self.convertRemainMsToFormathhmmss(self.state.remainTimeMs, self.state.quizTimeMs);

                  self.startCounter();
                  resolve();
              },
              onerror: function(error) { // No QuizAttempt for this user so create one
                  console.log(error)
                  let params = {
                      "quizId": parseInt(self.props.match.params.id)
                  }
                  _xhr.post({
                      url: _quizAttemptUrl + '/add',
                      params: params,
                      onsuccess: function(response) {
                          self.getQuizAttempt();
                      },
                      onerror: function(error) {
                          console.log(error);
                      }
                  })
              }
          })
      })
  }

  getQuizAttempt() {
    let self = this;
    return new Promise((resolve, reject) => {
      _xhr.get({
        url: _quizAttemptUrl + '/' + self.props.match.params.id,
        onsuccess: function(response) {
          let totalSpentTimeMs = 0;
          let remainTimeMs = self.state.remainTimeMs;
          if( response.data.questionAttempts ){
              response.data.questionAttempts.forEach(function (questionAttempt) {
                  totalSpentTimeMs = totalSpentTimeMs + questionAttempt.timeElapsed;
              })
              remainTimeMs = remainTimeMs - totalSpentTimeMs;

          }
            if(remainTimeMs < 0) {
                remainTimeMs = 0;
            }
          self.setState({
            quizAttemptId: response.data.id,
            quizAttemptCompleted: response.data.completed,
            questionAndAnswerAttempts: response.data.questionAttempts,
            totalSpentTimeMs: totalSpentTimeMs,
            remainTimeMs: remainTimeMs
          })
          //self.checkQuizCompleted();
          self.convertRemainMsToFormathhmmss(self.state.remainTimeMs, self.state.quizTimeMs);
          
          self.startCounter();
          resolve();
        },
        onerror: function(error) { // No QuizAttempt for this user so create one
          let params = {
            "quizId": parseInt(self.props.match.params.id)
          }
          _xhr.post({
            url: _quizAttemptUrl + '/add',
            params: params,
            onsuccess: function(response) {
                setTimeout(function(){ self.getActualQuizAttempt(); }, 300);
                resolve();
            },
            onerror: function(error) {
              console.log(error);
            }
          })
        }
      })
    })
  }



  onChangeAnswer(e) {
      let self = this;
      let answer = e.target.value;
      let activeQuestionData = self.state.activeQuestionData;
      answer = answer * 1;
      if (answer === activeQuestionData.responseId ) {
          e.target.checked = false;
          activeQuestionData.responseId = null
      } else {
          activeQuestionData.responseId = answer
      }
      self.setState({
          activeQuestionData: activeQuestionData
      })

  }

  onChangeTextAnswer(e) {
      let self = this;
      let answer = e.target.value;

      let activeQuestionData = self.state.activeQuestionData;
      if( answer !== null && answer !== undefined && answer.trim() !== "" ){
         activeQuestionData.response = answer
      }else{
         activeQuestionData.response = undefined
      }

      self.setState({
          activeQuestionData: activeQuestionData
      })

  }


  convertRemainMsToFormathhmmss(difference, differenceTT) {
      let self = this;
      var _totalSec = difference/1000;
      var daysDifference = Math.floor(_totalSec/60/60/24);
      difference -= daysDifference*1000*60*60*24;
      var _totalSecTT = differenceTT/1000;
      var daysDifferenceTT = Math.floor(_totalSecTT/60/60/24);
      differenceTT -= daysDifferenceTT*1000*60*60*24;
      var hoursDifference = Math.floor(difference/1000/60/60);
      difference -= hoursDifference*1000*60*60;
      var hoursDifferenceTT = Math.floor(differenceTT/1000/60/60);
      differenceTT -= hoursDifferenceTT*1000*60*60;

      var minutesDifference = Math.floor(difference/1000/60);
      difference -= minutesDifference*1000*60;
      var minutesDifferenceTT = Math.floor(differenceTT/1000/60);
      differenceTT -= minutesDifferenceTT*1000*60;

      var secondsDifference = Math.floor(difference/1000);
      var secondsDifferenceTT = Math.floor(differenceTT/1000);
      self.setState({
          remainTime: {
              sec: secondsDifference,
              min: minutesDifference,
              hour: hoursDifference
          },
          totalTime: {
              secTT: secondsDifferenceTT,
              minTT: minutesDifferenceTT,
              hourTT: hoursDifferenceTT
          }
      })
  }

  startCounter() {
      let self = this;

      let sec = self.state.remainTime.sec;
      let min = self.state.remainTime.min;
      let hour = self.state.remainTime.hour;
      if(sec > 60) sec = 60;
      if(min > 60) min = 60;
      if(!self.state.isStarted) {
        countupID = setInterval(function () {
          var totalPercentage = Math.floor(( ((sec*1000) + (min*60*1000) + (hour*60*60*1000)) / self.state.quizTimeMs)*100);
          if (sec < 1) {
              if(min < 1){
                  if(hour > 0){
                    hour -= 1;
                    min = 59;
                    sec = 59;
                  }else{
                    self.finishExam(true);
                  }
              }else{
                min -= 1;
                sec = 59;
              }
          } else {
              sec -= 1;
          }

          self.setState({
              remainTime: {
                  sec: sec,
                  min: min,
                  hour: hour
              },
              totalPercentage: totalPercentage
          })

      }, 1000)
      }
      self.setState({
        isStarted: true
      })
  }

  render() {
    if ( !this.state.showQuestions && this.state.quiz ) {
      return (
        <Row>
          <Col>
          <ExamInfo
            header={this.state.quiz.name}
            timeLimit={this.state.timeLimit}
            timeOpen={this.state.timeOpenFormatted}
            timeClose={this.state.timeCloseFormatted}
            visibleButton={this.state.isQuizOpened}
            questionCount={this.state.questionCount}
            buttonText= "Sınava Başla"
            onClick={this.startExam.bind(this)}
            />
          </Col>
        </Row>
      );
    } else if ( this.state.showQuestions && this.state.activeQuestionData) {
      return (

          <Row className="zeroBreadcrumbMargin">
            {isMobileOnly &&
              <Col md="3" className="pr-0 pl-0">
                <Timer
                  onClick={this.toggle.bind(this)}
                  totalTime={this.state.totalTime}
                  remainTime={this.state.remainTime}
                  totalPercentage={this.state.totalPercentage}
                  linkText="Tüm Sorular"/>

                  <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Sorular</ModalHeader>
                    <ModalBody className = "pl-0 pr-0">
                      <QuestionsAside
                        questions={this.state.questionsAndAnswers}
                        activeIndex={this.state.activeQuestionData.listIndex}
                        onClick={this.goToQuestion.bind(this)}
                        questionAttempts={this.state.questionAndAnswerAttempts}
                        totalTime={this.state.totalTime}
                        remainTime={this.state.remainTime}
                        totalPercentage={this.state.totalPercentage}/>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={this.toggle}>Kapat</Button>
                    </ModalFooter>
                  </Modal>
              </Col>
            }
            <Col md="9" className={isMobileOnly ? "pr-0 pl-0" : "scrollableArea pr-0 pl-0"}>
              <QuestionBody
                header={this.state.quiz.name}
                questionLength={this.state.questionsAndAnswers.length}
                activeQuestion={this.state.activeQuestionData}
                previousButtonOnClick={this.goToPrevQuestion.bind(this)}
                nextButtonOnClick={this.goToNextQuestion.bind(this)}
                finishButtonOnClick={this.finishExam.bind(this)}
                onChangeAnswer={this.onChangeAnswer.bind(this)}
                onChangeTextAnswer={this.onChangeTextAnswer.bind(this)}
                />
            </Col>
            {!isMobileOnly &&
              <Col md="3" className={isMobileOnly ? "pr-0 pl-0" : "pr-0 pl-0"}>
                <QuestionsAside
                  questions={this.state.questionsAndAnswers}
                  activeIndex={this.state.activeQuestionData.listIndex}
                  onClick={this.goToQuestion.bind(this)}
                  questionAttempts={this.state.questionAndAnswerAttempts}
                  totalTime={this.state.totalTime}
                  remainTime={this.state.remainTime}
                  totalPercentage={this.state.totalPercentage}/>
              </Col>
            }
          </Row>

      );
    }
    else return null;
  }
}

export default TakeExam;
