import React, { Component } from 'react';
import {
  Badge,
  Progress,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import PropTypes from 'prop-types';
import _ from 'lodash';

const propTypes = {
  questions: PropTypes.array,
  activeIndex: PropTypes.number,
  onClick: PropTypes.func,
  questionAttempts: PropTypes.array,
  totalTime: PropTypes.object,
  remainTime: PropTypes.object,
  totalPercentage: PropTypes.number,
  resultInfo: PropTypes.bool
};

const defaultProps = {};

class QuestionsAside extends Component {

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(index) {
    if (this.props.onClick) {
      this.props.onClick(index);
    }
  }

  render() {

    const { questions, activeIndex, onClick, questionAttempts, totalTime, remainTime, totalPercentage, resultInfo } = this.props;
if(remainTime) {
  if(remainTime.hour.toString().length === 1){
    remainTime.hour = "0"+remainTime.hour;
  }
  if(remainTime.min.toString().length === 1){
    remainTime.min = "0"+remainTime.min;
  }
  if(remainTime.sec.toString().length === 1){
    remainTime.sec = "0"+remainTime.sec;
  }

}
if(totalTime) {
  if(totalTime.hourTT.toString().length === 1){
    totalTime.hourTT = "0"+totalTime.hourTT;
  }
  if(totalTime.minTT.toString().length === 1){
    totalTime.minTT = "0"+totalTime.minTT;
  }
  if(totalTime.secTT.toString().length === 1){
    totalTime.secTT = "0"+totalTime.secTT;
  }
}

    let header = "Kalan Zaman";
    if(resultInfo){
      header = "Toplam Zaman";
    }
    return (
      <React.Fragment>
        <ListGroup className="list-group-accent questions-timer">
          <ListGroupItem className="list-group-item-accent text-center font-weight-bold text-muted small">
            <div className="text-uppercase mb-1 mt-2">
              <b>{header}</b>
            </div>
            <Progress className="progress-xs" color="danger" value={totalPercentage} />
            { remainTime ?  <b>{remainTime.hour}:{remainTime.min}:{remainTime.sec} / {totalTime.hourTT}:{totalTime.minTT}:{totalTime.secTT}</b> : null}
          </ListGroupItem>
        </ListGroup>
        <ListGroup className="list-group-accent questions-aside scrollableArea">
          <ListGroupItem className="list-group-item-accent bg-light text-center font-weight-bold text-muted text-uppercase small">
            SORULAR<br></br>

            {
              resultInfo &&
              <div>
                <Badge className="mr-1" color="success">DOĞRU CEVAP</Badge>
                <Badge className="mr-1" color="danger">YANLIŞ CEVAP</Badge>
                <Badge className="mr-1" color="secondary">BOŞ CEVAP</Badge>
                <Badge className="mr-1" color="primary">SEÇİLİ SORU</Badge>
              </div>
            }
            {
              !resultInfo &&
              <div>
              <Badge className="mr-1" color="success">CEVAPLANMIŞ SORU</Badge>
              <Badge className="mr-1" color="primary">SEÇİLİ SORU</Badge>
              </div>
            }

            </ListGroupItem>
          {
              questions &&
              questions.map((question, index) => {
                  let answered = false;
                  let correct = false;
                  if( questionAttempts.length > 0 ){
                    let attemptItemIndex = _.findIndex(questionAttempts, {questionId: question.id});
                    if( attemptItemIndex >= 0 ){

                      var attemptItem = questionAttempts[attemptItemIndex];
                      if(attemptItem.responseId) {
                        answered = true;
                      }
                      if(attemptItem.questionAnswer) {
                        if(attemptItem.questionAnswer.correct) {
                          correct = true;
                        }
                      }

                       if(question.Answers) {
                        question.Answers.forEach(item => {
                          if(attemptItem.responseId == item.id && item.correct)
                          correct = true;
                        });
                      }

                    }
                    /* if( questionAttempts[0].QuestionAttempts ){
                      let attemptItemIndex = _.findIndex(questionAttempts[0].QuestionAttempts, {questionId: question.id});
                      if( attemptItemIndex >= 0 ){
                         var attemptItem = questionAttempts[0].QuestionAttempts[attemptItemIndex];
                         if( (attemptItem.response !== null && attemptItem.response !== undefined)
                             || (attemptItem.responseId !== null && attemptItem.responseId !== undefined) ){
                           answered = true;
                           if(question.qtype === "multichoice"){
                             let questionAnswerItemIndex = _.findIndex(question.Answers, {id: attemptItem.responseId});
                             correct = question.Answers[questionAnswerItemIndex].correct;
                           }else if( attemptItem.response.toUpperCase() === question.Answers[0].answer.toUpperCase() ){
                             correct = true;
                           }

                         }
                      }
                    } */
                  }

                  let color;
                  let counterColor
                  if (index === activeIndex ){
                    color=" list-group-item-accent-info list-group-item-divider questionParentHeader "
                    counterColor = " avatar bg-info text-center "
                  }else if (index !== activeIndex && !answered ) {
                    color=" list-group-item-accent-secondary list-group-item-action list-group-item-divider questionParentHeader "
                    counterColor = " avatar bg-secondary text-center "
                  }else if (index !== activeIndex && answered ) {
                    if(resultInfo){
                      if(correct){
                        color=" list-group-item-accent-success list-group-item-action list-group-item-divider questionParentHeader "
                        counterColor = " avatar bg-success text-center "
                      }else {
                        color=" list-group-item-accent-danger list-group-item-action list-group-item-divider questionParentHeader "
                        counterColor = " avatar bg-danger text-center "
                      }
                    }else{
                      color=" list-group-item-accent-success list-group-item-action list-group-item-divider questionParentHeader "
                      counterColor = " avatar bg-success text-center "
                    }
                  }

                  return <ListGroupItem href="#" onClick={(e) => {e.preventDefault(); onClick(index)}} key={index}  tag="a" className={color}>
                    <div className="message">
                      <div className="py-2 pb-2 mr-3 float-left">
                        <div className={counterColor}>{question.listIndex + 1}</div>
                      </div>
                      <small className="text-muted ">
                          <span dangerouslySetInnerHTML={{ __html: question.questionText }}>
                          </span>
                      </small>
                    </div>
                  </ListGroupItem>
              })
          }
        </ListGroup>
      </React.Fragment>
    );
  }
}

QuestionsAside.propTypes = propTypes;
QuestionsAside.defaultProps = defaultProps;

export default QuestionsAside;
