/**
 * @AUTHOR Onur KESKİN
 * @DATE 30.12.2018
 */

import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Container, FormFeedback, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

import swal from 'sweetalert';
import rest from '../../utils/rest.cfg';
import regex from '../../utils/regex';
import FormValidator from '../../utils/FormValidator';
import validationMessages from '../../utils/validationMessages';
import _xhr from '../../utils/_xhr';
import {isMobile} from 'react-device-detect';
import appConstants from '../../utils/appConstants';

import 'date-input-polyfill-react';

const _registerUrl = rest('register');
const _citiesUrl = rest('cities');
const _countiesUrl = rest('counties');
class Register extends Component {
  constructor(props) {
      super(props);
      this.onSubmit = this.onSubmit.bind(this);

      this.inputs = {
          name          : { id: 'name'          , label: 'İsim            ', value: "" },
          surname       : { id: 'surname'       , label: 'Soyisim         ', value: "" },
          birthDate     : { id: 'birthDate'     , label: 'Doğum Tarihi    ', value: "" },
          phone         : { id: 'phone'         , label: 'Telefon         ', value: "", placeholder: "0(xxx)xxxxxxx" },
          email         : { id: 'email'         , label: 'E-posta Adresi  ', value: "", placeholder: "xxx@yyy.zzz" },
          password      : { id: 'password'      , label: 'Parola          ', value: "" },
          passwordagain : { id: 'passwordagain' , label: 'Parola Tekrarı  ', value: "" },
          schoolName    : { id: 'schoolName'    , label: 'Okul            ', value: "" },
          classroom     : { id: 'classroom'     , label: 'Sınıf           ', value: "" },
          cities        : { id: 'cities'        , label: 'Şehir           ', value: "" },
          locationId    : { id: 'locationId'    , label: 'İlçe            ', value: "" },
          isSubscribe: {id: 'isSubscribe', label: 'Kampanya, duyuru, bilgilendirmelerden e-posta ile haberdar olmak istiyorum.', value: false},
          agree: {id: 'agree', label: 'Kişisel Verilerin Korunması metnini kabul ediyorum.', value: false}
      };

      this.validator = new FormValidator([
        { field: this.inputs.name.id          , method: 'required' },
        { field: this.inputs.name.id          , method: 'regexCheck', args: [regex("alphaTurkishPlusSpace")]},
        { field: this.inputs.surname.id       , method: 'required' },
        { field: this.inputs.surname.id       , method: 'regexCheck', args: [regex("alphaTurkishPlusSpace")]},
        { field: this.inputs.birthDate.id     , method: 'required' },
        { field: this.inputs.birthDate.id     , method: 'regexCheck', args: [regex("date")] },
        { field: this.inputs.phone.id         , method: 'required' },
        { field: this.inputs.email.id         , method: 'required' },
        { field: this.inputs.email.id         , method: 'regexCheck', args: [regex("mail")]},
        { field: this.inputs.password.id      , method: 'required' },
        { field: this.inputs.password.id      , method: 'minLength', args: [8], msgArs:[8] },
        { field: this.inputs.passwordagain.id , method: 'required' },
        { field: this.inputs.passwordagain.id , method: 'minLength', args: [8], msgArs:[8] },
        { field: this.inputs.passwordagain.id , method: 'checkSame', args: [this.inputs.password.id, this.inputs.passwordagain.id] },
        { field: this.inputs.schoolName.id    , method: 'required' },
        { field: this.inputs.schoolName.id    , method: 'regexCheck', args: [regex("alphaNumericTurkishPlusSpaceDot")]},
        { field: this.inputs.classroom.id     , method: 'required' },
        { field: this.inputs.classroom.id     , method: 'regexCheck', args: [regex("numeric")]},
        { field: this.inputs.cities.id        , method: 'required' },
        { field: this.inputs.cities.id        , method: 'regexCheck', args: [regex("numeric")]},
        { field: this.inputs.locationId.id    , method: 'required' },
        { field: this.inputs.locationId.id    , method: 'regexCheck', args: [regex("numeric")]},
          { field: this.inputs.agree.id    , method: 'required' },
        ]);

      this.state = {
          inputs        : this.inputs,
          cities        : [],
          counties      : [],
          isSubmit      : false,
          validation    : this.validator.valid()
      };

  }

  componentWillMount() {

  }

  componentDidMount() {
    let self = this;

    /* gets city list */
     _xhr.get({
         url: _citiesUrl,
         onsuccess: function ({ data }) {
           self.setState({
             cities  : data
           })
         }
     });
  }

  componentWillUpdate() {

  }

  componentDidUpdate() {

  }

  componentWillReceiveProps(newProps) {

  }

  onCommonChange(e){
    let obj = this.state.inputs;
	  let value = e.target.value;

        if (obj[e.target.name].id === 'phone') {
            let originalValue = e.target.value.replace(/\(/g, "").replace(/\)/g, "");
            if (regex('phone').test(originalValue) && originalValue.length <= 11) {
                    if (originalValue.length >= 2) {
                        originalValue = originalValue.slice(0, 1) + '(' + originalValue.slice(1);
                    }
              if (originalValue.length >= 6) {
                        originalValue = originalValue.slice(0, 5) + ')' + originalValue.slice(5);
                    }
                    value = originalValue;
            }else{
                return false;
            }
      } else if(obj[e.target.name].id === 'birthDate') {
          var dateRegex = /^\d{2}[./-]\d{2}[./-]\d{4}$/;  //dd.mm.yyyy or dd-mm-yyyy
          if(value.length === 10 && dateRegex.test(value)){
            value = value.substr(6, 4) + '-' + value.substr(3, 2) + '-' + value.substr(0, 2);
          }
        }
	  obj[e.target.name].value = value;
    this.setState({
        inputs: obj
    })

  }

  onAgreeChange(event) {
      let obj = this.state.inputs;
      if(event.target.checked) {
          obj[event.target.name].value = true;
      } else {
          obj[event.target.name].value = false;
      }
  }

  onCityChange(selectedCity) {
      let self = this;
      self.state.inputs.cities.value = selectedCity.target.value;
      self.state.inputs.locationId.value = '';
      this.setState({
          inputs: self.state.inputs
      })

      if(selectedCity.target.value !== ''){
          /* gets county list */
         _xhr.get({
             url: _countiesUrl + '?cityId=' + selectedCity.target.value,
             onsuccess: function ({ data }) {
               self.setState({
                 counties  : data
               })
             }
         })
      }else{
          this.setState({
            counties  : {}
          })
      }

  }

  onSubmit(e) {
    e.preventDefault();
    const self = this;

    this.setState({
        isSubmit: true
    })

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    if (validation.isValid) {
        const params = Object.entries(this.state.inputs)
            .reduce((obj, item) => {
                const id    = item[1].id;
                let value;
                if ( id === 'phone' ) {
                  value = item[1].value.replace(/\(/g, "").replace(/\)/g, "");
                }else if ( id === 'birthDate' ){
                  value = item[1].value + "T11:11:11.111Z";
                }else{
                  value = item[1].value;
                }
                obj[id] = value;
                return obj;
            }, {});
        if(!params.agree) {
            swal("",
                "Lütfen kullanıcı sözleşmesini kabul edin.",
                {
                    icon: "error"
                });
            return;
        }
        params.username = params.email;
        params.countyId = parseInt(params.locationId);
        _xhr.post({
            url: _registerUrl,
            params: params,
            onsuccess: function ({ data }) {
              swal("Kullanıcı hesabınız başarıyla oluşturulmuştur.",
              "Lütfen e-posta adresinize gönderilen doğrulama linkine tıklayın. E-postanın iletilmemesi durumunda lütfen spam kutusunu kontrol edin.",
              {
                icon: "success",
                button: "Giriş"
              })
              .then((value) => {
                  self.props.history.push('/login');
              });
            },
            onerror: function ({response}){
              let message = validationMessages("technicalError");
              try{
                if(response.data.code === 1001){
                  message = "Girdiğiniz e-posta adresi sistemde mevcuttur. Lütfen farklı bir e-posta adresi ile tekrar deneyiniz.";
                } else {
                    message = response.data.message;
                }
                  swal(message, {
                      icon: "error"
                  });
              }catch(e){}

              swal(message, {
                icon: "error"
              });
            }
        })

    }
  }

  render() {
    let validation =    this.state.isSubmit ?
                        this.validator.validate(this.state) :
                        this.state.validation
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className={isMobile ? "" : "mx-4"}>
                <CardBody className={isMobile ? "" : "p-4"}>
                  <h1>Kayıt Ol</h1>
                  <p className="text-muted">Yeni kullanıcı oluşturmak için lütfen aşağıdaki bilgileri doldurun.</p>
                  <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend" className="w-30 d-block">
                            <InputGroupText>{this.state.inputs.name.label}</InputGroupText>
                        </InputGroupAddon>
                        <Input  className={ !validation.name.isValid && this.state.isSubmit ? "is-invalid" : "" }
                                value={this.state.inputs.name.value}
                                onChange={this.onCommonChange.bind(this)}
                                id={this.state.inputs.name.id}
                                type="text"
                                name={this.state.inputs.name.id}/>
                        {
                            !validation.name.isValid && this.state.isSubmit &&
                            <FormFeedback>{validation.name.message}</FormFeedback>
                        }
                  </InputGroup>


                  <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend" className="w-30 d-block">
                            <InputGroupText>{this.state.inputs.surname.label}</InputGroupText>
                        </InputGroupAddon>
                        <Input className={!validation.surname.isValid && this.state.isSubmit ? "is-invalid " : "" }
                               value={this.state.inputs.surname.value}
                               onChange={this.onCommonChange.bind(this)}
                               id={this.state.inputs.surname.id}
                               type="text"
                               name={this.state.inputs.surname.id} />
                        {
                            !validation.surname.isValid && this.state.isSubmit &&
                            <FormFeedback>{validation.surname.message}</FormFeedback>
                        }
                  </InputGroup>

                  <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend" className="w-30 d-block">
                            <InputGroupText>{this.state.inputs.birthDate.label}</InputGroupText>
                        </InputGroupAddon>
                        <Input className={!validation.birthDate.isValid && this.state.isSubmit ? "is-invalid " : "" }
                               type="date"
                               date-format="dd.mm.yyyy"
                               id={this.state.inputs.birthDate.id}
                               name={this.state.inputs.birthDate.id}
                               onChange={this.onCommonChange.bind(this)}
                               placeholder="gg.aa.yyyy" />
                         {
                             !validation.birthDate.isValid && this.state.isSubmit &&
                             <FormFeedback>{validation.birthDate.message}</FormFeedback>
                         }
                  </InputGroup>

                  <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend" className="w-30 d-block">
                            <InputGroupText>{this.state.inputs.phone.label}</InputGroupText>
                        </InputGroupAddon>
                        <Input className={!validation.phone.isValid && this.state.isSubmit ? "is-invalid " : "" }
                               value={this.state.inputs.phone.value}
                               onChange={this.onCommonChange.bind(this)}
                               id={this.state.inputs.phone.id}
                               type="text"
                               name={this.state.inputs.phone.id}
                               placeholder={this.state.inputs.phone.placeholder} />
                        {
                            !validation.phone.isValid && this.state.isSubmit &&
                            <FormFeedback>{validation.phone.message}</FormFeedback>
                        }
                  </InputGroup>

                  <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend" className="w-30 d-block">
                          <InputGroupText>{this.state.inputs.email.label}</InputGroupText>
                        </InputGroupAddon>
                        <Input className={!validation.email.isValid && this.state.isSubmit ? "is-invalid" : "" }
                               value={this.state.inputs.email.value}
                               onChange={this.onCommonChange.bind(this)}
                               id={this.state.inputs.email.id}
                               type="email"
                               name={this.state.inputs.email.id}
                               placeholder={this.state.inputs.email.placeholder} />
                        {
    						            !validation.email.isValid && this.state.isSubmit &&
                            <FormFeedback>{validation.email.message}</FormFeedback>
                        }
                  </InputGroup>
                  <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend" className="w-30 d-block">
                            <InputGroupText>{this.state.inputs.schoolName.label}</InputGroupText>
                        </InputGroupAddon>
                        <Input className={!validation.schoolName.isValid && this.state.isSubmit ? "is-invalid" : ""}
                						   value={this.state.inputs.schoolName.value}
                						   onChange={this.onCommonChange.bind(this)}
                						   id={this.state.inputs.schoolName.id}
                						   type="text"
                						   name={this.state.inputs.schoolName.id}  />
                        {
    						            !validation.schoolName.isValid && this.state.isSubmit &&
                            <FormFeedback>{validation.schoolName.message}</FormFeedback>
                        }
                  </InputGroup>

                  <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend" className="w-30 d-block">
                            <InputGroupText>{this.state.inputs.classroom.label}</InputGroupText>
                        </InputGroupAddon>
                        <Input className={!validation.classroom.isValid && this.state.isSubmit ? "is-invalid" : ""}
                               type="select"
                               onChange={this.onCommonChange.bind(this)}
                               name={this.state.inputs.classroom.id}
                               value={this.state.inputs.classroom.value}
                               id={this.state.inputs.classroom.id} >
                                <option value="">Lütfen sınıf seçin.</option>
                            {
                              appConstants.get('classes').map((r , i) => <option key={i} value={r.value}>{r.text}</option>)
                            }
                        </Input>
                        {
                            !validation.classroom.isValid && this.state.isSubmit &&
                            <FormFeedback>{validation.classroom.message}</FormFeedback>
                        }
                  </InputGroup>

                  <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend" className="w-30 d-block">
                            <InputGroupText>{this.state.inputs.cities.label}</InputGroupText>
                        </InputGroupAddon>
                        <Input className={!validation.cities.isValid && this.state.isSubmit ? "is-invalid" : ""}
                							 type="select"
                							 onChange={this.onCityChange.bind(this)}
                							 name={this.state.inputs.cities.id}
                               value={this.state.inputs.cities.value}
                							 id={this.state.inputs.cities.id} >
    						                <option value="">Lütfen şehir seçin.</option>
                            {
    							              this.state.cities.map((r , i) => <option key={i} value={r.id}>{r.name}</option>)
    						            }
                        </Input>
					              {
						                !validation.cities.isValid && this.state.isSubmit &&
                            <FormFeedback>{validation.cities.message}</FormFeedback>
					              }
                  </InputGroup>

                  <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend" className="w-30 d-block">
                            <InputGroupText>{this.state.inputs.locationId.label}</InputGroupText>
                        </InputGroupAddon>
                        <Input className={!validation.locationId.isValid && this.state.isSubmit ? "is-invalid" : ""}
                							 type="select"
                							 onChange={this.onCommonChange.bind(this)}
                							 name={this.state.inputs.locationId.id}
                               value={this.state.inputs.locationId.value}
                							 id={this.state.inputs.locationId.id} >
    						                <option value="">Lütfen ilçe seçin.</option>
                            {
    							              this.state.counties.length > 0 && this.state.counties.map((r , i) => <option key={i} value={r.id}>{r.name}</option>)
    						            }
                        </Input>
					              {
						                !validation.locationId.isValid && this.state.isSubmit &&
                            <FormFeedback>{validation.locationId.message}</FormFeedback>
					              }
                  </InputGroup>

                  <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend" className="w-30 d-block">
                            <InputGroupText>{this.state.inputs.password.label}</InputGroupText>
                        </InputGroupAddon>
                        <Input className={!validation.password.isValid && this.state.isSubmit ? "is-invalid" : ""}
                						   value={this.state.inputs.password.value}
                						   onChange={this.onCommonChange.bind(this)}
                						   id={this.state.inputs.password.id}
                						   type="password"
                						   name={this.state.inputs.password.id}  />
                        {
    						            !validation.password.isValid  && this.state.isSubmit &&
                            <FormFeedback>{validation.password.message}</FormFeedback>
                        }
                  </InputGroup>

                  <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend" className="w-30 d-block">
                            <InputGroupText>{this.state.inputs.passwordagain.label}</InputGroupText>
                        </InputGroupAddon>
                        <Input className={!validation.passwordagain.isValid && this.state.isSubmit ? "is-invalid" : ""}
                						   value={this.state.inputs.passwordagain.value}
                						   onChange={this.onCommonChange.bind(this)}
                						   id={this.state.inputs.passwordagain.id}
                						   type="password"
                						   name={this.state.inputs.passwordagain.id}  />
                        {
    						            !validation.passwordagain.isValid && this.state.isSubmit &&
                            <FormFeedback>{validation.passwordagain.message}</FormFeedback>
                        }
                  </InputGroup>

                <InputGroup className="mb-3 ml-4 pr-4">
                    <Input
                        value={this.state.inputs.agree.value}
                        onChange={this.onAgreeChange.bind(this)}
                        id={this.state.inputs.agree.id}
                        type="checkbox"
                        name={this.state.inputs.agree.id}  /> <a href={'https://elsyayin.com.tr/Sayfa/yasal-uyari.aspx'} target="_blank">Kişisel Verilerin Korunması</a> &nbsp;metnini kabul ediyorum.<br/>
                    {
                        !validation.agree.isValid && this.state.isSubmit &&
                        <FormFeedback>{validation.agree.message}</FormFeedback>
                    }
                </InputGroup>

                <InputGroup className="mb-3 ml-4 pr-4">
                    <Input
                        value={this.state.inputs.isSubscribe.value}
                        onChange={this.onAgreeChange.bind(this)}
                        id={this.state.inputs.isSubscribe.id}
                        type="checkbox"
                        name={this.state.inputs.isSubscribe.id}  /> {this.state.inputs.isSubscribe.label}
                </InputGroup>

                  <Button color="success" onClick={this.onSubmit} block>Kullanıcı Oluştur</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Register;
