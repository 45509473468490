const rest_cfg = {
    //root: 'https://testapi.elsyayin.com/',
    root: 'https://api.elsyayin.com/',
}

const rest = rest_cfg.root + "api/v1/"

const restList = {
    rest: rest,
    base: rest_cfg.root + "now",
    users: rest + 'user',
    register: rest + 'user/signup',
    changepassword: rest + 'user/password',
    reset: rest + 'users/reset',
    resetpassword: rest + 'users/reset-password',
    login: rest + 'user/app-login',
    logout: rest + 'users/logout',
    now: rest + 'Nows/Date',
    quizzes: rest + 'quiz',
    quizattempts: rest + 'quiz-result/all',
    cities: rest + 'location/city',
    counties: rest + 'location/county',
    following: rest + 'following',
    questionattempts: '/QuestionAttempts',
    quizresults: rest + 'quiz-result',
    emailverify: 'verify',
    questioncategories: rest + '/QuestionCategories',
    questionsubjects: rest + '/Subjects',
    home: rest + 'home',
    activeQuizzes: rest + 'quiz/active',
    date: rest + 'date',
    quizslots: rest + 'quiz-slot/questions',
    quizattempt: rest + 'quiz-attempt',
    questionanswers: rest + 'question-answer/all',
    questionattempt: rest + 'question-attempt',
    verify: rest + 'email/verification',
    emailForgot: rest + 'email/forgot',
    quizQuestionAnswers: rest + 'quiz-slot/question-answer',
    quizResultUser: rest + 'quiz-result/follow/all',
    quizResultUserOne: rest + 'quiz-result/follow',
    questionAttemptUser: rest + 'question-attempt/follow',
    totalStatistics: rest + 'statistics/total',
    categoryStatistics: rest + 'statistics/category',
    subjectStatistics: rest + 'statistics/subject'
}

const auth = {
    name: "access_token",
    user: "user"
}

const checkdetails = {
    emailverify: "emailverify"
}

export default function (nodeName) {
    if (nodeName === "auth") {
        return auth
    } else if (nodeName === "checkdetails") {
        return checkdetails
    } else {
        return restList[nodeName]
    }
};
