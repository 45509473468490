/**
 * @AUTHOR Recep Ozcelik
 * @DATE 03.03.2019
 */
const constants = {
    classes: [
        { value: '9'  , text: '9.Sınıf'  },
        { value: '10' , text: '10.Sınıf' },
        { value: '11' , text: '11.Sınıf' },
        { value: '12' , text: '12.Sınıf' },
        { value: '99' , text: 'Öğretmen' },
        { value: '98' , text: 'Diğer'}
      ],
}

let getClassText = function(classValue) {
    let classItem = constants.classes.filter(function(classRow){
        return classRow.value === classValue;
    });
    return (classItem && classItem[0]) ? classItem[0].text : "";
}

let getConstant = function(constName){
    return constants[constName];
}

export default {
    'get' : getConstant,
    'getClassText' : getClassText,
};