import React, { Component } from 'react';
import {
  Alert,
  Col,
  Card,
  CardBody,
  CardGroup,
  CardHeader,
  Row
} from 'reactstrap';

import HomeWidget01 from './HomeWidget01';

import cookie from '../../utils/cookie';
import _xhr from '../../utils/_xhr';
import rest from '../../utils/rest.cfg';

const _quizResultUrl =rest('home');
const auth = rest('auth');

class Home extends Component {
  constructor(props) {
      super(props);

      this.state = {
        examsCompleted: 0,
        questionsSolved: 0,
        correctPercentage: "%0",
        averageTime: "00:00:00"
      };
  }

  componentWillMount() {

  }

  componentDidMount() {
    let self = this;
    if(cookie.get(auth.token)) {
      _xhr.get({
          url: _quizResultUrl,
          onsuccess: function (response) {
            let percent = response.data.correctAnswersRatio ? ( "%" + response.data.correctAnswersRatio) : '';
            let complated = response.data.completedQuiz ? response.data.completedQuiz : '';
            let solved = response.data.completedQuestions ? response.data.completedQuestions : '';
            let averageTime = response.data.timeSpent ? ((new Date(response.data.timeSpent)).toUTCString().match(/(\d\d:\d\d:\d\d)/)[0]) : '';
            self.setState({
              examsCompleted: complated,
              questionsSolved: solved,
              correctPercentage: percent,
              averageTime: averageTime
            })
          }
      });
    } else {
      self.props.history.push('/login');
    }

  }

  componentWillUpdate() {

  }

  componentDidUpdate() {

  }

  componentWillReceiveProps(newProps) {

  }

  msToTime(duration) {
    let seconds = parseInt((duration / 1000) % 60, 10),
        minutes = parseInt((duration / (1000 * 60)) % 60, 10),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24, 10);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  }

  render() {
    return (
      <div>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <i className="fa fa-commenting fa-lg"></i><strong>Duyurular</strong>
              </CardHeader>
              <CardBody>
                <Alert color="success">
                  <h4 className="alert-heading">Hoş Geldiniz!</h4>
                  <p>
                    Soldaki menüden <b>Sınavlar</b>’a tıklayarak yeni bir sınava başlayabilir, <b>Sonuçlar</b>’a tıklayarak da geçmiş sınavlardaki sonuçlarınızı görebilirsiniz.
                    <br/>
                    Herhangi bir sorun yaşamanız halinde <b>els@elsyayin.com.tr</b> adresinden bize ulaşabilirsiniz.
                  </p>
                  <hr />
                  <p className="mb-0">
                    İyi çalışmalar dileriz...
                  </p>
                </Alert>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CardGroup className="mb-4">
          <HomeWidget01 icon="fa fa-newspaper-o" color="info" header={this.state.examsCompleted.toString()} invert>Tamamlanan Sınav Sayısı</HomeWidget01>
          <HomeWidget01 icon="fa fa-question-circle" color="success" header={this.state.questionsSolved.toString()} invert>Çözülen Soru Sayısı</HomeWidget01>
          <HomeWidget01 icon="fa fa-pie-chart" color="danger" header={this.state.correctPercentage.toString()} invert>Doğru Cevap Oranı</HomeWidget01>
          <HomeWidget01 icon="fa fa-hourglass-half" color="warning" header={this.state.averageTime.toString()} invert>Ortalama Zaman</HomeWidget01>
        </CardGroup>

      </div>
    );
  }
}

export default Home;
