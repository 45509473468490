import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, ListGroup, ListGroupItem, Button } from 'reactstrap';

const propTypes = {
  header: PropTypes.string,
  timeLimit: PropTypes.number,
  timeOpen: PropTypes.string,
  timeClose: PropTypes.string,
  visibleButton: PropTypes.bool,
  buttonText: PropTypes.string,
  questionCount: PropTypes.number,
  onClick: PropTypes.func
};

const defaultProps = {
  visibleButton: true,
  buttonText: ""
};

class ExamInfo extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  render() {
    const { header, timeLimit, timeOpen, timeClose, visibleButton, buttonText, questionCount} = this.props;
    return (
        <Card>
          <CardHeader>
            {header}
          </CardHeader>
          <CardBody>
            <ListGroup className="examInfoList">
              <ListGroupItem action color="">
                Bu sınav {questionCount} sorudan oluşmaktadır. Cevaplama süresi {timeLimit} dakikadır.
                Sınav başladığında aşağıda çıkacak olan “Sınava Başla” butonuna tıklamanızla birlikte süreniz
                işlemeye başlayacak ve {timeLimit} dakikanız dolduğunda sistem kendiliğinden sınavınızı sonlandıracaktır.
              </ListGroupItem>
              {timeOpen !== 'Invalid date' && <ListGroupItem action color="">
                Sınav, {timeOpen} ile {timeClose} arasında erişime açık olacaktır. Sınavınızı bu süre içinde tamamlamanız gerekmektedir.
              </ListGroupItem>}
              
              <ListGroupItem action color="">
                Bu sınavda yer alan her sorunun sadece bir doğru cevabı vardır. Birden fazla cevap işaretlemenize sistem izin vermeyecektir.
              </ListGroupItem>
              <ListGroupItem action color="">
                Bu test puanlanırken doğru cevaplarınızın sayısından yanlış cevaplarınızın dörtte biri çıkarılacak ve kalan sayı net doğru cevap sayınız olacaktır.
              </ListGroupItem>
              <ListGroupItem action color="">
                Cevaplamaya istediğiniz sorudan başlayabilirsiniz. Ekranda her zaman tek bir soru gösterilecektir. Soru ekranında göreceğiniz ileri/geri oklarını ve ekranın sağ tarafında göreceğiniz soru listesini kullanarak istediğiniz soruyu görüntüleyebilirsiniz.
              </ListGroupItem>
              {timeClose !== 'Invalid date' &&
                <ListGroupItem action color="">
                Tüm sonuçlar {timeClose}'da sınav süresinin dolmasından sonra açıklanacaktır.
                Sisteme giriş yapıp sonuç sayfasından sonuçlarınızı görebilirsiniz.
              </ListGroupItem>
              }
              <ListGroupItem action color="">
                Bu deneme sınavının amacı, artılarınızı, eksilerinizi ve tüm ELS kullanıcıları arasındaki sıralamanızı
                görmektir. Kitap, sözlük veya internet gibi sınavda kullanımı yasak olan kaynaklardan yardım almanız
                sonuçlarınızın gerçekten uzak olmasına neden olur ve sizi yanıltır.
                Lütfen soruları hiçbir dış kaynak kullanmadan kendi bilgilerinizle cevaplayınız.
              </ListGroupItem>
            </ListGroup>

            {
                visibleButton &&
                <Button onClick={this.onClick.bind(this)} color="primary font-weight-bold pull-right mt-3">{buttonText}</Button>
            }

          </CardBody>
        </Card>
    );
  }
}

ExamInfo.propTypes = propTypes;
ExamInfo.defaultProps = defaultProps;

export default ExamInfo;
