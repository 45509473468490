import React, { Component } from 'react';
import {
  Progress,
  ListGroup,
  ListGroupItem,
  Button
} from 'reactstrap';
import PropTypes from 'prop-types';

const propTypes = {
  onClick: PropTypes.func,
  totalTime: PropTypes.object,
  remainTime: PropTypes.object,
  totalPercentage: PropTypes.number,
  linkText: PropTypes.string,
  resultInfo: PropTypes.bool
};

const defaultProps = {};

class QuestionsAside extends Component {

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {

    // eslint-disable-next-line
    const { onClick, totalTime, remainTime, totalPercentage, linkText, resultInfo } = this.props;
    if(remainTime.hour.toString().length === 1){
      remainTime.hour = "0"+remainTime.hour;
    }
    if(remainTime.min.toString().length === 1){
      remainTime.min = "0"+remainTime.min;
    }
    if(remainTime.sec.toString().length === 1){
      remainTime.sec = "0"+remainTime.sec;
    }
    if(totalTime.hourTT.toString().length === 1){
      totalTime.hourTT = "0"+totalTime.hourTT;
    }
    if(totalTime.minTT.toString().length === 1){
      totalTime.minTT = "0"+totalTime.minTT;
    }
    if(totalTime.secTT.toString().length === 1){
      totalTime.secTT = "0"+totalTime.secTT;
    }
    let header = "Kalan Zaman";
    if(resultInfo){
      header = "Toplam Zaman";
    }
    return (
      <React.Fragment>
        <ListGroup className="list-group-accent">
          <ListGroupItem className="list-group-item-accent text-center font-weight-bold text-muted small">
            <div className="text-uppercase mb-1 mt-2">
              <b>{header}</b>
            </div>
            <Progress className="progress-xs" color="danger" value={totalPercentage} />
            <b>{remainTime.hour}:{remainTime.min}:{remainTime.sec} / {totalTime.hourTT}:{totalTime.minTT}:{totalTime.secTT}</b>
            <Button onClick={this.onClick.bind(this)} block color="link" className="pt-0 pb-0">{linkText}&nbsp;<i className="icon-cursor icons"></i></Button>
          </ListGroupItem>
        </ListGroup>
      </React.Fragment>
    );
  }
}

QuestionsAside.propTypes = propTypes;
QuestionsAside.defaultProps = defaultProps;

export default QuestionsAside;
