import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';

class MesafeliSatis extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      name: query.get('name') || '(MÜŞTERİ İSMİ)',
      address: query.get('address') || '(MÜŞTERİ ADRESİ)',
      phone: query.get('phone') || '(MÜŞTERİ TELEFON)',
      email: query.get('email') || '(MÜŞTERİ EMAİL)',
      price: query.get('price') || '(TOPLAM TUTAR)'
    }
    this.setState({

    })
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <b>MESAFELİ SATIŞ SÖZLEŞMESİ</b>
            </CardHeader>
            <CardBody>
              <div className="page-content">
                <p>
                  <strong>MADDE 1- TARAFLAR</strong>
                  <br/>
                  <strong>1.1- SATICI</strong>
                  <br/>
                  <br/>
                  Adres : Sarayardı Cad., No: 94/A, Hasanpaşa/Kadıköy/İSTANBUL
                  <br/>
                  Ünvanı : ELS Yayıncılık Ltd. Şti
                  <br/>
                  Telefon : (216) 349 18 24 / (216) 345 0247
                  <br/>
                  Fax : (216) 349 18 25
                  <br/>
                  E-mail : els@elsyayin.com.tr
                </p>
                <p>
                  <strong>1.2 -ALICI</strong>
                </p>
                <p>
                  Adı/soyadı/Ünvanı : {this.state.name}
                  <br/>
                  Adresi : {this.state.address}
                  <br/>
                  Telefon : {this.state.phone}
                  <br/>
                  E-mail : {this.state.email}
                </p>
                <p>
                  <strong>MADDE 2- KONU</strong>
                </p>
                <p>
                  İşbu mesafeli satış sözleşmesinin konusu, ALICI'nın SATICI'ya ait
                  www.elsyayin.com alan adlı internet sitesinden elektronik ortamda
                  siparişini yaptığı aşağıda nitelikleri ve satış fiyatı belirtilen ürünün
                  satışı ve teslimi ile ilgili olarak 6502 sayılı Tüketicinin Korunması
                  Hakkındaki Kanun gereğince tarafların hak ve yükümlülüklerinin
                  saptanmasıdır.
                </p>
                <p>
                  <strong>MADDE 3- SÖZLEŞME KONUSU ÜRÜN</strong>
                </p>
                <p>
                  <strong>Ürün Adı Adet Birim fiyat Toplam Tutar</strong>
                </p>
                <p>
                  (Ürün Adı) (Adet) (Birim fiyat) (Toplam Tutar)
                </p>
                <p>
                  <br/>
                  Ürünlerin Cinsi ve türü, Miktarı, Marka/Modeli, Rengi, Satış Bedeli
                  yukarıda belirtildiği gibi olup, bu bilgiler ALICI tarafından da
                  onaylanmıştır.
                  <br/>
                  Ödeme Şekli : Kredi Kartı
                  <br/>
                  Toplam Sipariş Tutarı: {this.state.price} TL
                  <br/>
                  Fatura Adresi : {this.state.address}
                  <br/>
                  Kargo Ücreti : 0,00 TL
                </p>
                <p>
                  Teslimat, kargo firması aracılığı ile SATICI tarafından yukarıda belirtilen
                  adrese yapılacaktır. Teslimat, anlaşmalı kargo firması aracılığı ile
                  ALICI’nın yukarıda belirtilen adresine yapılacaktır. Şu kadar ki, SATICI,
                  satış anında yürüttüğü ve/veya    <a href="http://www.elsyayin.com">www.elsyayin.com</a><u> </u>alan adlı
                  internet sitesinde şartlarını ilan ettiği kampanyaların sonucuna bağlı
                  olarak söz konusu kargo ücretinin tamamını ya da bir kısmını ALICI'ya
                  yansıtmayabilir.
                </p>
                <p>
                  <strong>MADDE 4- GENEL HÜKÜMLER</strong>
                </p>
                <p>
                  4.1- ALICI, SATICI 'ya ait    <a href="https://www.elsyayin.com.tr/" target="_BLANK">www.elsyayin.com.tr</a>
                  alan adlı internet sitesinde satışa arz edilen sözleşme konusu
                  ürünün(lerin)    <a href="https://www.elsyayin.com.tr/" target="_BLANK">www.elsyayin.com.tr</a>
                  alan adlı internet sitesinde belirtilen ve yukarıda yer alan temel
                  nitelikleri, satış fiyatı, fiyatların geçerlilik süresi ve ödeme şekli ile
                  teslimata ilişkin ön bilgileri ve diğer bilgileri okuyup doğru ve eksiksiz
                  şekilde bilgi sahibi olduğunu ve elektronik ortamda satın almaya ilişkin
                  gerekli teyidi verdiğini kabul ve beyan eder.
                  <br/>
                  4.2- Sözleşme konusu ürün/ürünler, yasa ve yönetmeliklerle belirlenen
                  siparişin SATICI’ya ulaşmasını takip eden, yasal 30 günlük süreyi aşmamak
                  koşulu ile her bir ürün için ALICI'nın yerleşim yerinin uzaklığına bağlı
                  olarak işbu 30 (otuz) günlük süre içinde ALICI veya gösterdiği adresteki
                  kişi/kuruluşa teslim edilir. Şu kadar ki, ön sipariş ürününde(lerinde) işbu
                  30 günlük yasal süre ilgili ürüne ait satış sayfasında ilan edilen satış
                  tarihinden itibaren başlayacak olup, işbu satış tarihinde tedarikçi firma
                  kaynaklı gecikmeler yaşanabilir. İşbu halde SATICI ALICI’yı öncesinde
                  yazılı olarak bilgilendirecektir. Bu halde de ALICI siparişin iptal
                  edilmesini veya teslimat süresini bekleyerek siparişe konu ürün(lerin)
                  teslim edilmesi haklarından birini kullanabilir. ALICI'nın siparişi iptal
                  etmesi halinde ödediği tutar 14 ( on dört ) gün içinde kendisine nakden ve
                  defaten ödenir.
                  <br/>
                  4.3- Mesafeli satış sözleşmesine konu ürün(ler), ALICI'dan başka bir
                  kişi/kuruluşa teslim edilecek ise, teslim edilecek kişi/kuruluşun teslimatı
                  kabul etmemesinden SATICI sorumlu tutulamaz.
                  <br/>
                  4.4- SATICI, mesafeli satış sözleşmesine konu ürünün(lerin) sağlam,
                  eksiksiz, siparişte belirtilen niteliklere uygun teslim edilmesi anına
                  kadar tüm sorumluluk SATICI’ya aittir. ALICI, ürünü(leri) teslim aldığı
                  anda kontrol etmekle ve üründe(lerde) kargodan kaynaklanan bir sorun
                  gördüğünde, ürünü(leri) kabul etmemekle ve kargo firması yetkilisine
                  tutanak tutturmakla sorumludur.
                  <br/>
                  4.5- Mesafeli satış sözleşmesine konu ürünün(lerin) teslimatı için işbu
                  mesafeli satış sözleşmesinin elektronik ortamda teyit edilmiş olması ve
                  ürün(lerin) bedelinin ALICI'nın tercih ettiği ödeme şekli ile ödenmiş
                  olması şarttır. Herhangi bir nedenle ürün bedeli ödenmez veya banka
                  kayıtlarında iptal edilir ise, SATICI ürünün teslimi yükümlülüğünden
                  kurtulmuş kabul edilir.
                  <br/>
                  4.6- Mesafeli satış sözleşmesine konu ürüne(lere) ilişkin ödemenin ALICI
                  tarafından kredi kartı ile yapılması durumunda, ALICI ile kredi kartı
                  sahibinin ya da ürünün teslim edileceği kişinin farklı olmasından
                  kaynaklanabilecek olan, kredi kartının yetkisiz kişilerce haksız ve hukuka
                  aykırı olarak kullanılması da dahil olmak üzere türlü hukuki risk, ALICI’ya
                  aittir. ALICI, bahsi geçen durumlarda herhangi bir şekilde zarara uğraması
                  halinde SATICI’dan hiçbir talepte bulunmayacağını kabul ve taahhüt eder.
                  <br/>
                  4.7- ALICI tarafından, mesafeli satış sözleşmesine konu ürüne(lere) ilişkin
                  ödemenin herhangi bir Banka’ya ait ATM cihazından ya da şubesinden “hesaba
                  yatırma” şeklinde yapılması durumunda, ürün bedelinin işbu sözleşmede
                  belirtilen şartlar çerçevesinde ALICI’ya iade edilmesinin gerektiği
                  hallerde kullanılmak üzere kendisine ait bir banka hesabına ait IBAN
                  numarasını doğru ve eksiksiz bir şekilde SATICI’nın <u>els@elsyayin.com.tr</u> mail adresine göndermeyi ve SATICI tarafından IBAN
                  numarasına iade yapılmasına muvafakat ettiğini kabul ve taahhüt eder.
                  <br/>
                  4.8- SATICI mücbir sebepler veya nakliyeyi engelleyen hava muhalefeti,
                  ulaşımın kesilmesi gibi olağanüstü durumlar ve/veya teknik sebepler vs.
                  nedenler ile mesafeli satış sözleşmesine konu ürünü(leri) süresi içinde
                  teslim edemez ise, durumu ALICI'ya 3 ( üç ) gün içinde bildirmekle
                  yükümlüdür. Bu takdirde ALICI siparişin iptal edilmesini, mesafeli satış
                  sözleşmesine konu ürünün(lerin) varsa emsali ile değiştirilmesini, ve/veya
                  teslimat süresinin engelleyici durumun ortadan kalkmasına kadar ertelenmesi
                  haklarından birini kullanabilir. ALICI'nın siparişi iptal etmesi halinde
                  ödediği tutar 14 (on dört)gün içinde kendisine nakten ve defaten ödenir.
                  <br/>
                  4.9- ALICI talep ve şikâyetlerini yukarıdaki SATICI adres, telefon, faks ve
                  e-posta adreslerine iletebilir ve ürünlerin arızalı veya bozuk olanlarının,
                  gerekli değişimin yapılması için de SATICI'ya ait adrese kargo bedeli
                  ödemeksizin gönderimde bulunabilir.
                  <br/>
                  4.10- ALICI, Türkiye Cumhuriyeti sınırları dışında ikamet
                  etmekte/bulunmakta ve/veya ürünün teslim edileceği adres Türkiye
                  Cumhuriyeti sınırları dışında bulunmakta ise, ikamet ettiği/bulunduğu
                  ve/veya ürünün teslim edileceği ülkenin söz konusu ürünün alımı nedeniyle
                  yasa, yönetmelik ve ilgili yasal düzenlemeleri uyarınca tahakkuk ettireceği
                  gümrük vergisi, harç ve sair her türlü mali yükümlülüğün kendisine ait
                  olacağını, ürünün teslim edilebilmesi için talep olunacak tüm ödemeleri
                  derhal ve aynen yerine getireceğini şimdiden kabul, beyan ve taahhüt eder.
                  Ayrıca ALICI’nın adresi SATICI’nın anlaşmalı olduğu kargo firmasının
                  dağıtım alanının dışında kalmakta ise, ALICI dilerse ürünün teslim
                  edilebilmesi için talep olunacak tüm masraf ve bedelleri ödemeyi veya
                  SATICI’nın anlaşmalı olduğu kargo firmasının ilgili şubesinden satışa konu
                  olan ürün/ürünleri iş bu mesafeli satış sözleşmesinde yer alan
                  alıcı/alıcılar tarafından bizzat teslim alınacağını şimdiden kabul, beyan
                  ve taahhüt eder. İşbu durum hakkında ALICI SATICI’nın anlaşmalı olduğu
                  kargo firması tarafından öncesinde bilgilendirilecek olup, ALICI dilerse
                  sözleşmeden dönebilir.
                  <br/>
                  4.13- ALICI tarafından satın alınan ürün/ürünlerin tamamı, bir kısmı
                  ve/veya üründen elde edilen herhangi bir bilgi, yazılım veya hizmet
                  değiştirilemez, kopyalanamaz, dağıtılamaz, çoğaltılamaz, yayınlanamaz,
                  türev niteliğinde çalışmalara konu edilemez, aktarılamaz veya satılamaz.
                  ALICI işbu sözleşme ile satın aldığı ürünü yasa dışı amaçlar için ve/veya
                  bu yasaklanan şekillerde kullanmayacağını kabul ve taahhüt eder. Aksi halde
                  doğabilecek tüm hukuki ve cezai sorumluluk ALICI’ya ait olmakla beraber
                  üçüncü kişiler veya yetkili merciler tarafından SATICI’ya karşı ileri
                  sürülebilecek tüm iddia ve taleplere karşı, SATICI’nın söz konusu izinsiz
                  kullanımdan kaynaklanan her türlü tazminat ve sair talep hakkı saklıdır.
                  <br/>
                  4.14- ALICI ve SATICI işbu mesafeli satış sözleşmesinin başında bahsedilen
                  yazışma adreslerinin geçerli tebligat adresi olduğunu ve bu adrese
                  yöneltilecek tüm tebligatların geçerli addolunacağını kabul, beyan ve
                  taahhüt eder.
                  <br/>
                  4.15- SATICI sipariş vermek için kullanılan www.elsyayin.com alan adlı
                  internet sitesinin kullanılması nedeniyle, ücret tarifesi ile ilgili olarak
                  ALICI’ya ilave bir maliyet yüklememektedir.
                </p>
                <p>
                  <strong>MADDE 5- CAYMA HAKKI</strong>
                </p>
                <p>
                  ALICI, işbu mesafeli satış sözleşmesinin 6. Maddesinde belirtilen
                  istisnalar hariç olmak üzere mesafeli satış sözleşmesine konu ürünün(lerin)
                  kendisine veya gösterdiği adresteki kişi/kuruluşa tesliminden veya
                  sözleşmenin imzalandığı tarihten itibaren 14 gün içinde herhangi bir
                  gerekçe göstermeksizin ve cezai şart ödemeksizin sözleşmeden cayma hakkına
                  sahiptir. İşbu 14 (on dört ) günlük süre, malın teslimine ilişkin
                  sözleşmelerde, tüketicinin malı teslim aldığı günden itibaren, diğer
                  sözleşmelerde ise sözleşmenin akdedildiği günden itibaren işlemeye başlar.
                  Cayma hakkının kullanılması için bu süre içinde ürünü(leri) işleyişine,
                  teknik özelliklerine ve kullanım talimatlarına uygun bir şekilde kullanmış
                  olmak kaydıyla SATICI'ya aşağıda detayları belirtilen iletişim bilgileri
                  vasıtasıyla ALICI yazılı olarak bildirimde bulunmak zorundadır. Bu
                  bildirimin SATICI’ya ulaşmasını takiben SATICI bildirimin kendisine
                  ulaştığına ilişkin teyit bilgisini ALICI ile paylaşacak ve bildirimin
                  ulaşma tarihini takiben de 14 (ondört) gün içinde SATICI cayma hakkına konu
                  ürünün(lerin) bedelini ödeme şekline uygun bir şekilde ALICI’ya iade
                  edecektir. SATICI’nın yukarıda belirtilen yükümlülüklerini yerine
                  getirmemesi hâlinde, ALICI cayma hakkını kullanmak için 14 (on dört) günlük
                  süreye bağlı değildir. Her halükarda bu süre cayma süresinin bittiği
                  tarihten itibaren bir yıl sonra sona erer. Bu hakkın kullanılması halinde,
                  gösterdiği adresteki kişi/kuruluşa veya ALICI'ya teslim edilen
                  ürünün(lerin) kargo firması aracılığı ile vergi mevzuatı gereğince fatura
                  aslının da ibrazı ile ALICI tarafından SATICI’ya iadesi gerekmektedir.
                  Yurtdışı teslimatlı siparişlerde cayma hakkı kullanılması durumunda kargo
                  gönderi ücreti müşteriye ait olacaktır.
                  <br/>
                  Adres: ELS Yayıncılık Ltd. Şti
                  <br/>
                  Sarayardı Cad., No: 94/A, Hasanpaşa/Kadıköy/İSTANBUL
                  <br/>
                  Fax : (0216) 349 18 25
                  <br/>
                  E-mail : els@elsyayin.com.tr
                </p>
                <p>
                  <strong>MADDE 6- CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER</strong>
                </p>
                <p>
                  a) Fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak değişen ve
                  satıcı veya sağlayıcının kontrolünde olmayan mal veya hizmetlere ilişkin
                  sözleşmeler.
                  <br/>
                  b) Tüketicinin istekleri veya kişisel ihtiyaçları doğrultusunda hazırlanan
                  mallara ilişkin sözleşmeler.
                  <br/>
                  c) Çabuk bozulabilen veya son kullanma tarihi geçebilecek malların
                  teslimine ilişkin sözleşmeler.
                  <br/>
                  ç) Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları
                  açılmış olan mallardan; iadesi sağlık ve hijyen açısından uygun
                  olmayanların teslimine ilişkin sözleşmeler.
                  <br/>
                  d) Tesliminden sonra başka ürünlerle karışan ve doğası gereği
                  ayrıştırılması mümkün olmayan mallara ilişkin sözleşmeler.
                  <br/>
                  e) Malın tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu
                  unsurları açılmış olması halinde maddi ortamda sunulan kitap, dergi,
                  dijital içerik ve bilgisayar sarf malzemelerine ilişkin sözleşmeler.
                  <br/>
                  f) Abonelik sözleşmesi kapsamında sağlananlar dışında, gazete ve dergi gibi
                  süreli yayınların teslimine ilişkin sözleşmeler.
                  <br/>
                  g) Belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya
                  taşıma, araba kiralama, yiyecek-içecek tedariki ve eğlence veya dinlenme
                  amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmeler.
                  <br/>
                  ğ) Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında
                  teslim edilen gayrimaddi mallara ilişkin sözleşmeler.
                  <br/>
                  h) Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile ifasına
                  başlanan hizmetlere ilişkin sözleşmeler.
                </p>
                <p>
                  <strong>MADDE 7- GENEL HÜKÜMLER</strong>
                </p>
                <p>
                  18 yaşından küçük kişiler ile ayırt etme gücünden yoksun veya kısıtlı
                  erginler SATICI'dan alış-veriş yapamaz.
                </p>
                <p>
                  <strong>MADDE 8- YETKİLİ MAHKEME</strong>
                </p>
                <p>
                  İşbu mesafeli satış sözleşmesinin uygulanmasından doğacak uyuşmazlıklarda
                  Gümrük ve Ticaret Bakanlığı’nca her yıl Aralık ayında ilan edilen parasal
                  sınırlar dahilinde ALICI’nın mal veya hizmeti satın aldığı veya
                  ikametgahının bulunduğu yerdeki Tüketici Sorunları Hakem Heyeti veya
                  Tüketici Mahkemeleri yetkilidir.
                </p>
                <p>
                  İşbu mesafeli satış sözleşmesi elektronik ortamda taraflarca okunup, kabul
                  edilip, teyit edilmiştir. İşbu mesafeli satış sözleşmesinde düzenlenmemiş
                  hususlarda 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve ilgili
                  mevzuat hükümleri uygulanır.
                </p>
                <p>
                  <strong>SATICI</strong>
                  <br/>
                  ELS Yayıncılık Ltd. Şti
                  <br/>
                  <br/>
                  <strong>ALICI</strong>
                  <br/>
                  (MÜŞTERİ İSMİ)
                </p>
                <p>
                  Tarih : (TARİH)
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

    );
  }
}

export default MesafeliSatis;
