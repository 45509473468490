import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.css';

// Styles
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'
// import '../node_modules/@coreui/styles/scss/_dropdown-menu-right.scss';

// Containers
import { MainLayout } from './components/Layout';
// Pages
import {
  Login,
  Register,
  ResetPassword,
  ChangePassword,
} from './views';

// import { renderRoutes } from 'react-router-config';

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/register" name="Register Page" component={Register} />
          <Route exact path="/resetpassword" component={ResetPassword}/>
          <Route exact path="/changepassword" component={ChangePassword}/>
          <Route path="/" name="MainLayout" component={MainLayout} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
