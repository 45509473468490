import React, {Component} from 'react';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';

class TeslimatIade extends Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <b>TESLİMAT VE İADE BİLGİLERİ</b>
            </CardHeader>
            <CardBody>
              <div className="page-content">
                <p>
                  ELS Yayıncılık Limited Şirketi (“ELS”) ürünlerinin iade işlemlerinde 6502
                  sayılı Tüketicinin Koruması Hakkında Kanunu hükümleri uygulanmaktadır.
                </p>
                <p>
                  <strong>Cayma hakkı kapsamında ürün iadesi ve koşulları:</strong>
                </p>
                <p>
                  ELS’ten satın aldığınız ürünleri (cayma hakkı kullanılamayacak ürünler
                  hariç olarak) teslim veya sözleşmenin imzalandığı tarihinden itibaren 14
                  (on dört) gün içerisinde (ürün hasarlı olmasa dahi) hiçbir gerekçe
                  belirtmeksizin iade edebilirsiniz.
                </p>
                <p>
                  İade için, satın aldığınız ürünün işleyişine, teknik özelliklerine ve
                  kullanım talimatlarına uygun bir şekilde kullanmış olması tahrip edilmemiş
                  ve bozulmamış olması ve cayma hakkına dair yazılı bildirimde bulunulması
                  gerekmektedir.
                </p>
                <p>
                  Bu bildirimin ELS’e ulaşmasını takiben bildirimin ulaştığına ilişkin teyit
                  bilgisi tarafınızla paylaşılacak ve bildirimin ulaşma tarihini takiben de
                  14 (ondört) gün içinde cayma hakkına konu ürünün/ürünlerin bedeli ödeme
                  şekline uygun bir şekilde tarafınıza iade edilecektir.
                </p>
                <p>
                  İade edilecek ürünlerin mutlaka ürün faturasının orijinali ile birlikte
                  teslimi gerekmektedir. Cayma hakkının kullanılması halinde, fatura aslının
                  iadesi zorunludur. Ancak, fatura aslının gönderilmemesi halinde KDV ve
                  varsa sair yasal kesintiler iade edilemeyecektir.
                </p>
                <p>
                  <strong>Cayma hakkı kapsamında iadesi mümkün olmayan ürünler:</strong>
                </p>
                <p>
                  Tüketici mevzuatı hükümleri doğrultusunda, aşağıda sayılan ürünlerin cayma
                  hakkı kapsamında iadesi mümkün değildir:
                </p>
                <ul>
                  <li>
                    Fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak değişen ve
                    satıcı veya sağlayıcının kontrolünde olmayan mal veya hizmetlere
                    ilişkin sözleşmeler.
                  </li>
                  <li>
                    Tüketicinin istekleri veya kişisel ihtiyaçları doğrultusunda hazırlanan
                    mallara ilişkin sözleşmeler.
                  </li>
                  <li>
                    Çabuk bozulabilen veya son kullanma tarihi geçebilecek malların
                    teslimine ilişkin sözleşmeler.
                  </li>
                  <li>
                    Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları
                    açılmış olan mallardan; iadesi sağlık ve hijyen açısından uygun
                    olmayanların teslimine ilişkin sözleşmeler.
                  </li>
                  <li>
                    Tesliminden sonra başka ürünlerle karışan ve doğası gereği
                    ayrıştırılması mümkün olmayan mallara ilişkin sözleşmeler.
                  </li>
                  <li>
                    <u>
                      Malın tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu
                      unsurları açılmış olması halinde maddi ortamda sunulan kitap,
                      dergi, dijital içerik ve bilgisayar sarf malzemelerine ilişkin
                      sözleşmeler.
                    </u>
                  </li>
                  <li>
                    Abonelik sözleşmesi kapsamında sağlananlar dışında, gazete ve dergi
                    gibi süreli yayınların teslimine ilişkin sözleşmeler.
                  </li>
                  <li>
                    Belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya
                    taşıma, araba kiralama, yiyecek-içecek tedariki ve eğlence veya
                    dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin
                    sözleşmeler.
                  </li>
                  <li>
                    <u>
                      Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye
                      anında teslim edilen gayrimaddi mallara ilişkin sözleşmeler.
                    </u>
                  </li>
                  <li>
                    Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile ifasına
                    başlanan hizmetlere ilişkin sözleşmeler.
                  </li>
                </ul>
                <p>
                  <strong><u>ÖNEMLİ UYARI:</u></strong>
                  SİTEMİZDEN ŞU ANDA SATIŞA SUNULMAKTA OLAN ÜRÜNLERİN TAMAMI CAYMA HAKKININ
                  İSTİSNALARI ARASINDA BULUNAN KİTAP, DERGİ VE ONLİNE/DİJİTAL ÜRÜNLER
                  OLDUĞUNDAN, SÖZ KONUSU ÜRÜNLERİN CAYMA HAKKI KAPSAMINDA İADESİ MEVCUT
                  TÜKETİCİ MEVZUATI HÜKÜMLERİ DOĞRULTUSUNDA MÜMKÜN DEĞİLDİR.
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

    );
  }
}

export default TeslimatIade;
