/**
 * @AUTHOR Onur KESKİN
 * @DATE 01.01.2019
 */
import validationMessages from './validationMessages';
import validationRoutines from './validationRoutines';

class FormValidator {
  constructor(validations) {
    this.validations = validations;
  }

  validate(state) {
    let validation = this.valid();

    this.validations.forEach(rule => {

      if (validation[rule.field].isValid) {

        const field_value = state.inputs[rule.field].value;
        const args = rule.args || [];
        const msgArs = rule.msgArs || [];
        
        if(!validationRoutines(rule.method, field_value, state, ...args)) {
          validation[rule.field] = { isValid: false, message: validationMessages(rule.method, state, ...msgArs) }
          validation.isValid = false;
        }
      }
    });

    return validation;
  }

  valid() {
    const validation = {}

    this.validations.map(rule => (
      validation[rule.field] = { isValid: true, message: '' }
    ));

    return { isValid: true, ...validation };
  }
}

export default FormValidator;
