import Konva from "konva";
export const addHighLight = (stage, layer, mode = "brush") => {
    let isHighlighting = false;
    let lastLine;
    stage.on("mousedown touchstart", function(e) {
        isHighlighting = true;
        let pos = stage.getPointerPosition();
        lastLine = new Konva.Line({
            stroke:  mode == "pink" ? "rgba(255, 99, 249, .3)" : mode == "green" ? "rgba(113, 255, 105, .3)" : mode == "yellow" ? "rgba(255, 255, 0, .3)" : mode == "brush" ? "rgba(255, 255, 0, .3)" : "white",
            strokeWidth: mode == "brush" || mode == "yellow" || mode == "green" || mode == "pink" ? 15 : 25,
            globalCompositeOperation:
                mode == "brush" || mode == "yellow" || mode == "green" || mode == "pink" ? "source-over" : "destination-out",
            points: [pos.x, pos.y],
            draggable: mode == "brush" || mode == "yellow" || mode == "green" || mode == "pink",
        });
        layer.add(lastLine);
    });
    stage.on("mouseup touchend", function() {
        isHighlighting = false;
    });
    stage.on("mousemove touchmove", function() {
        if (!isHighlighting) {
            return;
        }
        const pos = stage.getPointerPosition();
        let newPoints = lastLine.points().concat([pos.x, pos.y]);
        lastLine.points(newPoints);
        layer.batchDraw();
    });
};
