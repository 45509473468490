import React, { Component } from 'react';
import {
  Col, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button
} from 'reactstrap';

import QuestionsAside from '../../Exams/TakeExam/QuestionsAside'
import QuestionBody from '../../Exams/TakeExam/QuestionBody'
import Timer from '../../Exams/TakeExam/Timer'

import _ from 'lodash';
import {isMobileOnly} from 'react-device-detect';
import queryString from 'query-string';

import cookie from '../../../utils/cookie';
import _xhr from '../../../utils/_xhr';
import rest from '../../../utils/rest.cfg';
const _quizresultsUrl = rest('quizresults');
const _quizzesUrl = rest('quizzes');
const _quizattemptsUrl =rest('quizattempts');
const _usersUrl = rest('users');
const auth = rest('auth');
const _quizresultUrl = rest('quizresults');
const _quizQuestionAnswers = rest('quizQuestionAnswers');
const _questionAttempt = rest('questionattempt');
const _quizSlotsUrl = rest('quizslots');
const _questionAnswersUrl = rest('questionanswers');
const _quizAttemptUrl = rest('quizattempt');
const _quizResultUser = rest('quizResultUser');
const _questionAttemptUser = rest('questionAttemptUser');
const _userUrl = rest('users');
class ExamDetail extends Component {
  constructor(props) {
      super(props);

      this.state = {
        showQuestions       : false,
        isQuizOpened        : false,
        questionsAndAnswers : [],
        questionAndAnswerAttempts : {},
        answerPrefix: ["A) ", "B) ", "C) ", "D) ", "E) "],
        totalPercentage: 100,
        quizTimeMs: 0,
        remainTimeMs: 0,
        modal: false,
          user: {}
      };

      this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentWillMount() {
      let self = this;
      let userId = '';
      if(this.props.location.search !== "" && this.props.location.search.indexOf("userId") > -1 ){
          let parsed = queryString.parse(this.props.location.search);
          userId = parsed.userId;
          if(userId) {
              let userUrl = _userUrl;
              _xhr.get({
                  url: userUrl + '/' + userId,
                  onsuccess: function ({ data }) {
                      self.setState({
                          user:data
                      })
                  }
              });
          }
          self.setState({
              userId:userId
          })
      }
      self.getQuizInfo().then(() => {
          self.getQuestionAndAnswers().then(() => {
              self.getQuestionAndAnswerAttempts().then(() => {
                  self.goToQuestion(0);
              });
          });
      });
  }

  componentDidMount() {
  }

  componentWillUpdate() {

  }

  componentDidUpdate() {

  }

  componentWillReceiveProps(newProps) {

  }

  getQuizInfo() {
    let self = this;

      let userId = '';
      if(this.props.location.search !== "" && this.props.location.search.indexOf("userId") > -1 ){
          let parsed = queryString.parse(this.props.location.search);
          userId = parsed.userId;
          self.setState({
              userId:userId
          })
      }
    if(userId) {
        return new Promise((resolve, reject) => {
            _xhr.post({
                url: _quizresultUrl + "/follow/",
                params: {
                    resultId: self.props.match.params.id,
                    userId: userId
                },
                onsuccess: function (response) {
                    let quizTimeMs = response.data.data.quiz.timeLimit*60*1000;
                    let remainTimeMs = quizTimeMs;
                    self.setState({
                        quiz: response.data,
                        quizTimeMs: quizTimeMs,
                        remainTimeMs: remainTimeMs
                    })
                    resolve();
                }
            });
        });
    } else {
        return new Promise((resolve, reject) => {
            _xhr.get({
                url: _quizresultUrl + "/" + self.props.match.params.id,
                onsuccess: function (response) {

                    if(!userId) {
                        let quizTimeMs = response.data.data.quiz.timeLimit*60*1000;
                        let remainTimeMs = quizTimeMs;
                        self.setState({
                            quiz: response.data,
                            quizTimeMs: quizTimeMs,
                            remainTimeMs: remainTimeMs
                        })
                    } else {
                        self.getUserQuizInfo(userId);
                    }
                    resolve();
                }
            });
        });
    }

  }

  getUserQuizInfo(userId){
      let self = this;
      _xhr.post({
          url: _quizResultUser,
          params: {
              userId: userId
          },
          onsuccess: function(response) {
              let quizTimeMs = response.data.timeLimit*60*1000;
              let remainTimeMs = quizTimeMs;
              self.setState({
                  quiz: response.data,
                  quizTimeMs: quizTimeMs,
                  remainTimeMs: remainTimeMs
              })
          }
      })
  }

  goToPrevQuestion(){
    let self = this;
    self.goToQuestion(self.state.activeQuestionData.listIndex -1);
  }

  goToNextQuestion(){
    let self = this;
    self.goToQuestion(self.state.activeQuestionData.listIndex +1);
  }

  goToQuestion(index){
      let self = this;
      if(isMobileOnly && self.state.modal){
        self.toggle();
      }
      self.showQuestion(index).then(() => {
          self.fillQuestion(index);
      });
  }

  fillQuestion(index){
    let self = this;
    let activeQuestionData = self.state.questionsAndAnswers[index];

    if (activeQuestionData.qtype !== "multichoice"){
      document.getElementById("reply").value = "";
    }else{
      let radios = document.querySelectorAll('[type="radio"]');
      [].forEach.call(radios, function (el) {
          el.checked = false;
      })
    }

    let questionAndAnswerAttempts = self.state.questionAndAnswerAttempts;
    activeQuestionData.empty = true;
    activeQuestionData.correct = false;
      let userId = '';
      if(this.props.location.search !== "" && this.props.location.search.indexOf("userId") > -1 ){
          let parsed = queryString.parse(this.props.location.search);
          userId = parsed.userId;
          self.setState({
              userId:userId
          })
      }
      if(userId) {
          return new Promise((resolve, reject) => {
              _xhr.post({
                  url: _questionAttemptUser,
                  params: {
                      questionId: parseInt(activeQuestionData.id),
                      userId: parseInt(userId)
                  },
                  onsuccess: function (response) {
                      let attemptItem = response.data;
                      if(attemptItem.responseId) {
                          activeQuestionData.empty = false;
                      }
                      activeQuestionData.Answers.forEach(function(item){
                          if(item.id === attemptItem.responseId && item.correct) activeQuestionData.correct = true;
                      })
                      activeQuestionData.responseId = attemptItem.responseId;
                      self.setState({
                          activeQuestionData: activeQuestionData
                      })
                      resolve();
                  }
              });
          });
      } else {
          return new Promise((resolve, reject) => {
              _xhr.get({
                  url: _questionAttempt + "/" + activeQuestionData.id,
                  onsuccess: function (response) {
                      let attemptItem = response.data;
                      if(attemptItem.responseId) {
                          activeQuestionData.empty = false;
                      }
                      activeQuestionData.Answers.forEach(function(item){
                          if(item.id === attemptItem.responseId && item.correct) activeQuestionData.correct = true;
                      })
                      activeQuestionData.responseId = attemptItem.responseId;
                      self.setState({
                          activeQuestionData: activeQuestionData
                      })
                      resolve();
                  }
              });
          });
      }

  }

  showQuestion(index){
    let self = this;
      return new Promise((resolve, reject) => {
          let activeQuestionData = self.state.questionsAndAnswers[index];
          if(activeQuestionData) {
              activeQuestionData.startTime = +new Date();
              activeQuestionData.parentQuestion = self.state.parentQuestions[self.state.questionsAndAnswers[index].parent];
              if(activeQuestionData.qtype !== 'parent') {
                  _xhr.get({
                      url: _questionAnswersUrl + '/' + activeQuestionData.id,
                      onsuccess: function(response) {
                          activeQuestionData.Answers = response.data;
                          self.setState({
                              activeQuestionData: activeQuestionData,
                              showQuestions: true
                          })
                          window.scrollTo(0, 0);
                          resolve();
                      }
                  })
              }
          }
      });
  }

  getQuestionAndAnswers(){
    let self = this;
    return new Promise((resolve, reject) => {
        _xhr.get({
            url: _quizSlotsUrl + "/" + self.state.quiz.data.quiz.id,
            onsuccess: function (response) {
                response.data.sort(function(a, b) {
                    return a["group"] - b["group"] || a["slot"] - b["slot"];
                });
                let parents = {};
                let questionsAnswers = [];
                let index = 0;

                response.data.forEach(function (data, i) {
                    let question = data.question;
                    if (data.question.qtype === "parent") {
                        parents[data.question.id] = data.question.questionText;
                    } else {
                        if(i === 0) {
                            parents[data.question.questionCategory.id] = data.question.questionCategory.groupText;
                        } else if(i > 0 && response.data[i-1].group !== data.group) {
                            parents[data.question.questionCategory.id] = data.question.questionCategory.groupText;
                        }
                        question.parentText = question.questionCategory.groupText
                        data.question.listIndex = index;
                        if(question.parent) {
                            let parentIndex = _.findIndex(response.data, (e) => {
                                return e.question.id === question.parent;
                            })

                            //console.log(response.data[parentIndex].parentNumber);
                            if(parentIndex > -1) {
                                if(response.data[parentIndex].question.parentNumber === undefined) {
                                    response.data[parentIndex].question.parentNumber = index;
                                }
                                question.childQuestion = question.questionText;
                                let pat = /[^{\}]+(?=})/g;
                                let res;
                                if(pat) {
                                    res = response.data[parentIndex].question.questionText.replace(pat, function(number){
                                        return response.data[parentIndex].question.parentNumber + parseInt(number);
                                    });
                                    res = res.replaceAll('{','(');
                                    res = res.replaceAll('}',')');
                                    question.questionText = res;
                                } else {
                                    question.questionText = response.data[parentIndex].question.questionText;
                                }
                            }
                        }
                        questionsAnswers.push(question);

                        index++
                    }
                })

                self.setState({
                    questionsAndAnswers: questionsAnswers,
                    parentQuestions: parents
                })

              resolve();
            }
        });

    });

  }

  getQuestionAndAnswerAttempts(){
    let self = this;

    return new Promise((resolve, reject) => {
        if(self.state.userId) {
            _xhr.post({
                url: _quizAttemptUrl + '/follow',
                params: {
                    quizId: self.state.quiz.data.quiz.id,
                    userId: self.state.userId
                },
                onsuccess: function (response) {
                    let totalSpentTimeMs = 0;
                    let remainTimeMs = self.state.remainTimeMs;
                    if( response.data.questionAttempts ){
                        response.data.questionAttempts.forEach(function (questionAttempt) {
                            totalSpentTimeMs = totalSpentTimeMs + questionAttempt.timeElapsed;
                        })
                        remainTimeMs = totalSpentTimeMs;
                    }

                    self.setState({
                        questionAndAnswerAttempts: response.data.questionAttempts,
                        remainTimeMs: remainTimeMs
                    })

                    self.convertRemainMsToFormathhmmss(self.state.remainTimeMs, self.state.quizTimeMs);
                    resolve();

                }
            });
        } else {
            _xhr.get({
                url: _quizAttemptUrl + '/' + self.state.quiz.data.quiz.id,
                onsuccess: function (response) {
                    let totalSpentTimeMs = 0;
                    let remainTimeMs = self.state.remainTimeMs;
                    if( response.data.questionAttempts ){
                        response.data.questionAttempts.forEach(function (questionAttempt) {
                            totalSpentTimeMs = totalSpentTimeMs + questionAttempt.timeElapsed;
                        })
                        remainTimeMs = totalSpentTimeMs;
                    }

                    self.setState({
                        questionAndAnswerAttempts: response.data.questionAttempts,
                        remainTimeMs: remainTimeMs
                    })

                    self.convertRemainMsToFormathhmmss(self.state.remainTimeMs, self.state.quizTimeMs);
                    resolve();

                }
            });
        }

    });
  }

  convertQuestionMsToFormathhmmss(difference, index) {
      let self = this;
      let activeQuestionData = self.state.questionsAndAnswers[index];

      var _totalSec = difference/1000;
      var daysDifference = Math.floor(_totalSec/60/60/24);
      difference -= daysDifference*1000*60*60*24;

      var hoursDifference = Math.floor(difference/1000/60/60);
      difference -= hoursDifference*1000*60*60;

      var minutesDifference = Math.floor(difference/1000/60);
      difference -= minutesDifference*1000*60;

      var secondsDifference = Math.floor(difference/1000);

      activeQuestionData.sec  = secondsDifference
      activeQuestionData.min  = minutesDifference
      activeQuestionData.hour = hoursDifference
      self.setState({
          activeQuestionData: activeQuestionData
      })
  }

  convertRemainMsToFormathhmmss(difference, differenceTT) {
      let self = this;

      var _totalSec = difference/1000;
      var daysDifference = Math.floor(_totalSec/60/60/24);
      difference -= daysDifference*1000*60*60*24;
      var _totalSecTT = differenceTT/1000;
      var daysDifferenceTT = Math.floor(_totalSecTT/60/60/24);
      differenceTT -= daysDifferenceTT*1000*60*60*24;

      var hoursDifference = Math.floor(difference/1000/60/60);
      difference -= hoursDifference*1000*60*60;
      var hoursDifferenceTT = Math.floor(differenceTT/1000/60/60);
      differenceTT -= hoursDifferenceTT*1000*60*60;

      var minutesDifference = Math.floor(difference/1000/60);
      difference -= minutesDifference*1000*60;
      var minutesDifferenceTT = Math.floor(differenceTT/1000/60);
      differenceTT -= minutesDifferenceTT*1000*60;

      var secondsDifference = Math.floor(difference/1000);
      var secondsDifferenceTT = Math.floor(differenceTT/1000);

      var totalPercentage = Math.floor(( ((secondsDifference*1000) + (minutesDifference*60*1000) + (hoursDifference*60*60*1000)) / self.state.quizTimeMs)*100);

      self.setState({
          remainTime: {
              sec: secondsDifference,
              min: minutesDifference,
              hour: hoursDifference
          },
          totalTime: {
              secTT: secondsDifferenceTT,
              minTT: minutesDifferenceTT,
              hourTT: hoursDifferenceTT
          },
          totalPercentage: totalPercentage
      })
  }

  render() {
    if ( this.state.showQuestions && this.state.activeQuestionData) {
      return (

          <Row className="zeroBreadcrumbMargin">
            {isMobileOnly &&
              <Col md="3" className="pr-0 pl-0">
                <Timer
                  onClick={this.toggle.bind(this)}
                  totalTime={this.state.totalTime}
                  remainTime={this.state.remainTime}
                  totalPercentage={this.state.totalPercentage}
                  linkText="Tüm Sorular"
                  resultInfo={true}/>

                  <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Sorular</ModalHeader>
                    <ModalBody className = "pl-0 pr-0">
                      <QuestionsAside
                        questions={this.state.questionsAndAnswers}
                        activeIndex={this.state.activeQuestionData.listIndex}
                        onClick={this.goToQuestion.bind(this)}
                        questionAttempts={this.state.questionAndAnswerAttempts}
                        totalTime={this.state.totalTime}
                        remainTime={this.state.remainTime}
                        totalPercentage={this.state.totalPercentage}
                        resultInfo={true}/>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={this.toggle}>Kapat</Button>
                    </ModalFooter>
                  </Modal>
              </Col>
            }
            <Col md="9" className={isMobileOnly ? "pr-0 pl-0" : "scrollableArea pr-0 pl-0"}>
                <b> {
                    this.state.user.name ?
                        this.state.user.name + ' ' + this.state.user.surname + ' - SINAV SONUÇLARI' :
                        ''
                }</b>
              <QuestionBody
                header={this.state.quiz.name}
                questionLength={this.state.questionsAndAnswers.length}
                activeQuestion={this.state.activeQuestionData}
                previousButtonOnClick={this.goToPrevQuestion.bind(this)}
                nextButtonOnClick={this.goToNextQuestion.bind(this)}
                resultInfo={true}
                />
            </Col>
            {!isMobileOnly &&
              <Col md="3" className={isMobileOnly ? "pr-0 pl-0" : "scrollableArea pr-0 pl-0"}>
                <QuestionsAside
                  questions={this.state.questionsAndAnswers}
                  activeIndex={this.state.activeQuestionData.listIndex}
                  onClick={this.goToQuestion.bind(this)}
                  questionAttempts={this.state.questionAndAnswerAttempts}
                  totalTime={this.state.totalTime}
                  remainTime={this.state.remainTime}
                  totalPercentage={this.state.totalPercentage}
                  resultInfo={true}/>
              </Col>
            }
          </Row>

      );
    }
    else return null;
  }
}

export default ExamDetail;
